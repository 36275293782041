<template>
  <div v-if="child">

    <md-content v-if="organization.baseModuleActive" class="md-scrollbar" style="max-height: 750px; overflow-y: auto; overflow-x: hidden">

      <div v-if="selectedBookingTime">

        <div class="grid-x">
          <md-tabs v-if="!presenceViewEnabled" id="bookingTimesTabsId" md-dynamic-height md-elevation="1" @md-changed="onTabsChanged" :md-active-tab="activeTabId">
            <template slot="md-tab" slot-scope="{ tab }">
              <md-icon style="margin-bottom: 8px;">{{getBookingTimesTabIcon(tab.data.bookingTime)}}</md-icon>
                {{ tab.label }}
              <md-tooltip>
                <div>{{getBookingTimesTabLabelHover(tab.data.bookingTime)}}</div>
              </md-tooltip>
            </template>
            <md-tab v-for="(bookingTime, bookingTimeNr) in child.bookingTimes"
                    :md-label="getBookingTimesTabLabel(bookingTime)" :md-icon="getBookingTimesTabIcon(bookingTime)"
                    :id="getElementId('tab', bookingTimeNr)" :key="getElementId('tab', bookingTimeNr)"
                    :md-template-data="{ badge: false, bookingTime: bookingTime, reason: ''}">

              <div :id="getElementId('overview-grid', bookingTimeNr)" class="grid-x">
                <div class="cell text-left large-10">
                  <table :id="getElementId('overview-table', bookingTimeNr)">
                    <tr>
                      <td class="validity-period" style="width: 150px;">Bereich</td>
                      <td class="validity-period"><b id="division-type" :class="selectedBookingTime.divisiontype">{{labels.divisiontype[selectedBookingTime.divisiontype]}}</b></td>
                    </tr>
                    <tr>
                      <td class="validity-period" style="width: 150px;">Buchungszeit-Typ</td>
                      <td class="validity-period"><b id="booking-time-type" :class="selectedBookingTime.divisiontype">
                        {{labels.bookingTimeType[selectedBookingTime.type]}}
                        <span v-if="selectedBookingTime.type === 'HOLIDAY'">({{labels.schoolHolidaysName[selectedBookingTime.schoolHolidaysName]}})</span>
                      </b></td>
                    </tr>
                    <tr>
                      <td class="validity-period" style="width: 150px;">Förderfaktor</td>
                      <td class="validity-period"><b id="support-factor" :class="selectedBookingTime.divisiontype">{{selectedBookingTime.supportFactor}}</b></td>
                    </tr>
                    <tr>
                      <td class="validity-period" style="width: 150px;">Zeitfaktor</td>
                      <td class="validity-period"><b id="time-factor" :class="selectedBookingTime.divisiontype">{{selectedBookingTime.timeFactor}}</b></td>
                    </tr>
                    <tr v-if="selectedBookingTime.type === 'HOLIDAY'">
                      <td class="validity-period" style="width: 150px;">Ferientage</td>
                      <td class="validity-period"><b id="time-factor" :class="selectedBookingTime.divisiontype">{{selectedBookingTime.holidayDays}}</b></td>
                    </tr>
                    <tr>
                      <td class="validity-period" style="width: 150px;">Förderkommune</td>
                      <td class="validity-period"><b :class="selectedBookingTime.divisiontype">{{selectedBookingTime.promotionCommunityName}}</b></td>
                    </tr>
                  </table>
                </div>
                <div class="cell text-right large-2">
                  <md-button v-if="viewWeek" class="md-icon-button md-primary" style="margin-top: 20px;" @click="onToggleBookingTimesView">
                    <md-icon>calendar_today</md-icon>
                    <md-tooltip>Auf Kalenderansicht wechseln</md-tooltip>
                  </md-button>
                  <md-button v-else class="md-icon-button md-primary" style="margin-top: 20px;" @click="onToggleBookingTimesView">
                    <md-icon>view_week</md-icon>
                    <md-tooltip>Auf Wochenansicht wechseln</md-tooltip>
                  </md-button>
                </div>
                <div class="cell">
                  <md-switch class="md-primary" v-model="showClosedDays">
                    <div>Alle Tage für Einrichtung <b><span>{{getFacilityName(child.facilityReference)}}</span></b> anzeigen</div>
                  </md-switch>
                </div>

                <table v-if="viewWeek" :id="getElementId('table', bookingTimeNr)">
                  <thead>
                  <tr>
                    <th v-for="(day, index) of $material.locale.days" :key="index" :style="getAssignmentStyle(day)" v-if="showDay(day)">
                      {{day.substring(0,2)}} <span style="color: seagreen;">{{getTimeString(day)}}</span>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(bookingTimeLabel, index) of bookingTimeLabels" :key="index">
                    <td v-for="(day, index) of $material.locale.days" style="padding: 0; background: white" :key="index" v-if="showDay(day)">
                      <BookingSlotView :bookingSlot="bookingTimeLabel" :divisionType="selectedBookingTime.divisiontype"
                                       :facility="childFacility"
                                       :day="day" :times="selectedBookingTime.times"
                                       :showCoreTime="false"
                                       :slimStyle="'font-size: 5px;line-height: 5px;'"/>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div v-else>
                  <div :id="getElementId('calendar-grid', bookingTimeNr)" class="grid-x">
                    <div class="cell large-9 medium-7 small-7 text-left">
                      <h5 style="font-size: x-large">&nbsp;{{selectedBookingMonthDate | moment('MMMM YYYY')}}</h5>
                    </div>
                    <div class="cell large-1 medium-2 small-2 text-right">
                      <md-button class="md-icon-button md-primary" @click="onPrevBookingMonth">
                        <md-icon>chevron_left</md-icon>
                        <md-tooltip>Vorheriger Monat</md-tooltip>
                      </md-button>
                    </div>
                    <div class="cell large-1 medium-2 small-2 text-center">
                      <md-button class="md-icon-button md-primary" @click="onTodaysBookingMonth">
                        <md-icon>today</md-icon>
                        <md-tooltip>Heute</md-tooltip>
                      </md-button>
                    </div>
                    <div class="cell large-1 medium-1 small-1 text-left">
                      <md-button class="md-icon-button md-primary" @click="onNextBookingMonth">
                        <md-icon>chevron_right</md-icon>
                        <md-tooltip>Nächster Monat</md-tooltip>
                      </md-button>
                    </div>
                  </div>
                  <table :id="getElementId('calendar', bookingTimeNr)">
                    <thead>
                    <tr>
                      <th v-for="(day, index) of $material.locale.days" :key="index" :style="getAssignmentStyle(day)" v-if="showDay(day)">
                        {{day.substring(0,2)}}
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="row in [0, 1, 2, 3, 4, 5]" :key="row">
                      <td v-for="(day, index) of $material.locale.days" :key="index" :style="isInBookingTimeStyle(index, row)" v-if="showDay(day)">
                        <div class="grid-x">
                          <div class="cell large-9 text-left">
                            <div v-if="isInValidityPeriod(index, row) && getTimeString($material.locale.days[index]) != ''">
                              <div v-if="isInMonth(index, row)">
                                <span style="font-size: 11px" :class="selectedBookingTime.divisiontype">{{getIntervalString($material.locale.days[index])}}</span>
                                <br><span :class="selectedBookingTime.divisiontype">{{getTimeString($material.locale.days[index])}}</span>
                              </div>
                              <div v-else>
                                <span style="color: lightgray; font-style:italic; font-size: 11px">{{getIntervalString($material.locale.days[index])}}</span>
                                <br><span style="color: lightgray; font-style:italic;">{{getTimeString($material.locale.days[index])}}</span>
                              </div>
                            </div>
                            <div v-else>
                              <span style="color: lightgray; font-style:italic; font-size: 11px">Keine</span>
                              <br><span style="color: lightgray; font-style:italic">Buchung</span>
                            </div>
                          </div>
                          <div class="cell large-3 text-right">
                            <div v-if="isInMonth(index, row)" :class="isTodayClass(index, row)" style="font-size: larger">
                              {{getDay(index, row)}}

                              <md-button v-if="presenceViewEnabled" class="md-icon-button" style="margin-left: -10px" @click="onToggleChildAbsence(child, getDate(index, row), true)"
                                         :disabled="sending || !(isFacility || isAdmin || isFacilityAdmin || isManagement) || !canWrite">
                                <md-icon v-if="getAbsence(index, row)" style="color: green;">check</md-icon>
                                <md-icon v-else style="color: red;">close</md-icon>
                                <md-tooltip>Anwesenheit für <b>{{getDate(index, row) | moment("DD.MM.YYYY") }}</b> ändern</md-tooltip>
                              </md-button>
                              <md-tooltip md-delay="1000" style="max-width: 500px;"><div v-if="!getAbsence(index, row)" class="ai-alert-text">{{labels.absenceStatus[childAbsence[getDate(index, row)]]}}</div></md-tooltip>
                            </div>
                            <div v-else class="cell text-right" style="font-size: larger; color: lightgray; font-style:italic;">
                              {{getDay(index, row)}}
                              <div v-if="presenceViewEnabled" >
                                <md-icon v-if="getAbsence(index, row)" style="color: lightgreen;">check</md-icon>
                                <md-icon v-else style="color: lightcoral;">close</md-icon>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </md-tab>
          </md-tabs>

          <div v-if="presenceViewEnabled" class="cell">
            <div>
              <div id="presence-view-calendar-grid" class="grid-x">
                <div class="cell large-9 medium-7 small-7 text-left">
                  <h5 style="font-size: x-large">&nbsp;{{selectedBookingMonthDate | moment('MMMM YYYY')}}</h5>
                </div>
                <div class="cell large-1 medium-2 small-2 text-right">
                  <md-button class="md-icon-button md-primary" @click="onPrevBookingMonth">
                    <md-icon>chevron_left</md-icon>
                    <md-tooltip>Vorheriger Monat</md-tooltip>
                  </md-button>
                </div>
                <div class="cell large-1 medium-2 small-2 text-center">
                  <md-button class="md-icon-button md-primary" @click="onTodaysBookingMonth">
                    <md-icon>today</md-icon>
                    <md-tooltip>Heute</md-tooltip>
                  </md-button>
                </div>
                <div class="cell large-1 medium-1 small-1 text-left">
                  <md-button class="md-icon-button md-primary" @click="onNextBookingMonth">
                    <md-icon>chevron_right</md-icon>
                    <md-tooltip>Nächster Monat</md-tooltip>
                  </md-button>
                </div>
              </div>
              <table id="presence-view-calendar-id">
                <thead>
                <tr>
                  <th v-for="(day, index) of $material.locale.days" :key="index" :style="getAssignmentStyle(day)" v-if="showDay(day)">
                    {{day.substring(0,2)}}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="row in [0, 1, 2, 3, 4, 5]" :key="row">
                  <td v-for="(day, index) of $material.locale.days" :key="index" :style="isInBookingTimeStyle(index, row)" v-if="showDay(day)">
                    <div class="grid-x">
                      <div class="cell large-9 text-left">
                        <div v-if="isInValidityPeriod(index, row) && getTimeString($material.locale.days[index]) != ''">
                          <div v-if="isInMonth(index, row)">
                            <span style="font-size: 11px" :class="selectedBookingTime.divisiontype">{{getIntervalString($material.locale.days[index])}}</span>
                            <br><span :class="selectedBookingTime.divisiontype">{{getTimeString($material.locale.days[index])}}</span>
                          </div>
                          <div v-else>
                            <span style="color: lightgray; font-style:italic; font-size: 11px">{{getIntervalString($material.locale.days[index])}}</span>
                            <br><span style="color: lightgray; font-style:italic;">{{getTimeString($material.locale.days[index])}}</span>
                          </div>
                        </div>
                        <div v-else>
                          <span style="color: lightgray; font-style:italic; font-size: 11px">Keine</span>
                          <br><span style="color: lightgray; font-style:italic">Buchung</span>
                        </div>
                      </div>
                      <div class="cell large-3 text-right">
                        <div v-if="isInMonth(index, row)" :class="isTodayClass(index, row)" style="font-size: larger">
                          {{getDay(index, row)}}

                          <md-button v-if="presenceViewEnabled" class="md-icon-button" style="margin-left: -10px" @click="onToggleChildAbsence(child, getDate(index, row), true)"
                                     :disabled="sending || !(isFacility || isAdmin || isFacilityAdmin || isManagement) || !canWrite">
                            <md-icon v-if="getAbsence(index, row)" style="color: green;">check</md-icon>
                            <md-icon v-else style="color: red;">close</md-icon>
                            <md-tooltip>Anwesenheit für <b>{{getDate(index, row) | moment("DD.MM.YYYY") }}</b> ändern</md-tooltip>
                          </md-button>
                          <md-tooltip md-delay="1000" style="max-width: 500px;"><div v-if="!getAbsence(index, row)" class="ai-alert-text">{{labels.absenceStatus[childAbsence[getDate(index, row)]]}}</div></md-tooltip>
                        </div>
                        <div v-else class="cell text-right" style="font-size: larger; color: lightgray; font-style:italic;">
                          {{getDay(index, row)}}
                          <div v-if="presenceViewEnabled" >
                            <md-icon v-if="getAbsence(index, row)" style="color: lightgreen;">check</md-icon>
                            <md-icon v-else style="color: lightcoral;">close</md-icon>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <br>
            <div @click="confirmDownloadPresenceListPDF = true" class="button success" :disabled="sending">
              <i class="fi-page-export-pdf"></i>&nbsp;&nbsp;&nbsp;Anwesenheitsübersicht-PDF
                <md-tooltip>Anwesenheitsübersicht <b>{{selectedBookingMonthDate | moment('MMM YYYY')}}</b> für <b>{{child.masterdata.fullName}}</b> als PDF herunterladen</md-tooltip>
            </div>
          </div>
        </div>

        <br>
        <div v-if="!presenceViewEnabled" class="grid-x grid-padding-x">
          <div class="cell small-12 medium-12 large-8">
            <button v-if="!child.archived && canWrite && (isAdmin || isFacilityAdmin || isManagement || isTreasurer)"
                    type="button" @click="onEditBookingTime" class="button success expanded">
              <md-icon>edit</md-icon>
              <md-icon>schedule</md-icon>&nbsp;&nbsp;&nbsp;Buchungszeiten bearbeiten
            </button>
            <button v-else @click="onViewBookingTime" class="button expanded secondary">
              <i class="fi-eye"></i>&nbsp;&nbsp;&nbsp;Buchungszeiten einsehen
              <md-tooltip>
                Buchungszeiten können nur mit den Benutzer-Profilen <i>Administrator, Einrichtungs-Admin, Finanzen</i> und <i>Verwaltung</i> bearbeitet werden.
              </md-tooltip>
            </button>
          </div>
          <div class="cell small-12 medium-12 large-4">&nbsp;</div>
        </div>
      </div>
      <div v-else>
        <div v-if="!presenceViewEnabled" class="grid-x grid-padding-x">
          <div class="cell small-12 medium-12 large-8">
            <button v-if="!child.archived && canWrite && (isAdmin || isFacilityAdmin || isManagement || isTreasurer)"
                    type="button" @click="onNewBookingTime" class="button success expanded">
              <md-icon>add</md-icon>
              <md-icon>schedule</md-icon>&nbsp;&nbsp;&nbsp;Buchungszeiten anlegen
            </button>
          </div>
          <div class="cell small-12 medium-12 large-4">&nbsp;</div>
        </div>
        <div v-else>
          <div class="md-title" style="font-size: 1rem;"><md-icon style="color: red; margin-top: -3px;">error_outline</md-icon>&nbsp;&nbsp;Keine gültige Buchungszeit vorhanden.</div>
        </div>
      </div>

      <EditBookingTimesDialog v-if="!presenceViewEnabled" ref="editBookingTimesDialog" :child="childCopy" :mode="dialogMode"
                              :bookingTimesBackup="bookingTimesBackup"
                              :bookingTimeIndex="selectedBookingTimeIndex"
                              :showDialog="showEditBookingTimesDialog"
                              :childFacility="childFacility"
                              @bookingTimeTemplateSuccess="onBookingTimeTemplateSuccess"
                              @updateBookingTimeSuccess="onUpdateBookingTimeSuccess"
                              @closeEditBookingTimesDialog="onCloseEditBookingTimesDialog">
      </EditBookingTimesDialog>

      <md-dialog :md-click-outside-to-close="false" :md-active.sync="confirmDownloadPresenceListPDF">
        <div class="grid-x grid-padding-x grid-padding-y">
          <md-dialog-title>
            <md-content>
              <i class="fi-page-multiple"></i>&nbsp;&nbsp;&nbsp;Anwesenheitsübersicht <span>{{selectedBookingMonthDate | moment('MMM YYYY')}}</span> als PDF herunterladen
            </md-content>
          </md-dialog-title>
          <div class="cell">
            <md-content>
                Soll die Anwesenheitsübersicht <span>{{selectedBookingMonthDate | moment('MMM YYYY')}}</span> für
                <span>{{child.masterdata.fullName}}</span> als PDF heruntergeladen werden?
            </md-content>
            <br>
            <md-progress-bar md-mode="indeterminate" v-if="sending"/>
          </div>
          <div class="cell text-center">
            <button :disabled="sending" @click="onDownloadPresenceListPdf" class="button success"><i class="fi-download"></i>&nbsp;&nbsp;&nbsp;Ja,
              PDF herunterladen
            </button>
            <button :disabled="sending" @click="confirmDownloadPresenceListPDF = false"
                    class="button alert"
                    style="margin-left: 1rem;"
                    type="button">
              <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
            </button>
          </div>
          <button @click="confirmDownloadPresenceListPDF = false" class="close-button" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <br>
      </md-dialog>

    </md-content>
    <div v-else>
      <NoModuleCard moduleType="BASE" moduleFunction="Buchungszeiten"></NoModuleCard>
    </div>
  </div>
</template>

<script>

  import ChildService from '../../services/ChildService';
  import HttpErrorHandler from '../../services/HttpErrorHandler';
  import BookingSlotView from './BookingSlotView';
  import EditBookingTimesDialog from '../../components/dialogs/EditBookingTimesDialog';
  import NoModuleCard from '../../components/cards/NoModuleCard';
  import Vue from 'vue';
  import jQuery from "jquery";
  import PdfService from "../../services/PdfService";

  export default {
    name: 'BookingTimesView',

    components: {
      BookingSlotView,
      EditBookingTimesDialog,
      NoModuleCard,
    },

    props: {
      child: {
        required: true
      },
      childFacility: {
        required: true
      },
      presenceViewEnabled: {
        required: true
      }
    },

    data: () => ({
      bookingTimeLabels: ['Morgens', 'Vormittags', 'Mittags', 'Nachmittags', 'Abends'],
      selectedBookingTime: null,
      selectedBookingTimeIndex: 0,
      showEditBookingTimesDialog: false,
      dialogMode: 'update',
      childCopy: null,
      bookingTimesBackup: null,
      success: false,
      viewWeek: true,
      selectedBookingMonthDate: Vue.moment(Vue.moment().format('YYYY-MM') + '-01'),
      selectedBookingMonthFirstDayOfWeek: 0,
      selectedBookingMonthIndex: 0,
      childAbsence: {},
      confirmDownloadPresenceListPDF: false,
      presenceListPdf: {
        url: null,
        link: null,
        blob: null,
      },
      sending: false,
      showClosedDays: false,
      activeTabId: '',
    }),

    watch: {
      child() {
        if (!this.presenceViewEnabled) {
          this.resetIndices();
          this.onTodaysBookingMonth();
        }
        this.getChildAbsence(this.child);
        this.resizeElements();
        this.setActiveTab();
      },

      presenceViewEnabled() {
        this.getChildAbsence(this.child);
        this.resizeElements();
        this.resizePresenceViewElements();
      },
    },

    created() {
      this.selectBookingTime();
      this.resizeElements();
      this.setActiveTab();
    },

    mounted() {
      window.addEventListener('resize', () => {
        this.resizeElements();
        this.resizePresenceViewElements();
      }, { passive: true });
    },

    beforeDestroy() {
      this.cleanPDF();
    },

    methods: {

      getElementId(prefix, bookingTimeNr) {
        return prefix + '-booking-time-id-' + Number(bookingTimeNr).toString();
      },

      onTabsChanged(tabId) {
        if (tabId) {
          this.selectedBookingTimeIndex = Number(tabId.substr(-1, 1));
          this.selectBookingTime();
          if (this.selectedBookingTime) {
            this.resetBookingMonthIndex(this.selectedBookingTime.validFrom);
          }
        }
        setTimeout(() => jQuery('#bookingTimesTabsId').children('.md-content.md-tabs-content').attr('style', 'height: 100%'), 50);
      },

      setActiveTab() {
        if (this.selectedBookingTimeIndex > 0) {
          this.activeTabId = this.getElementId('tab', this.selectedBookingTimeIndex);
        } else {
          jQuery('#bookingTimesTabsId').children('.md-tabs-navigation').first().children(':button.md-button').last().trigger('click');
        }
      },

      getBookingTimesTabLabel(bookingTime) {
        let label = this.$moment(bookingTime.validFrom).format('DD.MM.YYYY') + '\nbis\n' + this.$moment(bookingTime.validUntil).format('DD.MM.YYYY');
        label += '\n' + bookingTime.hoursPerWeek + ' Stunden';
        return label;
      },

      getBookingTimesTabLabelHover(bookingTime) {
        let label = '\n' + bookingTime.description;
        label = label.replaceAll('Uhr', 'Uhr\n');
        label = label.replaceAll('\n;', '\n');
        return label;
      },

      getBookingTimesTabIcon(bookingTime) {
        if (bookingTime) {
          if (bookingTime.type === 'REGULAR') {
            return 'watch_later';
          }
          if (bookingTime.type === 'HOLIDAY') {
            return 'beach_access';
          }
          if (bookingTime.type === 'SHORT') {
            return 'timelapse';
          }
        }
        return '';
      },

      resizePresenceViewElements() {
        setTimeout(() => {
          const width = jQuery('#presence-view-calendar-grid').width();
          setTimeout(() => jQuery('#presence-view-calendar-id').width(width), 10);
        }, 20);
      },

      resizeElements() {
        setTimeout(() => {
          let index =  1;
          const width = jQuery('#CHILD_BOOKING_TIMES').width() - 16;
          for (let bookingTimeNr = 0; bookingTimeNr < this.child.bookingTimes.length; bookingTimeNr++) {
            setTimeout(() => jQuery('#' + this.getElementId('overview-grid', bookingTimeNr)).width(width), index++ * 10);
            setTimeout(() => jQuery('#' + this.getElementId('table', bookingTimeNr)).width(width), index++ * 10);
            setTimeout(() => jQuery('#' + this.getElementId('calendar', bookingTimeNr)).width(width), index++ * 10);
            setTimeout(() => jQuery('#' + this.getElementId('calendar-grid', bookingTimeNr)).width(width), index++ * 10);
          }
          setTimeout(() => jQuery('#bookingTimesTabsId').children('.md-tabs-navigation').first().width(width), index++ * 10);
        }, 20);
      },

      resetStates() {
        this.success = false;
      },

      resetIndices() {
        this.selectedBookingTimeIndex = 0;
        this.selectedBookingMonthFirstDayOfWeek = 0;
        this.selectedBookingMonthIndex = 0;
        this.selectBookingTime();
      },

      getAssignmentStyle(day) {
        let time = this.selectedBookingTime.times[day.substr(0, 2).toUpperCase()];
        if (time) {
          return time.assigned ? '' : 'background: whitesmoke'
        }
      },

      showDay(day) {
        let time = this.selectedBookingTime.times[day.substr(0, 2).toUpperCase()];
        return !!time || this.showClosedDays;
      },

      getTimeString(day) {
        let time = this.selectedBookingTime.times[day.substr(0, 2).toUpperCase()];
        if (time) {
          let minutes = time.minutes;
          let result = '';
          if (Math.floor(minutes / 60) !== 0) {
            result += Math.floor(minutes / 60) + 'h ';
          }
          if (minutes % 60 !== 0) {
            result += minutes % 60 + 'min';
          }

          return result;
        }
        return '';
      },

      getFacilityName(referenceNumber) {
        if (referenceNumber && referenceNumber.length > 0 && this.organization.facilities) {
          for (let facility of this.organization.facilities) {
            if (facility.referenceNumber === referenceNumber) {
              return facility.name;
            }
          }
        }
        return '';
      },

      isTodayClass(index, row) {
        let a = this.$moment(this.selectedBookingMonthDate).add(-this.selectedBookingMonthFirstDayOfWeek + index + row * 7, 'd').format('D');
        let b = this.$moment().format('D');
        if (a === b && this.selectedBookingMonthIndex === 0) {
          return 'cell text-right is-today';
        }
        return 'cell text-right';
      },

      isInMonth(index, row) {
        return this.$moment(this.selectedBookingMonthDate).add(- this.selectedBookingMonthFirstDayOfWeek + index + row * 7, 'd').format('MMMM') === this.$moment(this.selectedBookingMonthDate).format('MMMM');
      },

      getDay(index, row) {
        return this.$moment(this.selectedBookingMonthDate).add(- this.selectedBookingMonthFirstDayOfWeek + index + row * 7, 'd').format('D');
      },

      getDate(index, row) {
        return this.$moment(this.selectedBookingMonthDate).add(- this.selectedBookingMonthFirstDayOfWeek + index + row * 7, 'd').format('YYYY-MM-DD');
      },

      getAbsence(index, row) {
        if (this.childAbsence[this.getDate(index, row)]) {
          return this.childAbsence[this.getDate(index, row)].toString().startsWith('HERE');
        }
        return false;
      },

      firstCalenderViewDate() {
        return this.$moment(this.selectedBookingMonthDate).add(-this.selectedBookingMonthFirstDayOfWeek, 'd').format('YYYY-MM-DD');
      },

      lastCalenderViewDate() {
        return this.$moment(this.selectedBookingMonthDate).add(-this.selectedBookingMonthFirstDayOfWeek + 6 * 7 - 1, 'd').format('YYYY-MM-DD');
      },

      isInBookingTimeStyle(index, row) {
        if (!(this.$moment(this.selectedBookingMonthDate).add(-this.selectedBookingMonthFirstDayOfWeek + index + row * 7, 'd').isAfter(this.selectedBookingTime.validUntil) ||
          this.$moment(this.selectedBookingMonthDate).add(-this.selectedBookingMonthFirstDayOfWeek + index + row * 7, 'd').isBefore(this.selectedBookingTime.validFrom))) {
          if (this.isInMonth(index, row)) {
            if (this.selectedBookingTime.divisiontype === 'KRIPPE') return 'background: #ffc0cb3b;';
            if (this.selectedBookingTime.divisiontype === 'KINDERGARTEN') return 'background: #87cefa3b;';
            if (this.selectedBookingTime.divisiontype === 'HORT') return 'background: #90ee9036;';
          }
        } else {
          return '';
        }
      },

      isInValidityPeriod(index, row) {
        let selectedDate = this.$moment(this.selectedBookingMonthDate).add(-this.selectedBookingMonthFirstDayOfWeek + index + row * 7, 'd');
        return !(this.$moment(selectedDate).isBefore(this.selectedBookingTime.validFrom) || this.$moment(selectedDate).isAfter(this.selectedBookingTime.validUntil));
      },

      getIntervalString(day) {
        let time = this.selectedBookingTime.times[day.substr(0, 2).toUpperCase()];
        if (time) {
          return time.from.substr(0, 5) + ' - ' + time.until.substr(0, 5);
        }
        return '';
      },

      getPreFilledBookingTime() {
        if (this.child) {
          this.sending = true;
          ChildService.getPreFilledBookingTime(this.child.id, '')
            .then(response => {
              this.sending = false;
              this.childCopy.bookingTimes = [];
              this.childCopy.bookingTimes.push(response.data);
              this.childCopy.bookingTimes[0].refNr = -1;
              this.bookingTimesBackup = JSON.parse(JSON.stringify(this.childCopy.bookingTimes));
              this.showEditBookingTimesDialog = true;
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der vorausgefüllten Buchungszeit für Kind '
                + this.child.masterdata.firstname + ' ' + this.child.masterdata.lastname);
              this.sending = false;
            })
        }
      },

      resetBookingMonthIndex(date) {
        if (this.selectedBookingTime) {
          this.selectedBookingMonthIndex = Vue.moment(date, 'YYYY-MM-DD').diff(Vue.moment(Vue.moment().format('YYYY-MM') + '-01', 'YYYY-MM-DD'), 'months');
          this.selectedBookingMonthDate = Vue.moment(Vue.moment().format('YYYY-MM') + '-01').add(this.selectedBookingMonthIndex, 'M');
        }
      },

      onToggleBookingTimesView() {
        this.resetBookingMonthIndex(this.selectedBookingTime.validFrom);
        this.viewWeek = !this.viewWeek;
        this.resizeElements();
      },

       selectBookingTime() {
         if (this.child && this.child.bookingTimes && this.child.bookingTimes.length > this.selectedBookingTimeIndex) {
          this.selectedBookingTime = this.child.bookingTimes[this.selectedBookingTimeIndex];
          this.adaptBookingMonth();
          this.getChildAbsence(this.child);
        } else {
          this.selectedBookingTime = null;
        }
      },

      onEditBookingTime() {
        this.dialogMode = 'update';
        this.childCopy = JSON.parse(JSON.stringify(this.child));
        this.bookingTimesBackup = JSON.parse(JSON.stringify(this.child.bookingTimes));
        this.$refs.editBookingTimesDialog.selectedBookingTimeIndex = this.selectedBookingTimeIndex;
        this.showEditBookingTimesDialog = true;
      },

      onViewBookingTime() {
        this.dialogMode = 'view';
        this.childCopy = JSON.parse(JSON.stringify(this.child));
        this.bookingTimesBackup = JSON.parse(JSON.stringify(this.child.bookingTimes));
        this.$refs.editBookingTimesDialog.selectedBookingTimeIndex = this.selectedBookingTimeIndex;
        this.showEditBookingTimesDialog = true;
      },

      onNewBookingTime() {
        this.dialogMode = 'add';
        this.childCopy = JSON.parse(JSON.stringify(this.child));
        this.bookingTimesBackup = JSON.parse(JSON.stringify(this.child.bookingTimes));
        this.getPreFilledBookingTime();
      },

      onBookingTimeTemplateSuccess(message) {
        this.$store.commit('successMsg', message);
        this.success = true;
      },

      onUpdateBookingTimeSuccess(updatedChild) {
        this.child.bookingTimes = updatedChild.bookingTimes;
        this.child.alerts = updatedChild.alerts;
        if (this.dialogMode === 'add') {
          this.selectedBookingTime = updatedChild.currentBookingTime;
        }
        this.bookingTimesBackup = JSON.parse(JSON.stringify(this.child.bookingTimes));

        this.$emit("updateChildren", updatedChild);
        this.$store.commit('successMsg', 'Die Änderungen der Buchungszeiten für das Kind ' + this.child.masterdata.fullName + ' wurden erfolgreich durchgeführt!');
        this.success = true;
      },

      onCloseEditBookingTimesDialog() {
        this.showEditBookingTimesDialog = false;
        this.bookingTimesBackup = null;
      },

      onPrevBookingTime() {
        if (this.selectedBookingTimeIndex > 0) {
          this.selectedBookingTimeIndex--;
          this.selectBookingTime();
          this.resetBookingMonthIndex(this.selectedBookingTime.validFrom);
        }
      },

      onNextBookingTime() {
        if (this.selectedBookingTimeIndex < this.child.bookingTimes.length - 1) {
          this.selectedBookingTimeIndex++;
          this.selectBookingTime();
          this.resetBookingMonthIndex(this.selectedBookingTime.validFrom);
        }
      },

      adaptBookingMonth() {
        let firstDayOfWeek = Vue.moment(Vue.moment(this.selectedBookingMonthDate).format('YYYY-MM') + '-01').day() - 1;
        if (firstDayOfWeek < 1) {
          this.selectedBookingMonthFirstDayOfWeek = firstDayOfWeek + 7;
        } else {
          this.selectedBookingMonthFirstDayOfWeek = firstDayOfWeek;
        }
      },

      onPrevBookingMonth() {
        if (this.presenceViewEnabled || Vue.moment(this.selectedBookingMonthDate).isAfter(this.child.bookingTimes[0].validFrom)) {
          this.selectedBookingMonthIndex--;
          this.selectedBookingMonthDate = Vue.moment(Vue.moment().format('YYYY-MM') + '-01').add(this.selectedBookingMonthIndex, 'M');
          if (this.selectedBookingTime && !this.presenceViewEnabled) {
            if (Vue.moment(this.selectedBookingMonthDate).isBefore(Vue.moment(this.selectedBookingTime.validFrom, 'YYYY-MM-DD'))) {
              this.onPrevBookingTime();
            }
          }
          this.adaptBookingMonth();
          this.getChildAbsence(this.child);
        }
      },

      onTodaysBookingMonth() {
        this.selectedBookingMonthIndex = 0;
        if (this.child.bookingTimes.length > 0) {
          this.selectedBookingTime = this.child.bookingTimes[this.selectedBookingTimeIndex];
        }
        this.selectedBookingMonthDate = Vue.moment(Vue.moment().format('YYYY-MM') + '-01').add(this.selectedBookingMonthIndex, 'M');
        for (let i = 0; i < this.child.bookingTimes.length; i++) {
          if ((Vue.moment(this.child.bookingTimes[i].validFrom).isSameOrBefore(this.selectedBookingMonthDate) &&
            (Vue.moment(this.child.bookingTimes[i].validUntil).isSameOrAfter(this.selectedBookingMonthDate)))) {
            this.selectedBookingTimeIndex = i;
            this.selectedBookingTime = this.child.bookingTimes[this.selectedBookingTimeIndex];
            break;
          }
        }
        this.adaptBookingMonth();
        this.getChildAbsence(this.child);
      },

      onNextBookingMonth() {
        if (this.presenceViewEnabled || Vue.moment(this.selectedBookingMonthDate).isBefore(Vue.moment(this.child.bookingTimes[this.child.bookingTimes.length - 1].validUntil, 'YYYY-MM-DD').subtract(1, 'M'))) {
          this.selectedBookingMonthIndex++;
          this.selectedBookingMonthDate = Vue.moment(Vue.moment().format('YYYY-MM') + '-01').add(this.selectedBookingMonthIndex, 'M');
          if (this.selectedBookingTime && !this.presenceViewEnabled) {
            if (Vue.moment(this.selectedBookingMonthDate).isAfter(Vue.moment(this.selectedBookingTime.validUntil, 'YYYY-MM-DD'))) {
              this.onNextBookingTime();
            }
          }
          this.adaptBookingMonth();
          this.getChildAbsence(this.child);
        }
      },

      getChildAbsence(child) {
        if (this.presenceViewEnabled) {
          ChildService.getChildAbsence(child.id, this.firstCalenderViewDate(), this.lastCalenderViewDate())
            .then((response) => {
              this.childAbsence = response.data;
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Anwesenheit von Kind <b>' + child.masterdata.fullName + '</b>');
            })
        }
      },

      onToggleChildAbsence(child, date, updateParent) {
        ChildService.toggleChildAbsence(child.id, date)
          .then((response) => {
            this.childAbsence[date] = response.data.absenceStatusToday;
            if (date === Vue.moment().format('YYYY-MM-DD')) {
              this.child.absenceStatusToday = this.childAbsence[date];
              if (updateParent) {
                this.$emit("updateChildAbsence", this.child);
              }
            }
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Ändern des Anwesenheit von Kind <b>' + child.masterdata.fullName + '</b>');
          })
      },

      cleanPDF() {
        if (this.presenceListPdf.url) {
          this.presenceListPdf.url = window.URL.revokeObjectURL(this.presenceListPdf.url);
          this.presenceListPdf.blob = null;
        }
        this.presenceListPdf.url = null;
      },

      onDownloadPresenceListPdf() {
        if (this.child.facilityReference) {
          let config = [{
            facilityReference: this.child.facilityReference,
            childId: this.child.id,
            dsgvoPersonId: '',
            template: {
              type: 'ABSENCE',
              content: '',
              name: '',
              standard: false,
              printMargins: {
                top: 0,
                right: 0,
                left: 0,
                bottom: 0
              },
              forceUseOfLetterPaper: 0,
            },
            year: Number(Vue.moment(this.selectedBookingMonthDate).format('YYYY')),
            month: Number(Vue.moment(this.selectedBookingMonthDate).format('MM')),
            startDate: Vue.moment(this.selectedBookingMonthDate).format('YYYY-MM-DD'),
            endDate: Vue.moment(this.selectedBookingMonthDate).add(1, 'month').add(-1, 'd').format('YYYY-MM-DD'),
            preview: false,
          }];

          this.cleanPDF();
          this.getTemplateData(this.child.facilityReference, config, this.presenceListPdf)
        }
      },

      getTemplateData(referenceNumber, config, pdf) {
        this.sending = true;
        PdfService.getTemplateData(config)
          .then(response => {
            this.sending = false;
            this.createPdfForTemplate(referenceNumber, response.data, pdf)
          })
          .catch(e => {
            this.sending = false;
            HttpErrorHandler.handleError(e, this, 'Fehler beim Erzeugen der PDF Template Daten für Kind <b>' + this.child.masterdata.fullName + '</b> zur Erstellung der Anwesenheitsübersicht');
          })
      },

      createPdfForTemplate(referenceNumber, templateData, pdf) {
        this.sending = true;
        PdfService.createPdf(templateData)
          .then(response => {
            setTimeout(() => {
              pdf.blob = new Blob([response.data, 'utf-8'], {type: "application/pdf"});
              pdf.url = window.URL.createObjectURL(pdf.blob);

              if (pdf.link) {
                document.body.removeChild(pdf.link);
              }
              pdf.link = document.createElement('a');
              const id = this.childFacility.name + ' (' + this.childFacility.referenceNumber + ') Anwesenheitsübersicht ' + Vue.moment(this.selectedBookingMonthDate).format('MMM YYYY') + ' ' + this.child.masterdata.fullName;
              pdf.link.href = pdf.url;
              pdf.link.setAttribute('download', id + '.pdf');
              pdf.link.setAttribute('id', id);
              document.body.appendChild(pdf.link);
              pdf.link.click();

              this.sending = false;
              this.confirmDownloadPresenceListPDF = false;
            }, 50);
          })
          .catch(e => {
            this.sending = false;
            HttpErrorHandler.handleError(e, this, 'Fehler beim Erzeugen des PDF für die Datenauskunft für Person <b>' + this.person.firstname + ' ' + this.person.lastname + '</b>');
          })
      },
    },

    computed: {
      labels() {
        return this.$store.getters.labels
      },

      isAdmin() {
        return this.$store.getters.admin
      },

      isFacilityAdmin() {
        return this.$store.getters.facilityAdmin
      },

      isTreasurer() {
        return this.$store.getters.treasurer
      },

      isFacility() {
        return this.$store.getters.facility
      },

      isManagement() {
        return this.$store.getters.management
      },

      organization() {
        return this.$store.getters.organization
      },

      canWrite() {
        return this.$store.getters.canWrite
      },
    }
  }
</script>

<style lang="scss">
  #bookingTimesTabsId .md-tabs-navigation > .md-button {
    white-space: pre !important;
  }
  #bookingTimesTabsId .md-tabs-navigation > .md-button > .md-ripple {
    align-items: baseline !important;
  }
  #bookingTimesTabsId .md-tabs-navigation .md-ripple {
    padding: 6px 12px;
  }
  #bookingTimesTabsId .md-button.md-tab-nav-button {
    height: 120px;
    text-transform: none;
  }
  #bookingTimesTabsId .md-tab {
    padding: 16px 0 16px 0;
  }
  #CHILD_BOOKING_TIMES .md-card-content {
    padding: 16px 8px 16px 8px;
  }
</style>

<style lang="scss" scoped>
  p {
    margin: 10px;
  }

  span {
    color: cornflowerblue;
  }

  table {
    display: table;
    border-collapse: separate;
    border-spacing: 2px;
    border-color: grey;
    empty-cells: hide;
    width: 100%;
    margin: 4px 0 0;
  }

  th {
    width: 12.5%;
    font-weight: lighter;
    text-align: center;
    padding: 0;
  }

  #calendar {
    border-collapse: collapse;
  }

  #calendar table {
    font-weight: lighter;
    border-spacing: 2px;
    width: 100%;
  }

  #calendar td, #calendar th {
    width: 12.5%;
    border: 2px solid #fff;
    padding: 5px;
  }

  .md-theme-dark #calendar td,
  .md-theme-dark #calendar th {
    width: 12.5%;
    border: 2px solid #424242;
    padding: 5px;
  }

  #calendar tr {
    background-color: #fafafa;
  }

  .md-theme-dark #calendar tr {
    background-color: #525252;
  }

  #calendar tr:hover {
    background-color: rgba(255, 193, 7, 0.1);
  }

  .md-theme-dark #calendar tr:hover {
    background-color: rgba(37, 28, 2, 0.1);
  }

  #calendar th {
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: right;
    background: rgba(255, 193, 7, 0.4);
  }

  .md-theme-dark #calendar th {
    background: #666666;
  }

  .is-today {
    background: cornflowerblue;
    color: white;
    text-align: center;
  }

  h5 {
    font-weight: 400;
    color: lightseagreen;
    margin-bottom: 0;
    margin-top: 5px;
  }

  .validity-period {
    text-align: left;
    padding: 0px;
    font-weight: 300;
    font-size: medium;
  }

  .validity-description {
    text-align: left;
    padding: 5px;
    font-weight: 300;
    font-size: small;
    color: lightseagreen;
  }

  .button {
    margin-bottom: 0;
  }

  .md-tooltip {
    font-size: medium;
    height: auto;
    white-space: normal;
  }

  #division-type .KRIPPE, span.KRIPPE {
    color: deeppink;
    background: none;
  }

  #division-type .KINDERGARTEN, span.KINDERGARTEN {
    color: deepskyblue;
    background: none;
  }

  #division-type .HORT, span.HORT {
    color: limegreen;
    background: none;
  }
</style>

<template>
  <div v-if="child">
    <md-dialog :md-click-outside-to-close="false" id="editBookingTimeDialog" :md-active.sync="showDialog"
               @md-closed="closeDialog" class="edit-booking-times-dialog">

      <form novalidate @submit.prevent="validateBookingTimes">

        <md-dialog-title>
          <div class="grid-x grid-padding-x">
            <div v-if="mode === 'view'" class="cell small-7">
              <md-icon>visibility</md-icon>
              <md-icon>schedule</md-icon>&nbsp;&nbsp;&nbsp;Buchungszeiten für&nbsp;&nbsp;<ImageView
                :imageId="child.imageId" size="tiny"></ImageView>&nbsp;&nbsp;
              <span class="group-title">{{ child.masterdata.fullName }}</span> einsehen
            </div>
            <div v-if="mode === 'update'" class="cell small-7">
              <md-icon>edit</md-icon>
              <md-icon>schedule</md-icon>&nbsp;&nbsp;&nbsp;Buchungszeiten für&nbsp;&nbsp;<ImageView
                :imageId="child.imageId" size="tiny"></ImageView>&nbsp;&nbsp;
              <span class="group-title">{{ child.masterdata.fullName }}</span> bearbeiten
            </div>
            <div v-else-if="mode === 'add'" class="cell small-7">
              <md-icon>add</md-icon>
              <md-icon>schedule</md-icon>&nbsp;&nbsp;&nbsp;Buchungszeiten für
              <ImageView :imageId="child.imageId" size="tiny"></ImageView>&nbsp;
              <span class="group-title">{{ child.masterdata.fullName }}</span> anlegen
            </div>
            <div class="cell small-1 text-left">
              <vue-simple-spinner v-if="sending" style="padding-top: 12px;"></vue-simple-spinner>
              <SessionTime v-else></SessionTime>
            </div>
            <div v-if="selectedBookingTime" class="cell small-4 text-left" style="padding-top: 6px;">
              <button v-if="mode === 'update' || mode === 'add'" class="button success" type="submit"
                      :disabled="sending">
                <i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Änderungen speichern
                <md-tooltip v-if="selectedBookingTime.creationPossible" style="font-size: large; padding: 10px">
                  <md-icon style="color: greenyellow">check</md-icon>&nbsp;Alle Eingaben für ausgewählte Buchungszeit in
                  Ordnung<br>
                </md-tooltip>
                <md-tooltip v-else style="font-size: large; padding: 10px" class="text-left">
                  <md-icon class="md-accent">error_outline</md-icon>&nbsp;<b style="color: orangered">Achtung</b><br>&nbsp;<br>
                  <i style="color: white">{{ selectedBookingTime.creationErrorMessage }}</i>
                </md-tooltip>
              </button>
              <button v-if="mode !== 'view'" class="button alert" style="margin-left: 1rem;" @click="closeDialog"
                      type="button"
                      :disabled="sending">
                <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Abbrechen
              </button>
            </div>
          </div>
        </md-dialog-title>

      </form>

      <md-dialog-content id="dialogContentId">

        <div class="grid-x grid-padding-x">
          <div class="cell large-8">

            <div v-if="!classicView" class="grid-x" style="padding-bottom: 1rem">
              <md-tabs id="editBookingTimesTabsId" md-dynamic-height md-elevation="1" @md-changed="onTabsChanged"
                       :md-active-tab="activeTabId">
                <template slot="md-tab" slot-scope="{ tab }">
                  <md-icon style="margin-bottom: 8px;">{{getBookingTimesTabIcon(tab.data.bookingTime)}}</md-icon>
                  {{ tab.label }}
                  <md-tooltip>
                    <div>{{getBookingTimesTabLabelHover(tab.data.bookingTime)}}</div>
                  </md-tooltip>
                </template>
                <md-tab v-for="(bookingTime, bookingTimeNr) in child.bookingTimes"
                        :md-label="getBookingTimesTabLabel(bookingTime)" :md-icon="getBookingTimesTabIcon(bookingTime)"
                        :id="getElementId('tab', bookingTimeNr)" :key="getElementId('tab', bookingTimeNr)"
                        :md-template-data="{ badge: false, bookingTime: bookingTime, reason: ''}">
                </md-tab>
              </md-tabs>
            </div>

            <div class="grid-x">
              <div class="cell small-4">
                <h5 style="font-weight: 300; font-size: large">Einrichtung:
                  <br><span
                      style="font-weight: 300; font-size: larger">{{ facilityNames[child.facilityReference] }}</span>
                </h5>
              </div>
              <div class="cell small-4">
                <md-field>
                  <label>Vorlage</label>
                  <md-select v-model="bookingTimeTemplateIndex" :disabled="sending || child.archived">
                    <md-option v-for="(template, index) of availableBookingTimeTemplates[child.facilityReference]"
                               :value="index" :key="index">
                      {{ template.name }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="cell small-1">
                <br>
                <md-button class="md-icon-button md-raised md-primary"
                           :disabled="bookingTimeTemplateIndex < 0 || child.archived"
                           @click="onUseTemplate">
                  <md-icon>arrow_downward</md-icon>
                  <md-tooltip>Vorlage übernehmen</md-tooltip>
                </md-button>
              </div>
              <div class="cell small-1">
                <br>
                <md-button
                    v-if="bookingTimesBackup && bookingTimesBackup.length > 0 && bookingTimesBackup[this.selectedBookingTimeIndex]"
                    class="md-icon-button md-raised md-primary"
                    :disabled="!bookingTimesBackup[this.selectedBookingTimeIndex].backupActive || child.archived"
                    @click="onUndoTemplate">
                  <md-icon>undo</md-icon>
                  <md-tooltip>Vorlage zurückziehen</md-tooltip>
                </md-button>
              </div>
              <div class="cell small-1">
                <br>
                <md-button v-if="selectedBookingTime && bookingTimesBackup && bookingTimesBackup.length > 0"
                           class="md-icon-button md-raised md-accent"
                           @click="onDeleteBookingTimeSheet"
                           :disabled="child.archived || selectedBookingTime.refNr < 0">
                  <md-icon>delete</md-icon>
                  <md-tooltip>Buchungszeit löschen</md-tooltip>
                </md-button>
              </div>
              <div class="cell small-1">
                <br>
                <md-button class="md-icon-button md-raised md-primary"
                           @click="onAddBookingTimeSheet" :disabled="!canAddNewBookingTimeSheet || child.archived">
                  <md-icon>playlist_add</md-icon>
                  <md-tooltip>Weitere Buchungszeit anlegen</md-tooltip>
                </md-button>
              </div>
            </div>

            <table v-if="selectedBookingTime">
              <thead>
              <tr>
                <th v-for="(day, index) of $material.locale.days" :key="index" :style="getAssignmentStyle(day)"
                    v-if="showDay(day)">
                  {{ day }} <br><span style="font-size: larger; color: darkseagreen;">{{ getTimeString(day) }}</span>
                  <md-button class="md-icon-button md-raised md-micro md-primary"
                             @click="transferBookingTime(index)" :disabled="child.archived">
                    <md-icon>chevron_right</md-icon>
                    <md-tooltip><b>Buchungszeit</b> auf nächsten Tag <b>übertragen</b>
                    </md-tooltip>
                  </md-button>
                  <md-button class="md-icon-button md-raised md-micro md-accent"
                             @click="clearBookingTime(index)" :disabled="child.archived">
                    <md-icon>clear</md-icon>
                    <md-tooltip><b>Buchungszeit</b> für <b>{{ day }} löschen</b></md-tooltip>
                  </md-button>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="bookingTimeLabel of bookingTimeLabels" :key="bookingTimeLabel">
                <td v-for="(day, index) of $material.locale.days" style="padding: 0; background: white" :key="index"
                    v-if="showDay(day)">
                  <BookingSlotView :bookingSlot="bookingTimeLabel" :day="day"
                                   :divisionType="selectedBookingTime.divisiontype"
                                   :times="selectedBookingTime.times"
                                   :facility="childFacility"
                                   :slimStyle="'font-size: 14px;line-height: 14px;'"
                                   :editMode="!child.archived"
                                   :showCoreTime="showCoreTime"
                                   @changeBookingTime="changeBookingTime"/>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div v-if="selectedBookingTime" class="cell large-4">

            <div v-if="classicView" class="grid-x">
              <div class="cell small-2 text-left">
                <md-button class="md-icon-button md-primary" @click="onPrevBookingTime"
                           :disabled="selectedBookingTimeIndex <= 0">
                  <md-icon>chevron_left</md-icon>
                  <md-tooltip>Ältere Buchungszeit</md-tooltip>
                </md-button>
              </div>
              <div class="cell small-8 text-center">
                <h5>
                  Buchungszeiten&nbsp;{{ selectedBookingTimeIndex + 1 }}&nbsp;/&nbsp;{{
                    child.bookingTimes.length
                  }}</h5>
              </div>
              <div class="cell small-2 text-right">
                <md-button class="md-icon-button md-primary" @click="onNextBookingTime"
                           :disabled="selectedBookingTimeIndex + 1 >= child.bookingTimes.length">
                  <md-icon>chevron_right</md-icon>
                  <md-tooltip>Neuere Buchungszeit</md-tooltip>
                </md-button>
              </div>
              <div class="cell small-12 text-center">
                <span class="validity-description">{{ selectedBookingTime.description }}</span>
              </div>
            </div>

            <br>

            <div class="grid-x grid-padding-x">
              <div class="cell small-6 medium-6 text-left">
                <md-field :class="getValidationClass('type')">
                  <label>Buchungszeit-Typ</label>
                  <md-select v-model="selectedBookingTime.type" autocomplete="off"
                             :disabled="sending" @md-selected="recalculateBookingTime(selectedBookingTime)">
                    <md-option v-for="bookingTimeType of labels.bookingTimeTypes" :value="bookingTimeType"
                               :key="bookingTimeType">
                      {{ labels.bookingTimeType[bookingTimeType] }}
                    </md-option>
                  </md-select>
                  <span class="md-error" v-if="!$v.selectedBookingTime.type.required">Bitte wählen</span>
                </md-field>
              </div>
              <div class="cell small-6 medium-6 text-left">
                <md-field :class="getValidationClass('divisiontype')">
                  <label>Bereich</label>
                  <md-select v-model="selectedBookingTime.divisiontype" autocomplete="off"
                             :disabled="sending" @md-selected="recalculateBookingTime(selectedBookingTime)">
                    <md-option v-for="divisiontype of labels.divisionTypes" :value="divisiontype"
                               :key="divisiontype">
                      {{ labels.divisiontype[divisiontype] }}
                    </md-option>
                  </md-select>
                  <span class="md-error"
                        v-if="!$v.selectedBookingTime.divisiontype.required">Bereich ist notwendig</span>
                </md-field>
              </div>
            </div>

            <div class="md-layout" :style="showRegularType(selectedBookingTime.type)">
              <div class="md-layout-item" style="padding-right: 15px;">
                <md-datepicker id="valid-from-date-dp" :md-debounce="10000" :md-open-on-focus="false"
                               v-model="validFromDate" md-immediately :disabled="sending"
                               :class="getValidationClass('validFrom')" :md-disabled-dates="disabledFirstDay"
                               @md-closed="onCloseValidFromDatePicker">
                  <label>Gültig von</label>
                  <span class="md-error" v-if="!$v.selectedBookingTime.validFrom.required">Gültig von Datum ist notwendig</span>
                </md-datepicker>
              </div>
              <div class="md-layout-item" style="padding-left: 15px;">
                <md-datepicker id="valid-until-date-dp" :md-debounce="10000" :md-open-on-focus="false"
                               v-model="validUntilDate" md-immediately :disabled="sending"
                               :class="getValidationClass('validUntil')" :md-disabled-dates="disabledLastDay"
                               @md-closed="onCloseValidUntilDatePicker" @md-opened="onOpenValidUntilDatePicker">
                  <label>Gültig bis</label>
                  <span class="md-error" v-if="!$v.selectedBookingTime.validUntil.required">Gültig bis Datum ist notwendig</span>
                </md-datepicker>
              </div>
            </div>
            <div class="md-layout" :style="showOtherType(selectedBookingTime.type)">
              <div class="md-layout-item" style="padding-right: 15px;">
                <md-datepicker id="valid-from-date-else-dp" :md-debounce="10000" :md-open-on-focus="false"
                               v-model="validFromDate" md-immediately :disabled="sending"
                               :class="getValidationClass('validFrom')"
                               @md-closed="onCloseValidFromDatePicker">
                  <label>Gültig von</label>
                  <span class="md-error" v-if="!$v.selectedBookingTime.validFrom.required">Gültig von Datum ist notwendig</span>
                </md-datepicker>
              </div>
              <div class="md-layout-item" style="padding-left: 15px;">
                <md-datepicker id="valid-until-date-else-dp" :md-debounce="10000" :md-open-on-focus="false"
                               v-model="validUntilDate" md-immediately :disabled="sending"
                               :class="getValidationClass('validUntil')"
                               @md-closed="onCloseValidUntilDatePicker" @md-opened="onOpenValidUntilDatePicker">
                  <label>Gültig bis</label>
                  <span class="md-error" v-if="!$v.selectedBookingTime.validUntil.required">Gültig bis Datum ist notwendig</span>
                </md-datepicker>
              </div>
            </div>

            <md-tabs md-dynamic-height>
              <md-tab id="FEATURES" md-label="Merkmale">
                <div class="grid-x grid-padding-x">
                  <div v-if="selectedBookingTime.migrantBackground" class="cell small-12" style="margin-bottom: 10px;">
                    <span style="color: orangered; font-size: large;">Kind mit Migrationshintergrund</span>
                  </div>
                  <div class="cell small-6">Stunden pro Tag: <span>{{ selectedBookingTime.hoursPerDay }}</span></div>
                  <div class="cell small-6">Stunden pro Woche: <span>{{ selectedBookingTime.hoursPerWeek }}</span></div>
                  <hr>
                  <div class="cell small-12">
                    <md-field>
                      <label>Mittagsunterbrechung (Stunden pro Tag)</label>
                      <md-input name="lunchbreakHours" id="lunchbreakHours" autocomplete="off" type="number"
                                @blur="recalculateBookingTime(selectedBookingTime)"
                                v-model="selectedBookingTime.lunchbreakHours" :disabled="sending"/>
                    </md-field>
                  </div>
                  <hr>
                  <div class="cell small-6">
                    <md-checkbox class="md-primary" v-model="selectedBookingTime.handicapped"
                                 @change="recalculateBookingTime(selectedBookingTime)">Behinderung
                    </md-checkbox>
                  </div>
                  <div v-if="selectedBookingTime.showVorschulKind" class="cell small-6">
                    <md-checkbox class="md-primary" v-model="selectedBookingTime.preschooler">Vorschulkind</md-checkbox>
                  </div>
                  <div class="cell small-6">
                    <md-checkbox class="md-primary" v-model="selectedBookingTime.preparatoryCourseActive">Vorkurs
                      besucht
                    </md-checkbox>
                  </div>
                </div>
                <div v-if="selectedBookingTime.showBeitragszuschuss">
                  <div class="grid-x grid-padding-x">
                    <div class="cell small-12">
                      <md-checkbox class="md-primary"
                                   v-model="selectedBookingTime.kindergartenBeitragszuschussAutoCalculationEnabled"
                                   @change="onAutoKigaSupport">
                        Kindergartenzuschuss automatisch berechnen
                        <md-tooltip>Kindergartenzuschuss automatisch berechnen</md-tooltip>
                      </md-checkbox>
                    </div>
                  </div>
                  <div v-if="selectedBookingTime.kindergartenBeitragszuschussAutoCalculationEnabled"
                       class="grid-x grid-padding-x">
                    <div class="cell small-12">
                      <br>
                      <div>Kiga Zuschuss <span
                          v-if="selectedBookingTime.kindergartenBeitragszuschuss">von {{
                          kigaStartDate
                        }} bis {{ kigaEndDate }}</span>
                        <span v-else>(keinen Zuschuss)</span></div>
                    </div>
                  </div>
                  <div v-else class="md-layout">
                    <br>
                    <div class="md-layout-item" style="padding-right: 15px;">
                      <md-datepicker id="kiga-start-date-dp" :md-debounce="10000" :md-open-on-focus="false"
                                     v-model="kigaStartDate" md-immediately :disabled="sending"
                                     :class="getValidationClass('kindergartenBeitragszuschussStart')"
                                     :md-disabled-dates="disabledFirstDay"
                                     @md-closed="onCloseKigaStartDatePicker">
                        <label>Zuschuss Start</label>
                        <span class="md-error"
                              v-if="!$v.selectedBookingTime.kindergartenBeitragszuschussStart.required">Start Datum ist notwendig</span>
                      </md-datepicker>
                    </div>
                    <div class="md-layout-item" style="padding-left: 15px;">
                      <md-datepicker id="kiga-end-date-dp" :md-debounce="10000" :md-open-on-focus="false"
                                     v-model="kigaEndDate" md-immediately :disabled="sending"
                                     :class="getValidationClass('kindergartenBeitragszuschussEnde')"
                                     :md-disabled-dates="disabledLastDay"
                                     @md-closed="onCloseKigaEndDatePicker" @md-opened="onOpenKigaEndDatePicker">
                        <label>Zuschuss Ende</label>
                        <span class="md-error" v-if="!$v.selectedBookingTime.kindergartenBeitragszuschussEnde.required">Ende Datum ist notwendig</span>
                      </md-datepicker>
                    </div>
                  </div>
                </div>
              </md-tab>
              <md-tab id="SUPPORT" md-label="KiBiG-Web">
                <div class="grid-x grid-padding-x">
                  <div v-if="selectedBookingTime" class="cell">
                    <md-autocomplete id="child-promotion-community-name-id"
                                     v-model="selectedBookingTime.promotionCommunityName"
                                     :md-options="promotionCommunities"
                                     @md-changed="getPromotionCommunities" @md-closed="validatePromotionCommunity"
                                     @md-selected="selectPromotionCommunity"
                                     :class="getValidationClass('promotionCommunityName')">
                      <label>Förderkommune (Zur Suche tippen)</label>

                      <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                        <md-highlight-text :md-term="term">{{ item.promotionCommunityName }}</md-highlight-text>
                      </template>

                      <template slot="md-autocomplete-empty" slot-scope="{ term }">
                        <span v-if="term.length > 1 && !loading">"{{ term }}" nicht gefunden</span>
                      </template>

                      <span class="md-error" v-if="!$v.selectedBookingTime.promotionCommunityName.required">Förderkommune ist notwendig</span>
                    </md-autocomplete>
                  </div>
                </div>
                <div class="grid-x grid-padding-x">
                  <div class="cell small-4">
                    <md-field :class="getValidationClass('supportFactor')">
                      <label>Förderfaktor</label>
                      <md-input name="supportFactor" id="supportFactor" autocomplete="off"
                                v-model="selectedBookingTime.supportFactor"
                                :disabled="sending || selectedBookingTime.supportFactorAutoCalculationEnabled"/>
                      <span class="md-error" v-if="!$v.selectedBookingTime.supportFactor.required">Förderfaktor ist notwendig</span>
                    </md-field>
                  </div>
                  <div class="cell small-4">
                    <md-field :class="getValidationClass('timeFactor')">
                      <label>Zeitfaktor</label>
                      <md-input name="timeFactor" id="timeFactor" autocomplete="off"
                                v-model="selectedBookingTime.timeFactor"
                                :disabled="sending || selectedBookingTime.timeFactorAutoCalculationEnabled"/>
                      <span class="md-error"
                            v-if="!$v.selectedBookingTime.timeFactor.required">Zeitfaktor ist notwendig</span>
                    </md-field>
                  </div>
                  <div class="cell small-4">
                    <md-field>
                      <label>KiBiG Zeitfenster</label>
                      <md-input name="kibigTimeSlot" id="kibigTimeSlot" autocomplete="off"
                                v-model="kibigTimeSlotLabel"
                                disabled/>
                    </md-field>
                  </div>

                </div>
                <div class="grid-x grid-padding-x">
                  <div class="cell small-4">
                    <md-checkbox class="md-primary" v-model="selectedBookingTime.supportFactorAutoCalculationEnabled"
                                 @change="recalculateBookingTime(selectedBookingTime)">
                      Auto
                      <md-tooltip>Förderfaktor automatisch berechnen</md-tooltip>
                    </md-checkbox>
                  </div>
                  <div class="cell small-4">
                    <md-checkbox class="md-primary" v-model="selectedBookingTime.timeFactorAutoCalculationEnabled"
                                 @change="recalculateBookingTime(selectedBookingTime)">
                      Auto
                      <md-tooltip>Zeitfaktor automatisch berechnen</md-tooltip>
                    </md-checkbox>
                  </div>
                  <div class="cell small-4"></div>
                </div>
              </md-tab>
            </md-tabs>

          </div>
        </div>

        <md-card-actions>
          <div class="grid-x" style="width: 100%">
            <div class="cell small-3 text-left">
              <md-switch class="md-primary" v-model="showCoreTime">
                <span v-if="showCoreTime" class="crosshatch">Kernzeit anzeigen</span>
                <div v-else>Kernzeit anzeigen</div>
              </md-switch>
            </div>
            <div class="cell small-3 text-left">
              <md-switch class="md-primary" v-model="recalculateData">
                <div>Daten dynamisch berechnen</div>
                <md-tooltip>Nach Änderung der Angaben werden die Daten sofort geprüft und entsprechend angepasst.
                </md-tooltip>
              </md-switch>
            </div>
            <div class="cell small-3 text-left">
              <md-switch class="md-primary" v-model="showClosedDays">
                <div>Geschlossene Tage anzeigen</div>
              </md-switch>
            </div>
            <div class="cell small-3">&nbsp;</div>
          </div>
        </md-card-actions>

        <md-progress-bar md-mode="indeterminate" v-if="sending"/>

      </md-dialog-content>


      <button class="close-button" type="button" @click="closeDialog">
        <span aria-hidden="true">&times;</span>
      </button>
    </md-dialog>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDeleteBookingTimeDialog" class="delete-booking-times-dialog">
      <div v-if="selectedBookingTime" class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title>
          <md-icon>delete</md-icon>
          <md-icon>schedule</md-icon>
          &nbsp;&nbsp;&nbsp;Buchungszeit für&nbsp;&nbsp;
          <ImageView :imageId="child.imageId" size="tiny"></ImageView>&nbsp;&nbsp;
          <span class="group-title">{{ child.masterdata.firstname }} {{ child.masterdata.lastname }}</span> löschen
        </md-dialog-title>
        <div class="cell text-center">
          <md-content v-if="selectedBookingTime.validFrom && selectedBookingTime.validUntil">Soll die Buchungszeit von
            <span class="group-title">{{ selectedBookingTime.validFrom | moment("DD.MM.YYYY") }}</span> bis
            <span class="group-title">{{ selectedBookingTime.validUntil | moment("DD.MM.YYYY") }}</span> wirklich
            gelöscht
            werden?
          </md-content>
          <md-content v-else>Soll diese Buchungszeit wirklich gelöscht werden?
          </md-content>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>
        <div class="cell text-center">
          <md-checkbox v-model="absolutelySure" :disabled="sending">Ich bin mir absolut sicher!</md-checkbox>
        </div>
        <div class="cell text-center">
          <button @click="onDeleteBookingTime" class="button alert" :disabled="sending || !absolutelySure">
            <i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Ja, Buchungszeit jetzt löschen
          </button>
          <button class="button success" style="margin-left: 1rem;"
                  @click="closeDeleteBookingTimeDialog"
                  type="button"
                  :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="closeDeleteBookingTimeDialog">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

    <md-dialog :md-click-outside-to-close="false" id="saveBookingTimeDialog" :md-active.sync="showSaveBookingTimeDialog" class="save-booking-times-dialog">
      <div v-if="selectedBookingTime" class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title>
          <md-icon>save</md-icon>
          <md-icon>schedule</md-icon>
          &nbsp;&nbsp;&nbsp;Änderungen an Buchungszeit für&nbsp;&nbsp;
          <ImageView :imageId="child.imageId" size="tiny"></ImageView>&nbsp;&nbsp;
          <span class="group-title">{{ child.masterdata.firstname }} {{ child.masterdata.lastname }}</span> speichern
        </md-dialog-title>
        <div class="cell text-left" style="padding-left: 2rem; padding-right: 2rem;">
          <md-content v-if="selectedBookingTime.validFrom && selectedBookingTime.validUntil">Sollen die Änderungen an
            Buchungszeit von
            <span class="group-title">{{ selectedBookingTime.validFrom | moment("DD.MM.YYYY") }}</span> bis
            <span class="group-title">{{ selectedBookingTime.validUntil | moment("DD.MM.YYYY") }}</span> gespeichert
            werden?
          </md-content>
          <md-content v-else>Sollen Änderungen dieser Buchungszeit wirklich gespeichert werden?
          </md-content>
          <br><br>
          <md-checkbox class="md-primary" v-model="saveAsTemplate">Einstellungen als Vorlage ablegen</md-checkbox>
          <br>
          <div v-if="saveAsTemplate">
            <br>
            <div>
              <md-radio v-model="createNewTemplate" value="false" class="md-primary" @change="templateName = ''">
                Bestehende Vorlage überschreiben
              </md-radio>
              <md-radio v-model="createNewTemplate" value="true" class="md-primary" @change="templateName = ''">Neue
                Vorlage anlegen
              </md-radio>
            </div>
            <div class="grid-x">
              <div class="cell small-8">
                <md-field v-if="createNewTemplate === 'true'" :class="getValidationClass('newTemplateName')">
                  <label>Name für neue Vorlage</label>
                  <md-input v-model="newTemplateName" :disabled="!saveAsTemplate"></md-input>
                  <span class="md-error"
                        v-if="$v.newTemplateName.$invalid">Name ungültig (Vorlage bereits vorhanden)</span>
                </md-field>
                <md-field v-else>
                  <label>Bestehende Vorlage</label>
                  <md-select v-model="templateName" :disabled="sending">
                    <md-option v-for="(template, index) of availableBookingTimeTemplates[child.facilityReference]"
                               :value="template.name" :key="index">
                      {{ template.name }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="cell small-4 text-center">
                <div v-if="createNewTemplate !== 'true'">
                  <br>
                  <md-button class="md-icon-button md-raised md-accent" @click="onDeleteTemplateDialog"
                             :disabled="!templateName">
                    <md-icon>delete</md-icon>
                    <md-tooltip md-direction="left">Ausgewählte Vorlage <b>{{ templateName }}</b> löschen
                    </md-tooltip>
                  </md-button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="selectedBookingTime && selectedBookingTime.refNr < 0">
            <br>
            <md-checkbox class="md-primary" v-model="createFee">Für diese neue Buchungszeit entsprechende Gebühr
              (z.B. Betreuungsbeitrag) mitanlegen
            </md-checkbox>
          </div>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending && !showDeleteTemplateDialog"/>
        </div>

        <div class="cell text-center">
          <button @click="onSaveBookingTime" class="button success"
                  :disabled="sending ||
                  (saveAsTemplate && createNewTemplate !== 'true' && templateName.length <= 0) ||
                  (saveAsTemplate && createNewTemplate === 'true' && newTemplateName.length <= 0) ||
                  ($v.newTemplateName.$invalid && createNewTemplate === 'true')">
            <i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Ja, Buchungszeit jetzt speichern
          </button>
          <button class="button alert" style="margin-left: 1rem;"
                  @click="closeSaveBookingTimeDialog"
                  type="button"
                  :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="closeSaveBookingTimeDialog">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDeleteTemplateDialog">
      <div v-if="selectedBookingTime" class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title>
          <md-icon>delete</md-icon>
          &nbsp;&nbsp;&nbsp;Vorlage löschen
        </md-dialog-title>
        <div class="cell text-center">
          <md-content>Soll die Vorlage <span class="group-title ">{{ templateName }}</span> wirklich gelöscht werden?
          </md-content>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>
        <div class="cell text-center">
          <button @click="onDeleteTemplate" class="button alert" :disabled="sending"><i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Ja,
            Vorlage jetzt löschen
          </button>
          <button class="button success" style="margin-left: 1rem;"
                  @click="closeDeleteTemplateDialog"
                  type="button"
                  :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="closeDeleteTemplateDialog">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

  </div>
</template>

<script>
import jQuery from 'jquery';
import BookingSlotView from '../../components/views/BookingSlotView';
import ChildService from '../../services/ChildService';
import FacilityService from '../../services/FacilityService';
import OrganizationService from '../../services/OrganizationService'
import HttpErrorHandler from '../../services/HttpErrorHandler';
import ImageView from '../../components/views/ImageView';
import Spinner from 'vue-simple-spinner';
import SessionTime from '../animations/SessionTime';
import Vue from 'vue';
import _ from 'lodash';

import {validationMixin} from 'vuelidate';
import {
  required,
} from 'vuelidate/lib/validators';
import BookingTime from '../../entities/BookingTime';
import {Md5} from 'ts-md5/dist/md5';

const isInTemplates = (value, vm) => {
  for (let template of vm.availableBookingTimeTemplates[vm.child.facilityReference]) {
    if (value === template.name) {
      return false;
    }
  }
  return true;
};

export default {
  name: 'EditBookingTimesDialog',
  mixins: [validationMixin],

  components: {
    ImageView,
    BookingSlotView,
    'vue-simple-spinner': Spinner,
    SessionTime,
  },

  props: {
    child: {
      required: true
    },
    childFacility: {
      required: true
    },
    bookingTimeIndex: {
      required: true
    },
    bookingTimesBackup: {
      required: true
    },
    showDialog: {
      required: true
    },
    mode: {
      required: true
    },
  },

  data: () => ({
    bookingTimeLabels: ['Morgens', 'Vormittags', 'Mittags', 'Nachmittags', 'Abends'],
    selectedBookingTimeRefNr: '',
    selectedBookingTime: null,
    selectedBookingTimeIndex: 0,
    selectedBookingTimePristine: true,
    canAddNewBookingTimeSheet: false,
    showDeleteBookingTimeDialog: false,
    showSaveBookingTimeDialog: false,
    showDeleteTemplateDialog: false,
    saveAsTemplate: false,
    createFee: false,
    createNewTemplate: 'false',
    templateName: '',
    newTemplateName: '',
    success: true,
    sending: false,
    errorMsg: '',
    errorCode: '',

    disabledFirstDay: date => {
      const day = date.getDate();
      return day !== 1;
    },

    disabledLastDay: date => {
      const day = date.getDate();
      const dateString = date.getFullYear() + '-' + Number(date.getMonth() + 1) + '-' + Number(date.getDay() + 1);
      const lastDay = Number(Vue.moment(dateString).endOf('month').format('DD'));
      return day !== lastDay
    },

    emptyPromotionCommunity: {
      promotionCommunityKey: '',
      promotionCommunityName: 'Keine Förderkommune ausgewählt',
    },

    invalid: true,
    validFromDate: null,
    validUntilDate: null,
    kigaStartDate: null,
    kigaEndDate: null,
    canWriteBackDates: true,

    facilities: [],
    facilityNames: {},
    bookingTimeTemplateIndex: -1,
    availableBookingTimeTemplates: {},
    kibigTimeSlotLabel: '',

    loading: false,
    promotionCommunities: [],
    searchTimer: null,
    showCoreTime: true,
    showClosedDays: false,
    recalculateData: true,
    recalculateAllowed: true,
    creationPossibleSuccessShown: true,
    absolutelySure: false,

    originalBookingTimes: null,
    bookingTimesMd5Hash: null,
    canCloseDialog: true,
    postCancelSaveNextBookingTime: false,
    postCancelSavePrevBookingTime: false,
    postCancelSaveAddBookingTime: false,
    recoverBookingTime: false,
    activeTabId: '',
    classicView: false,
  }),

  created() {
    this.getAllOrganizationFacilities();
    this.updateChild();
    this.resetPromotionCommunities();
  },

  updated() {
    this.updateChild();
  },

  watch: {
    showDialog() {
      this.createFee = false;
      this.canAddNewBookingTimeSheet = this.child.bookingTimes[this.child.bookingTimes.length - 1].refNr >= 0;

      setTimeout(() => this.initDatePickerSyncHandler(this.showDialog), 250);
      this.selectedBookingTimeIndex = this.bookingTimeIndex;
      this.setActiveTab();
    },

    child() {
      this.updateChild();
      this.setMD5Hash();
    },
  },

  validations: {
    selectedBookingTime: {
      validFrom: {
        required,
      },
      validUntil: {
        required,
      },
      kindergartenBeitragszuschussStart: {},
      kindergartenBeitragszuschussEnde: {},
      divisiontype: {
        required,
      },
      supportFactor: {
        required,
      },
      timeFactor: {
        required,
      },
      promotionCommunityName: {
        required,
      },
      type: {
        required,
      }
    },
    newTemplateName: {
      isInTemplates
    }
  },

  methods: {

    getElementId(prefix, bookingTimeNr) {
      return prefix + '-booking-time-id-' + Number(bookingTimeNr).toString();
    },

    onTabsChanged(tabId) {
      if (tabId && this.selectedBookingTimeIndex !== Number(tabId.substr(-1, 1))) {
        this.selectedBookingTimeIndex = Number(tabId.substr(-1, 1));
        this.canWriteBackDates = false;
        this.selectBookingTime();
      }
    },

    setActiveTab() {
      if (this.selectedBookingTimeIndex >= 0) {
        this.activeTabId = this.getElementId('tab', this.selectedBookingTimeIndex);
      } else {
        this.activeTabId = '';
      }
    },

    getBookingTimesTabLabel(bookingTime) {
      let label = this.$moment(bookingTime.validFrom).format('DD.MM.YYYY') + '\nbis\n' + this.$moment(bookingTime.validUntil).format('DD.MM.YYYY');
      label += '\n' + bookingTime.hoursPerWeek + ' Stunden';
      return label;
    },

    getBookingTimesTabLabelHover(bookingTime) {
      let label = '\n' + bookingTime.description;
      label = label.replaceAll('Uhr', 'Uhr\n');
      label = label.replaceAll('\n;', '\n');
      return label;
    },

    getBookingTimesTabIcon(bookingTime) {
      if (bookingTime) {
        if (bookingTime.type === 'REGULAR') {
          return 'watch_later';
        }
        if (bookingTime.type === 'HOLIDAY') {
          return 'beach_access';
        }
        if (bookingTime.type === 'SHORT') {
          return 'timelapse';
        }
      }
      return '';
    },

    setMD5Hash() {
      if (this.child && this.child.bookingTimes) {
        this.bookingTimesMd5Hash = Md5.hashAsciiStr(JSON.stringify(this.child.bookingTimes));
        this.originalBookingTimes = JSON.parse(JSON.stringify(this.child.bookingTimes));
      }
    },

    selectedBookingTimeHasChanged() {
      if (!this.originalBookingTimes) {
        this.setMD5Hash();
      }

      if (this.child && this.child.bookingTimes) {
        return !_.isEqual(this.originalBookingTimes, this.child.bookingTimes);
      }
      /*
              if (!this.bookingTimesMd5Hash) {
                this.setMD5Hash();
              }

              if (this.child && this.child.bookingTimes) {
                return (this.bookingTimesMd5Hash !== Md5.hashAsciiStr(JSON.stringify(this.child.bookingTimes)));
              }*/
    },

    showRegularType(type) {
      return type !== 'REGULAR' ? 'display: none' : ''
    },

    showOtherType(type) {
      return type === 'REGULAR' ? 'display: none' : ''
    },

    updateChild() {
      this.writeBackDates();
      this.selectBookingTime();
    },

    initDatePickerSyncHandler(init) {
      if (init) {
        setTimeout(() => {
          jQuery('#valid-from-date-dp').children('input').bind('blur', () => {
            this.validFromDate = jQuery('#valid-from-date-dp').children('input').val();
            this.updateDates();
          });
          jQuery('#valid-until-date-dp').children('input').bind('blur', () => {
            this.validUntilDate = jQuery('#valid-until-date-dp').children('input').val();
            this.updateDates();
          });
          jQuery('#valid-from-date-else-dp').children('input').bind('blur', () => {
            this.validFromDate = jQuery('#valid-from-date-else-dp').children('input').val();
            this.updateDates();
          });
          jQuery('#valid-until-date-else-dp').children('input').bind('blur', () => {
            this.validUntilDate = jQuery('#valid-until-date-else-dp').children('input').val();
            this.updateDates();
          });
          jQuery('#kiga-start-date-dp').children('input').bind('blur', () => {
            this.kigaStartDate = jQuery('#kiga-start-date-dp').children('input').val();
            this.updateDates();
          });
          jQuery('#kiga-end-date-dp').children('input').bind('blur', () => {
            this.kigaEndDate = jQuery('#kiga-end-date-dp').children('input').val();
            this.updateDates();
          });
          jQuery('#child-promotion-community-name-id').children('.md-menu').children('input').bind('focus', () => {
            setTimeout(() => jQuery('#child-promotion-community-name-id').children('.md-menu').children('input').trigger('click'), 250);
          });
        }, 500);
      } else {
        jQuery('#valid-from-date-dp').children('input').unbind('blur');
        jQuery('#valid-until-date-dp').children('input').unbind('blur');
        jQuery('#valid-from-date-else-dp').children('input').unbind('blur');
        jQuery('#valid-until-date-else-dp').children('input').unbind('blur');
        jQuery('#kiga-start-date-dp').children('input').unbind('blur');
        jQuery('#kiga-end-date-dp').children('input').unbind('blur');
        jQuery('#child-promotion-community-name-id').children('input').unbind('focus');
      }
    },

    updateDates() {
      this.writeBackDates();
      setTimeout(() => {
        this.recalculateBookingTime(this.selectedBookingTime)
      }, 100);
    },

    getAllOrganizationFacilities() {
      this.sending = true;
      OrganizationService.getAllFacilities()
          .then(response => {
            this.facilities = response.data;
            this.sending = false;
            this.buildListOfFacilityNamesAndAvailableTemplates();
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Einrichtungen für Träger ' + this.organization.name);
            this.sending = false;
          })
    },

    buildListOfFacilityNamesAndAvailableTemplates() {
      for (let facility of this.facilities) {
        this.facilityNames[facility.referenceNumber] = facility.name;
        this.availableBookingTimeTemplates[facility.referenceNumber] = facility.bookingTimeTemplates;
        this.availableBookingTimeTemplates[facility.referenceNumber].forEach(template => template.bookingTime.promotionCommunityName = '');
      }
    },

    formatValidFromDate() {
      if (this.validFromDate === null) {
        if (this.selectedBookingTime && this.selectedBookingTime.validFrom) {
          this.validFromDate = Vue.moment(this.selectedBookingTime.validFrom).format('DD.MM.YYYY');
        }
      }
    },

    formatValidUntilDate() {
      if (this.validUntilDate === null) {
        if (this.selectedBookingTime && this.selectedBookingTime.validUntil) {
          this.validUntilDate = Vue.moment(this.selectedBookingTime.validUntil).format('DD.MM.YYYY');
        }
      }
    },

    formatKigaStartDate() {
      if (this.kigaStartDate === null) {
        if (this.selectedBookingTime && this.selectedBookingTime.kindergartenBeitragszuschussStart) {
          this.kigaStartDate = Vue.moment(this.selectedBookingTime.kindergartenBeitragszuschussStart).format('DD.MM.YYYY');
        }
      }
    },

    formatKigaEndDate() {
      if (this.kigaEndDate === null) {
        if (this.selectedBookingTime && this.selectedBookingTime.kindergartenBeitragszuschussEnde) {
          this.kigaEndDate = Vue.moment(this.selectedBookingTime.kindergartenBeitragszuschussEnde).format('DD.MM.YYYY');
        }
      }
    },

    onCloseValidFromDatePicker() {
      this.updatePristineState();
      setTimeout(() => {
        this.recalculateBookingTime(this.selectedBookingTime)
      }, 100);
    },

    onCloseValidUntilDatePicker() {
      this.updatePristineState();
      setTimeout(() => {
        this.recalculateBookingTime(this.selectedBookingTime)
      }, 100);
    },

    onCloseKigaStartDatePicker() {
      this.updatePristineState();
    },

    onCloseKigaEndDatePicker() {
      this.updatePristineState();
    },

    onOpenValidUntilDatePicker() {
      if ((this.selectedBookingTime.validFrom && !this.selectedBookingTime.validUntil) ||
          (this.validFromDate && !this.validUntilDate)) {
        this.selectedBookingTime.validUntil = this.selectedBookingTime.validFrom;
        this.validUntilDate = this.validFromDate;
      }
    },

    onOpenKigaEndDatePicker() {
      if ((this.selectedBookingTime.kindergartenBeitragszuschussStart && !this.selectedBookingTime.kindergartenBeitragszuschussEnde) ||
          (this.kigaStartDate && !this.kigaEndDate)) {
        this.selectedBookingTime.kindergartenBeitragszuschussEnde = this.selectedBookingTime.kindergartenBeitragszuschussStart;
        this.kigaEndDate = this.kigaStartDate;
      }
    },

    getAssignmentStyle(day) {
      let time = this.selectedBookingTime.times[day.substr(0, 2).toUpperCase()];
      if (time) {
        return time.assigned ? '' : 'background: whitesmoke'
      }
    },

    showDay(day) {
      let time = this.selectedBookingTime.times[day.substr(0, 2).toUpperCase()];
      return !!time || this.showClosedDays;
    },

    getTimeString(day) {
      let time = this.selectedBookingTime.times[day.substr(0, 2).toUpperCase()];
      if (time) {
        let minutes = time.minutes;
        let result = '';
        if (Math.floor(minutes / 60) !== 0) {
          result += Math.floor(minutes / 60) + 'h ';
        }
        if (minutes % 60 !== 0) {
          result += minutes % 60 + 'min';
        }
        return result;
      }
    },

    selectBookingTime() {
      if (this.child && this.child.bookingTimes && this.child.bookingTimes.length > this.selectedBookingTimeIndex) {
        this.selectedBookingTime = this.child.bookingTimes[this.selectedBookingTimeIndex];
        this.kibigTimeSlotLabel = this.labels.kibigtimeslot[this.selectedBookingTime.kibigTimeSlot];
        if (this.showDialog) {
          if (!this.canWriteBackDates) {
            this.validFromDate = null;
            this.validUntilDate = null;
            this.kigaStartDate = null;
            this.kigaEndDate = null;
          }

          this.formatValidFromDate();
          this.formatValidUntilDate();
          this.formatKigaStartDate();
          this.formatKigaEndDate();

          if (!this.canWriteBackDates) {
            this.canWriteBackDates = true;
          }

        }
      } else {
        this.selectedBookingTime = null;
      }

      this.setActiveTab();
    },

    onUseTemplate() {
      this.writeBackDates();
      this.selectedBookingTimeRefNr = this.child.bookingTimes[this.selectedBookingTimeIndex].refNr;

      if (this.selectedBookingTimeIndex >= this.bookingTimesBackup.length) {
        this.bookingTimesBackup.push(this.child.bookingTimes[this.selectedBookingTimeIndex]);
      }

      this.child.bookingTimes[this.selectedBookingTimeIndex] = this.availableBookingTimeTemplates[this.child.facilityReference][this.bookingTimeTemplateIndex].bookingTime;
      this.bookingTimesBackup[this.selectedBookingTimeIndex].backupActive = true;
      this.child.bookingTimes[this.selectedBookingTimeIndex].refNr = this.selectedBookingTimeRefNr;
      this.recalculateAllowed = false;

      this.resetValidityDates();
      this.getPreFilledBookingTime(this.availableBookingTimeTemplates[this.child.facilityReference][this.bookingTimeTemplateIndex].refNr);
      this.bookingTimeTemplateIndex = -1;
    },

    onUndoTemplate() {
      this.child.bookingTimes[this.selectedBookingTimeIndex] = this.bookingTimesBackup[this.selectedBookingTimeIndex];
      this.bookingTimesBackup[this.selectedBookingTimeIndex].backupActive = false;
      this.bookingTimeTemplateIndex = -1;
      jQuery('div:contains("KiBiG")').trigger('click');
      setTimeout(() => jQuery('div:contains("Merkmale")').trigger('click'), 500);
      this.selectBookingTime();
    },

    getPreFilledBookingTime(btTemplateRefNr) {
      if (this.child) {
        this.sending = true;
        ChildService.getPreFilledBookingTime(this.child.id, btTemplateRefNr)
            .then(response => {
              this.sending = false;
              this.child.bookingTimes[this.selectedBookingTimeIndex] = response.data;

              if (btTemplateRefNr !== '') {
                this.child.bookingTimes[this.selectedBookingTimeIndex].refNr = this.selectedBookingTimeRefNr;
              }

              if (this.selectedBookingTimeIndex >= this.bookingTimesBackup.length) {
                this.bookingTimesBackup.push(this.child.bookingTimes[this.selectedBookingTimeIndex]);
              }

              if (btTemplateRefNr === '' && this.canAddNewBookingTimeSheet === false && this.selectedBookingTimeIndex === this.bookingTimesBackup.length - 1) {
                this.bookingTimesBackup[this.selectedBookingTimeIndex] = this.child.bookingTimes[this.selectedBookingTimeIndex];
                this.bookingTimesBackup[this.selectedBookingTimeIndex].backupActive = true;
              }

              this.validFromDate = null;
              this.validUntilDate = null;
              this.kigaStartDate = null;
              this.kigaEndDate = null;

              this.selectBookingTime();
              this.getPromotionCommunities();

              this.recalculateAllowed = true;
              this.recalculateBookingTime(this.selectedBookingTime);
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der vorausgefüllten Buchungszeit für Kind '
                  + this.child.masterdata.firstname + ' ' + this.child.masterdata.lastname);
              this.sending = false;
            })
      }
    },

    onAutoKigaSupport() {
      if (this.selectedBookingTime.kindergartenBeitragszuschussAutoCalculationEnabled) {
        this.recalculateBookingTime(this.selectedBookingTime);
      }
    },

    recalculateBookingTime(bookingTime) {
      if (this.recalculateAllowed && this.child && !this.child.archived && this.recalculateData) {
        this.sending = true;
        this.writeBackDates();
        ChildService.recalculateBookingTime(this.child.id, bookingTime)
            .then(response => {
              this.sending = false;
              this.child.bookingTimes[this.selectedBookingTimeIndex] = response.data;

              this.validFromDate = null;
              this.validUntilDate = null;
              this.kigaStartDate = null;
              this.kigaEndDate = null;

              this.selectBookingTime();

              if (!this.selectedBookingTime.creationPossible) {
                this.$store.commit('warningMsg', 'Warnung für ausgewählte ' + this.labels.bookingTimeType[bookingTime.type] + '<br><b>' + this.selectedBookingTime.creationErrorMessage + '</b>');
                this.creationPossibleSuccessShown = false;
              } else {
                if (!this.creationPossibleSuccessShown) {
                  this.$store.commit('successMsg', 'Alle Eingaben für ausgewählte Buchungszeit in Ordnung');
                  this.creationPossibleSuccessShown = true;
                }
              }
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Neuberechnen der Buchungszeit für Kind '
                  + this.child.masterdata.firstname + ' ' + this.child.masterdata.lastname);
              this.sending = false;
            })
      }
    },

    changeBookingTime() {
      this.updatePristineState();
      if (this.selectedBookingTime) {
        this.recalculateBookingTime(this.selectedBookingTime);
      }
    },

    updatePristineState() {
      setTimeout(() => {
        if (this.validFromDate && this.validUntilDate) {
          this.selectedBookingTimePristine = false;
        } else {
          this.selectedBookingTimePristine = true;
        }
      }, 100)
    },

    resetValidityDates() {
      this.validFromDate = null;
      this.validUntilDate = null;
    },

    onPrevBookingTime() {
      if (this.selectedBookingTimeHasChanged()) {
        this.canCloseDialog = false;
        this.postCancelSavePrevBookingTime = true;
        this.recoverBookingTime = true;
        this.onSaveBookingTimeSheet();
        return;
      }

      this.prevBookingTime();
    },

    prevBookingTime() {
      this.postCancelSavePrevBookingTime = false;

      if (this.selectedBookingTimeIndex > 0) {
        this.writeBackDates();
        this.selectedBookingTimeIndex--;
        this.resetValidityDates();
        this.selectBookingTime();
        this.setMD5Hash();
      }
    },

    onNextBookingTime() {
      if (this.selectedBookingTimeHasChanged()) {
        this.canCloseDialog = false;
        this.postCancelSaveNextBookingTime = true;
        this.recoverBookingTime = true;
        this.onSaveBookingTimeSheet();
        return;
      }

      this.nextBookingTime();
    },

    nextBookingTime() {
      this.postCancelSaveNextBookingTime = false;

      if (this.selectedBookingTimeIndex < this.child.bookingTimes.length - 1) {
        this.writeBackDates();
        this.selectedBookingTimeIndex++;
        this.resetValidityDates();
        this.selectBookingTime();
        this.setMD5Hash();
      }
    },

    onAddBookingTimeSheet() {
      if (this.selectedBookingTimeHasChanged()) {
        this.canCloseDialog = false;
        this.postCancelSaveAddBookingTime = true;
        this.recoverBookingTime = true;
        this.onSaveBookingTimeSheet();
        return;
      }

      this.addBookingTimeSheet();
    },

    addBookingTimeSheet() {
      this.postCancelSaveAddBookingTime = false;

      this.recalculateAllowed = false; // Avoid execution of recalculateBookingTime() due to multiple event triggers

      let newBookingTime = JSON.parse(JSON.stringify(BookingTime.bookingTime));

      this.child.bookingTimes.push(newBookingTime);
      this.bookingTimesBackup.push(newBookingTime);

      this.selectedBookingTimeIndex = this.child.bookingTimes.length - 1;

      this.canAddNewBookingTimeSheet = false;
      this.validFromDate = null;
      this.validUntilDate = null;

      this.getPreFilledBookingTime('');
      this.selectBookingTime();
    },

    addBookingTime() {
      if (this.selectedBookingTime) {
        this.sending = true;
        this.success = false;
        let templateName = '';
        if (this.saveAsTemplate) {
          templateName = this.newTemplateName;
        }

        ChildService.addBookingTime(this.child.id, this.selectedBookingTime, templateName, this.createFee)
            .then(response => {
              this.child.id = response.data.id;
              this.success = true;
              this.sending = false;
              this.selectedBookingTimePristine = true;
              this.$emit("updateBookingTimeSuccess", response.data);

              if (this.saveAsTemplate) {
                this.getAllOrganizationFacilities();
              }
              this.templateName = '';
              this.saveAsTemplate = false;
              this.createFee = false;

              this.setMD5Hash();
              this.closeSaveBookingTimeDialog();
              this.closeDialog();
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Hinzufügen einer neuen Buchungszeit für '
                  + this.child.masterdata.firstname + ' ' + this.child.masterdata.lastname);
              this.sending = false;
            })
      }
    },

    onSaveBookingTimeSheet() {
      if (this.child.archived) {
        this.closeSaveBookingTimeDialog();
      }

      if (this.selectedBookingTime && !this.child.archived) {
        jQuery('#editBookingTimeDialog').attr("style", "display:none");
        this.showSaveBookingTimeDialog = true;
      }
    },

    closeSaveBookingTimeDialog() {
      if (this.postCancelSaveNextBookingTime || this.postCancelSavePrevBookingTime || this.postCancelSaveAddBookingTime) {

        if (this.recoverBookingTime) {
          this.bookingTimesBackup[this.selectedBookingTimeIndex].backupActive = false;
          this.child.bookingTimes[this.selectedBookingTimeIndex] = JSON.parse(JSON.stringify(this.bookingTimesBackup[this.selectedBookingTimeIndex]));
          this.selectedBookingTime = this.child.bookingTimes[this.selectedBookingTimeIndex];

          this.recoverBookingTime = false;
        }

        this.canCloseDialog = false;
        if (this.postCancelSaveNextBookingTime) {
          this.nextBookingTime();
        }
        if (this.postCancelSavePrevBookingTime) {
          this.prevBookingTime();
        }
        if (this.postCancelSaveAddBookingTime) {
          this.addBookingTimeSheet();
        }

        this.canCloseDialog = false;
      }

      if (this.canCloseDialog) {
        this.setMD5Hash();
        this.closeDialog();
      }

      jQuery('#editBookingTimeDialog').attr("style", "display:normal");
      this.showSaveBookingTimeDialog = false;
      this.newTemplateName = '';
      this.createNewTemplate = 'false';
      this.canCloseDialog = true;
    },

    onSaveBookingTime() {
      this.recoverBookingTime = false;

      if (this.postCancelSaveNextBookingTime || this.postCancelSavePrevBookingTime || this.postCancelSaveAddBookingTime) {
        this.canCloseDialog = false;
      }

      if (this.selectedBookingTime && this.selectedBookingTime.refNr < 0) {
        this.addBookingTime();
      } else {
        this.updateBookingTime();
      }
    },

    onDeleteBookingTimeSheet() {
      if (this.selectedBookingTime && this.selectedBookingTime && this.selectedBookingTime.refNr >= 0) {
        jQuery('#editBookingTimeDialog').attr("style", "display:none");
        this.absolutelySure = false;
        this.showDeleteBookingTimeDialog = true;
      }
    },

    closeDeleteBookingTimeDialog() {
      jQuery('#editBookingTimeDialog').attr("style", "display:normal");
      this.showDeleteBookingTimeDialog = false;
    },

    onDeleteBookingTime() {
      if (this.selectedBookingTime && this.selectedBookingTime.refNr >= 0) {
        this.deleteBookingTime();
      } else {
        this.child.bookingTimes.splice(this.selectedBookingTimeIndex, 1);
        this.bookingTimesBackup.splice(this.selectedBookingTimeIndex, 1);
        if (this.selectedBookingTimeIndex > this.bookingTimesBackup.length) {
          this.selectedBookingTimeIndex -= 1;
        }
        this.canAddNewBookingTimeSheet = true;
        this.closeDeleteBookingTimeDialog();
      }
    },

    deleteBookingTime() {
      if (this.selectedBookingTime) {
        this.sending = true;
        this.success = false;
        ChildService.deleteBookingTime(this.child.id, this.selectedBookingTime.refNr)
            .then(response => {
              this.child.id = response.data.id;
              this.success = true;
              this.sending = false;
              this.selectedBookingTimePristine = true;
              this.$emit("updateBookingTimeSuccess", response.data);

              this.setMD5Hash();
              this.closeDeleteBookingTimeDialog();
              this.closeDialog();
            })
            .catch(e => {
              this.closeDeleteBookingTimeDialog();
              HttpErrorHandler.handleError(e, this, 'Fehler beim Löschen der Buchungszeit mit RefNr'
                  + this.selectedBookingTime.refNr + ' für ' + this.child.masterdata.fullName);
              this.sending = false;
            })
      }
    },

    updateBookingTime() {
      if (this.selectedBookingTime) {
        this.sending = true;
        this.success = false;
        let templateName = '';
        if (this.saveAsTemplate) {
          templateName = this.newTemplateName;
        }
        ChildService.updateBookingTime(this.child.id, this.selectedBookingTime, templateName)
            .then(response => {
              this.child.id = response.data.id;
              this.success = true;
              this.sending = false;
              this.selectedBookingTimePristine = true;
              this.$emit("updateBookingTimeSuccess", response.data);

              if (this.saveAsTemplate) {
                setTimeout(() => this.$emit("bookingTimeTemplateSuccess", 'Die neue Vorlage <b>' + templateName + '</b> wurde erfolgreich angelegt!'), 500);
                this.getAllOrganizationFacilities();
              }
              this.templateName = '';
              this.saveAsTemplate = false;
              this.setMD5Hash();
              this.closeSaveBookingTimeDialog();
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Speichern der Änderungen für Buchungszeit mit RefNr '
                  + this.selectedBookingTime.refNr);
              this.sending = false;
            })
      }
    },

    transferBookingTime(index) {
      let srcDay = this.$material.locale.days[index];
      let dstDay = this.$material.locale.days[(index + 1) % 5];
      this.selectedBookingTime.times[dstDay.substr(0, 2).toUpperCase()] =
          JSON.parse(JSON.stringify(this.selectedBookingTime.times[srcDay.substr(0, 2).toUpperCase()]));
      this.recalculateBookingTime(this.selectedBookingTime);
    },

    clearBookingTime(index) {
      let day = this.$material.locale.days[index];
      this.selectedBookingTime.times[day.substr(0, 2).toUpperCase()] = {
        from: "00:00:00",
        until: "00:00:00",
        assigned: false,
        minutes: 0
      };
      this.recalculateBookingTime(this.selectedBookingTime);
    },

    onDeleteTemplateDialog() {
      if (this.selectedBookingTime) {
        jQuery('#saveBookingTimeDialog').attr("style", "display:none");
        this.showDeleteTemplateDialog = true;
      }
    },

    onDeleteTemplate() {
      if (this.selectedBookingTime) {
        this.deleteTemplate();
      }
    },

    closeDeleteTemplateDialog() {
      jQuery('#saveBookingTimeDialog').attr("style", "display:normal");
      this.showDeleteTemplateDialog = false;
    },

    deleteTemplate() {
      if (this.selectedBookingTime) {
        this.sending = true;
        this.success = false;
        FacilityService.deleteBookingTimeTemplate(this.childFacility.referenceNumber, this.templateName)
            .then(response => {
              this.availableBookingTimeTemplates[this.child.facilityReference] = response.data;
              this.$emit("bookingTimeTemplateSuccess", 'Die Vorlage <b>' + this.templateName + '</b> wurde erfolgreich gelöscht.');
              this.templateName = '';
              this.success = true;
              this.sending = false;
              this.closeDeleteTemplateDialog();
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Löschen der Vorlage ' + this.selectedBookingTime.refNr);
              this.sending = false;
            })
      }
    },

    getValidationClass(fieldName) {
      let field = this.$v.selectedBookingTime[fieldName];
      if (!field) {
        field = this.$v[fieldName];
      }
      if (field) {
        if (!this.selectedBookingTime.kindergartenBeitragszuschussAutoCalculationEnabled && field.$dirty &&
            ((fieldName === 'kindergartenBeitragszuschussStart' && this.kigaStartDate === null) ||
                (fieldName === 'kindergartenBeitragszuschussEnde' && this.kigaEndDate === null))) {
          return 'md-invalid';
        }

        return {
          'md-invalid': (field.$invalid && field.$dirty) ||
              (field.$model === 'Invalid date' && field.$dirty)
        }
      }
    },

    writeBackDates() {
      if (!this.canWriteBackDates) {
        return;
      }

      if (this.selectedBookingTime && this.showDialog) {
        if (this.validFromDate) {
          this.selectedBookingTime.validFrom = Vue.moment(this.validFromDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
          this.child.bookingTimes[this.selectedBookingTimeIndex].validFrom = this.selectedBookingTime.validFrom;
        } else {
          this.selectedBookingTime.validFrom = null;
        }

        if (this.validUntilDate) {
          this.selectedBookingTime.validUntil = Vue.moment(this.validUntilDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
          this.child.bookingTimes[this.selectedBookingTimeIndex].validUntil = this.selectedBookingTime.validUntil;
        } else {
          this.selectedBookingTime.validUntil = null;
        }

        if (!this.selectedBookingTime.kindergartenBeitragszuschussAutoCalculationEnabled) {
          if (this.kigaStartDate) {
            this.selectedBookingTime.kindergartenBeitragszuschussStart = Vue.moment(this.kigaStartDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
            this.child.bookingTimes[this.selectedBookingTimeIndex].kindergartenBeitragszuschussStart = this.selectedBookingTime.kindergartenBeitragszuschussStart;
          } else {
            this.selectedBookingTime.kindergartenBeitragszuschussStart = null;
          }

          if (this.kigaEndDate) {
            this.selectedBookingTime.kindergartenBeitragszuschussEnde = Vue.moment(this.kigaEndDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
            this.child.bookingTimes[this.selectedBookingTimeIndex].kindergartenBeitragszuschussEnde = this.selectedBookingTime.kindergartenBeitragszuschussEnde;
          } else {
            this.selectedBookingTime.kindergartenBeitragszuschussEnde = null;
          }
        }
      }
    },

    validateBookingTimes() {
      this.writeBackDates();

      this.$v.$touch();
      this.invalid = this.$v.$invalid;

      if (!this.selectedBookingTime.kindergartenBeitragszuschussAutoCalculationEnabled &&
          (this.selectedBookingTime.kindergartenBeitragszuschussStart === null || this.selectedBookingTime.kindergartenBeitragszuschussEnde === null)) {
        this.invalid = true;
      }

      if (!this.selectedBookingTime.validUntil || !this.selectedBookingTime.validFrom) {
        jQuery('#dialogContentId').animate({scrollTop: 0}, 'slow');
        this.invalid = true;
      }

      if (!this.selectedBookingTime.promotionCommunityName) {
        jQuery('div:contains("KiBiG")').trigger('click');
      }

      if (!this.invalid) {
        this.onSaveBookingTimeSheet();
      }
    },

    closeDialog() {
      if (this.selectedBookingTimeHasChanged()) {
        this.onSaveBookingTimeSheet();
        return;
      }

      if (this.canCloseDialog) {
        this.$emit("closeEditBookingTimesDialog");
        this.$v.$reset();
        this.selectedBookingTimePristine = true;
        this.validFromDate = null;
        this.validUntilDate = null;
        this.bookingTimeTemplateIndex = -1;
        this.selectedBookingTimeIndex = 0;
        this.canAddNewBookingTimeSheet = true;

        this.resetPromotionCommunities();
      }
      this.canCloseDialog = true;
    },

    getPromotionCommunities() {
      if (this.selectedBookingTime.promotionCommunityName && this.selectedBookingTime.promotionCommunityName.length > 1) {
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => {
          if (this.selectedBookingTime.promotionCommunityName.length > 1) {
            this.searchCommunitiesByName(this.selectedBookingTime.promotionCommunityName);
          }
        }, 250);
      } else {
        this.resetPromotionCommunities();
      }
    },

    searchCommunitiesByName(name) {
      this.loading = true;
      ChildService.getCommunitiesByName(name)
          .then(response => {
            this.loading = false;
            let communities = response.data;
            this.promotionCommunities = [];

            let i = 0;
            communities.forEach(community => {
              if (i < 100) {
                this.promotionCommunities.push({
                  promotionCommunityKey: community.commKey,
                  promotionCommunityName: community.name
                });
                i++;
              }
            })
          })
          .catch(e => {
            this.loading = false;
            this.resetPromotionCommunities();
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Förderkommunen');
          })
    },

    selectPromotionCommunity(community) {
      if (community) {
        this.selectedBookingTime.promotionCommunityName = community.promotionCommunityName;
        this.selectedBookingTime.promotionCommunityKey = community.promotionCommunityKey;

        this.validatePromotionCommunity();
      }
    },

    validatePromotionCommunity() {
      setTimeout(() => {
        let valid = false;
        if (this.promotionCommunities) {
          this.promotionCommunities.forEach(community => {
            if (this.selectedBookingTime.promotionCommunityName === community.promotionCommunityName &&
                community.promotionCommunityName !== this.emptyPromotionCommunity.promotionCommunityName) {
              valid = true;
            }
          })
          if (!valid) {
            this.selectedBookingTime.promotionCommunityName = '';
          }
        }
      }, 300)
    },

    resetPromotionCommunities() {
      if (this.selectedBookingTime && this.selectedBookingTime.promotionCommunityName && this.selectedBookingTime.promotionCommunityName.length > 1) {
        this.promotionCommunities = [{
          promotionCommunityKey: this.selectedBookingTime.promotionCommunityKey,
          promotionCommunityName: this.selectedBookingTime.promotionCommunityName,
        }];
      } else {
        this.promotionCommunities = [this.emptyPromotionCommunity];
      }
    },
  },

  computed: {

    isAuthenticated() {
      return this.$store.getters.authenticated
    },

    isAdmin() {
      return this.$store.getters.admin
    },

    isSage() {
      return this.$store.getters.sage
    },

    organization() {
      return this.$store.getters.organization;
    },

    labels() {
      return this.$store.getters.labels
    },
  },
};
</script>


<style lang="scss">
#editBookingTimesTabsId .md-tabs-navigation > .md-button {
  white-space: pre !important;
}
#editBookingTimesTabsId .md-tabs-navigation > .md-button > .md-ripple {
  align-items: baseline !important;
}
#editBookingTimesTabsId .md-tabs-navigation .md-ripple {
  padding: 6px 12px;
}
#editBookingTimesTabsId .md-button.md-tab-nav-button {
  height: 120px;
  text-transform: none;
}
#editBookingTimesTabsId .md-tabs-navigation {
  padding: 0;
}
#editBookingTimesTabsId .md-tab {
  padding: 0;
}
</style>

<style scoped>
.md-dialog.edit-booking-times-dialog /deep/ .md-dialog-container {
  min-width: 95%;
  min-height: 95%;
}

.md-dialog.save-booking-times-dialog /deep/ .md-dialog-container {
  max-width: 850px !important;
}

.md-dialog.delete-booking-times-dialog /deep/ .md-dialog-container {
  max-width: 850px !important;
}

.md-icon {
  color: black;
}

p {
  margin: 10px;
}

span {
  color: cornflowerblue;
}

h5 {
  font-weight: 400;
  font-size: x-large;
  color: lightseagreen;
  margin-bottom: 0;
  margin-top: 5px;
}

.validity-period {
  text-align: left;
  padding: 5px;
  font-weight: 300;
  font-size: large;
}

.validity-description {
  text-align: left;
  padding: 5px;
  font-weight: 300;
  font-size: small;
  color: lightseagreen;
}

table {
  display: table;
  border-collapse: separate;
  border-spacing: 2px;
  border-color: grey;
  empty-cells: hide;
  width: 100%;
  margin: 0;
}

th {
  width: 12.5%;
  font-weight: 300;
  text-align: center;
  padding: 5px 0;
}

.md-checkbox {
  margin: 5px;
}

.md-micro {
  width: 25px;
  height: 25px;
  padding: 4px;
  margin: 0px 8px;
}

.md-icon-button {
  min-width: 24px;
}

.md-icon {
  font-size: 20px !important;
}

.crosshatch {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHhtbG5zOnhsaW5rPSdodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rJyB3aWR0aD0nNicgaGVpZ2h0PSc2Jz4KICA8cmVjdCB3aWR0aD0nNicgaGVpZ2h0PSc2JyBmaWxsPScjZWVlZWVlJy8+CiAgPGcgaWQ9J2MnPgogICAgPHJlY3Qgd2lkdGg9JzMnIGhlaWdodD0nMycgZmlsbD0nI2U2ZTZlNicvPgogICAgPHJlY3QgeT0nMScgd2lkdGg9JzMnIGhlaWdodD0nMicgZmlsbD0nI2Q4ZDhkOCcvPgogIDwvZz4KICA8dXNlIHhsaW5rOmhyZWY9JyNjJyB4PSczJyB5PSczJy8+Cjwvc3ZnPg==");
  background-repeat: repeat;
  opacity: 0.5;
  width: 100%;
  color: black;
}

.md-tooltip {
  font-size: medium;
  height: auto;
  white-space: normal;
}
</style>

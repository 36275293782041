<template>
  <div v-if="child">
    <md-dialog :md-click-outside-to-close="false" id="createNewContractDialog" :md-active.sync="showDialog" @md-closed="closeDialog">

      <md-dialog-title>

        <form novalidate @submit.prevent="validateContract">

          <div class="grid-x grid-padding-x">
            <div v-if="mode === 'view'" class="cell large-5 medium-12">
              <i class="fi-eye"></i>&nbsp;&nbsp;&nbsp;Vertrag einsehen
            </div>
            <div v-if="mode === 'update'" class="cell large-5 medium-12">
              <i class="fi-pencil"></i>&nbsp;&nbsp;&nbsp;Vertrag bearbeiten
            </div>
            <div v-else-if="mode === 'add'" class="cell large-5 medium-12">
              <img width="25px" src="../../assets/icons/contract-sample.svg" alt="Vertragsdokument"/>&nbsp;&nbsp;&nbsp;Neuen Vertrag hinzufügen
            </div>
            <div class="cell large-2 medium-2 small-12" style="margin-top: -5px;">
              <SessionTime></SessionTime>
            </div>
            <div v-if="mode !== 'view'" class="cell large-5 medium-12 text-center">
              <button v-if="mode === 'update'" class="button success" type="submit" :disabled="sending">
                <i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Änderungen speichern
              </button>
              <button v-else-if="mode === 'add'" class="button success" type="submit" :disabled="sending">
                <img width="16px" src="../../assets/icons/contract-sample.svg" alt="Vertragsdokument"/>&nbsp;&nbsp;&nbsp;Vertrag hinzufügen
              </button>
              <button class="button alert" style="margin-left: 1rem;" @click="closeDialog" type="button"
                      :disabled="sending">
                <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Abbrechen
              </button>
            </div>
          </div>

        </form>

      </md-dialog-title>

      <md-dialog-content id="dialogContentId">
        <md-tabs id="contract-tabs-id" v-if="contract" md-dynamic-height>
          <md-tab id="1" md-label="Vertragsdaten">
            <ContractForm ref="contractForm" :contract="contract" :child="child" :mode="mode" @updateContract="onUpdateContract"></ContractForm>
          </md-tab>
          <md-tab id="2" md-label="Vertragspartner">
            <div class="grid-x grid-padding-x">
              <div class="cell large-12 medium-12 small-12">
                <br>
                <h5>Daten</h5>
              </div>
            </div>
            <div class="grid-x grid-padding-x">
              <div class="cell large-6 medium-6 small-6">
                <md-field>
                  <label>Vertragspartner ist</label>
                  <md-select v-model="newRelatedPersonChildRelationSrc" autocomplete="off"
                             @md-selected="onCopyRelatedPerson" :disabled="sending">
                    <md-option value="CHILD">
                      Adresse von Kind übernehmen
                    </md-option>
                    <md-option v-for="(relatedSrcPerson, index) of child.relatedPersons"
                               v-if="relatedSrcPerson.childRelation !== newRelatedPerson2ChildRelationSrc"
                               :key="index" :value="relatedSrcPerson.childRelation">
                      {{labels.childRelation[relatedSrcPerson.childRelation]}}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="cell large-4 medium-5 small-6">
                <br>
                <md-switch class="md-primary" v-model="contract.secondSigningPerson" @change="toggleSecondSigningPerson">2. Vertragspartner</md-switch>
              </div>
              <div class="cell large-2 medium-1 hide-for-small-only">
                <br>
                <div v-if="copying">
                  <vue-simple-spinner></vue-simple-spinner>
                </div>
              </div>
              <div class="cell large-6 medium-6 small-6"></div>
            </div>
            <PersonForm ref="responsiblePersonForm" :birthDateDisabled="false"
                        :person="contract.signingPerson" :sending="sending"></PersonForm>
            <div class="grid-x grid-padding-x">
              <div class="cell large-12 medium-12 small-12">
                <br>
                <h5>Adresse</h5>
              </div>
            </div>
            <AddressForm ref="responsiblePersonAddressForm" :address="contract.signingPerson.address"
                         :sending="sending"></AddressForm>
          </md-tab>
          <md-tab v-if="contract.secondSigningPerson" id="3" md-label="2. Vertragspartner">
            <div class="grid-x grid-padding-x">
              <div class="cell large-12 medium-12 small-12">
                <br>
                <h5>Daten</h5>
              </div>
            </div>
            <div class="grid-x grid-padding-x">
              <div class="cell large-6 medium-6 small-6">
                <md-field>
                  <label>2. Vertragspartner ist</label>
                  <md-select v-model="newRelatedPerson2ChildRelationSrc" autocomplete="off"
                             @md-selected="onCopyRelatedPerson2" :disabled="sending">
                    <md-option value="CHILD">
                      Adresse von Kind übernehmen
                    </md-option>
                    <md-option v-for="(relatedSrcPerson, index) of child.relatedPersons"
                               v-if="relatedSrcPerson.childRelation !== newRelatedPersonChildRelationSrc"
                               :key="index" :value="relatedSrcPerson.childRelation">
                        {{labels.childRelation[relatedSrcPerson.childRelation]}}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="cell large-4 medium-5 small-6">
                <br>
              </div>
              <div class="cell large-2 medium-1 hide-for-small-only">
                <br>
                <div v-if="copying">
                  <vue-simple-spinner></vue-simple-spinner>
                </div>
              </div>
              <div class="cell large-6 medium-6 small-6"></div>
            </div>
            <PersonForm ref="responsiblePerson2Form" :birthDateDisabled="false"
                        :person="contract.signingPerson2" :sending="sending"></PersonForm>
            <div class="grid-x grid-padding-x">
              <div class="cell large-12 medium-12 small-12">
                <br>
                <h5>Adresse</h5>
              </div>
            </div>
            <AddressForm ref="responsiblePerson2AddressForm" :address="contract.signingPerson2.address"
                         :sending="sending"></AddressForm>
          </md-tab>
          <md-tab v-if="contract.paymentMethod === 'SEPA'" id="4" md-label="Bankverbindung">
            <BankAccountForm ref="bankAccountForm" :bankAccount="contract.account" :mandateReferenceDisabled="false"
                             :paymentMethod="contract.paymentMethod" :firstIsRecurrent="true"
                             :sending="sending"></BankAccountForm>
          </md-tab>
        </md-tabs>

        <md-progress-bar md-mode="indeterminate" v-if="sending"/>

      </md-dialog-content>

      <button class="close-button" type="button" @click="closeDialog">
        <span aria-hidden="true">&times;</span>
      </button>
    </md-dialog>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="confirmMissingContractDataDialog">
      <div class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title><img width="25px" src="../../assets/icons/contract-sample.svg" alt="Vertragsdokument"/>&nbsp;&nbsp;&nbsp;Unvollständigen
          Datensatz für Vertrag bestätigen
        </md-dialog-title>
        <div class="cell">
          <md-content>Soll der unvollständige Datensatz wirklich gespeichert werden?
          </md-content>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>
        <div class="cell text-center">
          <div @click="confirmProcessingData" class="button success"><i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Ja,
            speichern
          </div>
          <button class="button alert" style="margin-left: 1rem;"
                  @click="closeConfirmationDialog"
                  type="button"
                  :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="closeConfirmationDialog">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>
  </div>
</template>

<script>

  import jQuery from 'jquery';
  import ChildService from '../../services/ChildService'
  import HttpErrorHandler from '../../services/HttpErrorHandler'
  import ContractForm from '../forms/ContractForm';
  import PersonForm from '../forms/PersonForm';
  import AddressForm from '../forms/AddressForm';
  import BankAccountForm from '../forms/BankAccountForm';
  import Spinner from 'vue-simple-spinner'
  import SessionTime from '../animations/SessionTime';
  import Person from "@/entities/Person";

  export default {
    name: 'EditContractDialog',

    components: {
      ContractForm,
      PersonForm,
      AddressForm,
      BankAccountForm,
      'vue-simple-spinner': Spinner,
      SessionTime,
    },

    props: {
      child: {
        required: true
      },
      contract: {
        required: true
      },
      showDialog: {
        required: true
      },
      mode: {
        required: true
      }
    },

    watch: {
      contract() {
        if (this.contract) {
          if (this.contract.signingPerson) {
            this.newRelatedPersonChildRelationSrc = this.contract.signingPerson.childRelation;
          }
          if (this.contract.signingPerson2) {
            this.newRelatedPerson2ChildRelationSrc = this.contract.signingPerson2.childRelation;
          }
        }
      },
    },

    data: () => ({
      success: true,
      sending: false,
      confirmMissingContractDataDialog: false,
      newRelatedPersonChildRelationSrc: '',
      newRelatedPerson2ChildRelationSrc: '',
      copying: false,
    }),

    methods: {

      toggleSecondSigningPerson() {
        if (this.contract.secondSigningPerson) {
          if (this.contract.signingPerson2 == null) {
            this.contract.signingPerson2 = JSON.parse(JSON.stringify(Person.person));
            this.newRelatedPerson2ChildRelationSrc = this.contract.signingPerson2.childRelation;
          }
        }
      },

      addContract() {
        this.sending = true;
        this.success = false;
        ChildService.addContract(this.child.id, this.contract)
          .then(response => {
            const child = JSON.parse(JSON.stringify(response.data));
            this.success = true;
            this.sending = false;
            this.$emit("closeEditContractDialog");
            this.$emit("updateContractSuccess", child);
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Hinzufügen des Vertrags mit der Nr ' + this.contract.contractReferenceNumber + ' für Kind ' + this.child.masterdata.fullName);
            this.sending = false;
          })
      },

      updateContract() {
        this.sending = true;
        this.success = false;
        ChildService.updateContract(this.child.id, this.contract)
          .then(response => {
            const child = JSON.parse(JSON.stringify(response.data));
            this.success = true;
            this.sending = false;
            this.$emit("closeEditContractDialog");
            this.$emit("updateContractSuccess", child);
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Aktualisieren des Vertrags mit der Nr ' + this.contract.contractReferenceNumber + ' für Kind ' + this.child.masterdata.fullName);
            this.sending = false;
          })
      },

      getValidationClass(fieldName) {
        let field = this.$v.contract[fieldName];

        if (field) {
          return {
            'md-invalid': field.$invalid && field.$dirty
          }
        }
      },

      onCopyRelatedPerson() {
        if (this.newRelatedPersonChildRelationSrc.length > 0) {
          for (let srcRelatedPerson of this.child.relatedPersons) {
            if (srcRelatedPerson.childRelation === this.newRelatedPersonChildRelationSrc) {
              this.copying = true;
              setTimeout(() => {
                this.contract.signingPerson = JSON.parse(JSON.stringify(srcRelatedPerson));
                this.newRelatedPersonChildRelationSrc = this.contract.signingPerson.childRelation;
                this.copying = false;
              }, 500);
              return;
            }
          }
          if (this.newRelatedPersonChildRelationSrc === 'CHILD') {
            this.copying = true;
            setTimeout(() => {
              this.contract.signingPerson.address = JSON.parse(JSON.stringify(this.child.masterdata.address));
              this.contract.signingPerson.childRelation = null;
              this.newRelatedPersonChildRelationSrc = '';
              this.copying = false;
            }, 500);
          }
        }
      },

      onCopyRelatedPerson2() {
        if (this.newRelatedPerson2ChildRelationSrc.length > 0) {
          for (let srcRelatedPerson of this.child.relatedPersons) {
            if (srcRelatedPerson.childRelation === this.newRelatedPerson2ChildRelationSrc) {
              this.copying = true;
              setTimeout(() => {
                this.contract.signingPerson2 = JSON.parse(JSON.stringify(srcRelatedPerson));
                this.newRelatedPerson2ChildRelationSrc = this.contract.signingPerson2.childRelation;
                this.copying = false;
              }, 500);
              return;
            }
          }
          if (this.newRelatedPerson2ChildRelationSrc === 'CHILD') {
            this.copying = true;
            setTimeout(() => {
              this.contract.signingPerson2.address = JSON.parse(JSON.stringify(this.child.masterdata.address));
              this.contract.signingPerson2.childRelation = null;
              this.newRelatedPerson2ChildRelationSrc = '';
              this.copying = false;
            }, 500);
          }
        }
      },

      confirmMissingData() {
        jQuery('#createNewContractDialog').attr("style", "display:none");
        this.confirmMissingContractDataDialog = true;
      },

      closeConfirmationDialog() {
        this.confirmMissingContractDataDialog = false;
        jQuery('#createNewContractDialog').attr("style", "display:normal");
      },

      confirmProcessingData() {
        this.closeConfirmationDialog();
        if (this.mode === 'update') {
          this.updateContract();
        }
        if (this.mode === 'add') {
          this.addContract();
        }
      },

      validateContract() {
        this.$refs.contractForm.validateContact();
        this.$refs.responsiblePersonForm.validatePerson();
        this.$refs.responsiblePersonAddressForm.validateAddress();
        if (this.contract.secondSigningPerson) {
          this.$refs.responsiblePerson2Form.validatePerson();
          this.$refs.responsiblePerson2AddressForm.validateAddress();
        }
        if (this.$refs.bankAccountForm) {
          this.$refs.bankAccountForm.validateBankAccount();
        }

        if (this.$refs.contractForm.invalid) {
          jQuery('#contract-tabs-id').children('.md-tabs-navigation').first().children(':button.md-button').first().trigger('click');
          return;
        }

        if (this.$refs.responsiblePersonForm.invalid) {
          jQuery('#contract-tabs-id').children('.md-tabs-navigation').first().children(':button.md-button').first().next().trigger('click');
          return;
        }

        if (this.contract.secondSigningPerson && this.$refs.responsiblePerson2Form.invalid) {
          jQuery('#contract-tabs-id').children('.md-tabs-navigation').first().children(':button.md-button').first().next().next().trigger('click');
          return;
        }

        if (this.contract.paymentMethod === 'SEPA' && this.$refs.bankAccountForm.invalid) {
          if (!this.contract.secondSigningPerson) {
            jQuery('#contract-tabs-id').children('.md-tabs-navigation').first().children(':button.md-button').first().next().next().trigger('click');
          } else {
            jQuery('#contract-tabs-id').children('.md-tabs-navigation').first().children(':button.md-button').first().next().next().next().trigger('click');
          }
          return;
        }

        if (!this.contract.secondSigningPerson) {
          if (!(this.$refs.contractForm.invalid || this.$refs.responsiblePersonForm.invalid ||
              this.$refs.responsiblePersonAddressForm.invalid || (this.$refs.bankAccountForm && this.$refs.bankAccountForm.invalid))) {
            if (this.mode === 'update') {
              this.updateContract();
            }
            if (this.mode === 'add') {
              this.addContract();
            }
          } else {
            setTimeout(() => this.confirmMissingData(), 250);
          }
        } else {
          if (!(this.$refs.contractForm.invalid || this.$refs.responsiblePersonForm.invalid || this.$refs.responsiblePerson2Form.invalid ||
              this.$refs.responsiblePersonAddressForm.invalid || this.$refs.responsiblePerson2AddressForm.invalid ||
              (this.$refs.bankAccountForm && this.$refs.bankAccountForm.invalid))) {
            if (this.mode === 'update') {
              this.updateContract();
            }
            if (this.mode === 'add') {
              this.addContract();
            }
          } else {
            setTimeout(() => this.confirmMissingData(), 250);
          }
        }

      },

      closeDialog() {
        this.$emit("closeEditContractDialog");
      },

      onUpdateContract(child) {
        this.$emit("updateContractSuccess", child);
      }
    },

    computed: {

      isAuthenticated() {
        return this.$store.getters.authenticated
      },

      isAdmin() {
        return this.$store.getters.admin
      },

      isSage() {
        return this.$store.getters.sage
      },

      organization() {
        return this.$store.getters.organization
      },

      labels() {
        return this.$store.getters.labels
      },
    },
  };
</script>

<style scoped>
  h5 {
    font-weight: 400;
    color: lightseagreen;
  }
  .md-dialog /deep/.md-dialog-container {
    min-width: 85%;
  }
</style>

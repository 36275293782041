<template>
  <div>
    <md-dialog :md-click-outside-to-close="false" id="createNewChildDialog" v-if="child" :md-active.sync="showDialog"
               @md-closed="closeDialog">
      <md-dialog-title>
        <form novalidate @submit.prevent="validateChild">
          <div class="grid-x grid-padding-x">
            <div v-if="mode === 'update'" class="cell large-5 medium-5">
              <i class="fi-pencil"></i>&nbsp;&nbsp;&nbsp;Daten bearbeiten - Kind {{child.masterdata.fullName}}
            </div>
            <div v-else-if="mode === 'add'" class="cell large-5 medium-5">
              <img width="20px" src="../../assets/icons/child-torso.png"/>&nbsp;&nbsp;&nbsp;Neues Kind hinzufügen
            </div>
            <div class="cell large-2 medium-2 small-12" style="margin-top: -5px;">
              <SessionTime></SessionTime>
            </div>
            <div class="cell large-5 medium-5 text-center">
              <button v-if="mode === 'update'" class="button success" type="submit" :disabled="sending">
                <i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Änderungen speichern
              </button>
              <button v-else-if="mode === 'add'" class="button success" type="submit" :disabled="sending">
                <i class="fi-torso"></i>&nbsp;&nbsp;&nbsp;Kind hinzufügen
              </button>
              <button class="button alert" style="margin-left: 1rem;" @click="closeDialog" type="button" :disabled="sending">
                <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Abbrechen
              </button>
            </div>
          </div>
        </form>
        <md-progress-bar md-mode="indeterminate" v-if="sending"/>
      </md-dialog-title>

      <md-dialog-content id="dialogContentId">

        <div class="grid-x grid-padding-x">
          <div v-if="!isSage && availableFacilities" class="cell large-6 medium-6 small-12">
            <md-field :class="getValidationClass('facilityReference')">
              <label>Einrichtung</label>
              <md-select v-model="child.facilityReference" :disabled="sending || availableFacilities.length <= 1" @md-selected="onSelectFacility">
                <md-option v-for="facility of availableFacilities" :value="facility.referenceNumber"
                           v-bind:key="facility.name + facility.referenceNumber">
                  {{ facility.longName }}
                </md-option>
              </md-select>
              <span class="md-error" v-if="!$v.child.facilityReference.required">Einrichtung ist notwendig</span>
            </md-field>
          </div>
          <div class="cell large-6 medium-6 hide-for-small-only">
            <md-field :class="getValidationClass('groupReferences')">
              <label>Gruppen</label>
              <md-select v-model="child.groupReferences" :disabled="sending" multiple>
                <md-option v-for="group of availableGroups" :value="group.id"
                           v-bind:key="group.name + group.id">
                  {{group.name}}
                </md-option>
              </md-select>
            </md-field>
          </div>
        </div>

        <br>

        <ChildTabs ref="childTabsForm" @saveAndExitTo="onSaveAndExitTo"
                   :mdCardContentStyle="'height: 100%; overflow-x: auto; padding: 0; margin-top: 0;'"
                   :selectedChild="child" :editActive="editActive" :sending="sending" :mode="mode"></ChildTabs>

      </md-dialog-content>

      <button class="close-button" type="button" @click="closeDialog">
        <span aria-hidden="true">&times;</span>
      </button>
    </md-dialog>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="confirmMissingChildDataDialog">
      <div class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title><img width="25px" src="../../assets/icons/child-torso.png"/>&nbsp;&nbsp;&nbsp;Unvollständigen
          Datensatz für Kind bestätigen
        </md-dialog-title>
        <div class="cell text-center">
          <md-content>Soll der unvollständige Datensatz wirklich gespeichert werden?
          </md-content>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>
        <div class="cell text-center">
          <div @click="addChild" class="button success"><i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Ja, speichern</div>
          <button class="button alert" style="margin-left: 1rem;"
                  @click="closeConfirmationDialog"
                  type="button"
                  :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="closeConfirmationDialog">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="confirmCancelChildDataDialog">
      <div class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title><img width="25px" src="../../assets/icons/child-torso.png"/>&nbsp;&nbsp;&nbsp;Eingabe für Kind abbrechen
        </md-dialog-title>
        <div class="cell text-center">
          <md-content>Wollen Sie die Dateneingabe wirklich abbrechen und in den Bereich <b>Verwaltung / Anpassungen</b> wechseln?
            </md-content>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>
        <div class="cell text-center">
          <div @click="onConfirmCancellationAndExit" class="button alert"><i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Ja, Eingabe abbrechen</div>
          <button class="button success" style="margin-left: 1rem;"
                  @click="onAbortCancellation"
                  type="button"
                  :disabled="sending">
            <i class="fi-check"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="onAbortCancellation">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

  </div>

</template>

<script>

  import jQuery from 'jquery';
  import {validationMixin} from 'vuelidate';
  import SessionTime from '../animations/SessionTime';

  import {
    required,
  } from 'vuelidate/lib/validators';

  import ChildTabs from "../../components/tabs/ChildTabs";
  import FacilityService from '../../services/FacilityService';
  import HttpErrorHandler from '../../services/HttpErrorHandler';

  export default {
    name: 'EditChildDialog',
    mixins: [validationMixin],

    components: {
      ChildTabs,
      SessionTime,
    },

    props: {
      child: {
        required: true
      },
      availableFacilities: {
        required: true
      },
      availableGroups: {
        required: true
      },
      showDialog: {
        required: true
      },
      mode: {
        required: true
      }
    },

    validations: {
      child: {
        facilityReference: {
          required,
        },
      }
    },

    created() {
    },

    watch: {
      child() {
        this.updateFreeAttributes();
      },
    },

    data: () => ({
      success: true,
      sending: false,
      editActive: true,
      confirmMissingChildDataDialog: false,
      confirmCancelChildDataDialog: false,
      exitRoute: '',
    }),

    methods: {

      onSaveAndExitTo(route) {
        this.exitRoute = route;
        jQuery('#createNewChildDialog').attr("style", "display:none");
        this.confirmCancelChildDataDialog = true;
      },

      onConfirmCancellationAndExit() {
        this.confirmCancelChildDataDialog = false;
        jQuery('#createNewChildDialog').attr("style", "display:normal");
        this.$emit('saveAndExitTo', this.exitRoute);
      },

      onAbortCancellation() {
        this.confirmCancelChildDataDialog = false;
        jQuery('#createNewChildDialog').attr("style", "display:normal");
      },

      onSelectFacility() {
        this.updateFreeAttributes();
      },

      updateFreeAttributes() {
        this.availableFacilities.forEach(facility => {
          if (facility.referenceNumber === this.child.facilityReference) {
            this.child.freeAttributes = facility.facilitySettings.freeAttributes;
          }
        })
      },

      addChild() {
        this.closeConfirmationDialog();
        this.sending = true;
        this.success = false;
        FacilityService.addChildToFacility(this.child.facilityReference, this.child)
          .then(response => {
            this.child = JSON.parse(JSON.stringify(response.data));
            this.success = true;
            this.sending = false;
            this.$emit("closeEditChildDialog");
            this.$emit("updateChildSuccess", this.child);
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Hinzufügen des Kindes ' + this.child.masterdata.firstname + ' '
              + this.child.masterdata.lastname);
            this.sending = false;
          })
      },

      confirmMissingData() {
        jQuery('#createNewChildDialog').attr("style", "display:none");
        this.confirmMissingChildDataDialog = true;
      },

      closeConfirmationDialog() {
        this.confirmMissingChildDataDialog = false;
        jQuery('#createNewChildDialog').attr("style", "display:normal");
        this.$v.$reset();
      },

      getValidationClass(fieldName) {
        let field = this.$v.child[fieldName];

        if (field) {
          return {
            'md-invalid': field.$invalid && field.$dirty
          }
        }
      },

      validateChild() {
        if (this.child.facilityReference === '*') {
          this.child.facilityReference = null;
        }

        this.$v.$touch();
        this.$refs.childTabsForm.$refs.childPersonForm.validatePerson();
        this.$refs.childTabsForm.$refs.childForm.validateChild();

        if (this.child.facilityReference === null || this.child.facilityReference === '') {
          document.querySelector('#childTabsId .md-tabs-navigation button').click();
          jQuery('#dialogContentId').animate({scrollTop: 0}, 'slow');
          jQuery('.md-card-content').animate({scrollTop: 0}, 'slow');
          return;
        }

        if (this.$refs.childTabsForm.$refs.childPersonForm.invalid) {
          document.querySelector('#childTabsId .md-tabs-navigation button').click();
          jQuery('#dialogContentId').animate({scrollTop: 240}, 'slow');
          jQuery('.md-card-content').animate({scrollTop: 0}, 'slow');
          return;
        }

        if (this.$refs.childTabsForm.$refs.childForm.invalid) {
          document.querySelector('#childTabsId .md-tabs-navigation button').click();
          jQuery('#dialogContentId').animate({scrollTop: 1000}, 'slow');
          jQuery('.md-card-content').animate({scrollTop: 0}, 'slow');
          return;
        }

        this.$refs.childTabsForm.validateChild();

        if (!this.$refs.childTabsForm.invalid) {
          if (this.mode === 'update') {
            this.updateChild();
          }
          if (this.mode === 'add') {
            this.addChild();
          }
        } else {
          setTimeout(() => this.confirmMissingData(), 250);
        }
      },

      closeDialog() {
        this.$emit("closeEditChildDialog");
        this.$v.$reset();
      }
    },

    computed: {

      isAuthenticated() {
        return this.$store.getters.authenticated
      },

      isAdmin() {
        return this.$store.getters.admin
      },

      isSage() {
        return this.$store.getters.sage
      },

      organization() {
        return this.$store.getters.organization;
      },
    },
  };
</script>

<style scoped>
  .md-dialog /deep/.md-dialog-container {
    //min-width: 95%;
    //min-height: 95%;
  }
</style>
<template>
  <div id="children-overview-id" v-if="isAuthenticated" :class="fullViewClass">
    <div class="callout success">
      <div class="grid-x">
        <div class="cell small-8">
          <h3 v-if="!archiveActive"><img width="35px" src="../assets/icons/children-playing.png"/>&nbsp;&nbsp;&nbsp;Kinder-Übersicht
          </h3>
          <h3 v-else class="flash-orange-red"><img width="35px" src="../assets/icons/children-playing.png"/>&nbsp;&nbsp;&nbsp;Kinder-Archiv
          </h3>
        </div>
        <div class="cell small-4 text-right">
          <md-switch id="archiveSwitchId" v-model="archiveActiveToggle" @change="onToggleArchive" :disabled="editActive"
                     style="margin: 11px 11px 11px 0;">
            <span style="font-weight: lighter; font-size: large;">Archiv</span>
          </md-switch>
        </div>
      </div>
    </div>

    <div id="overlayLeft" class="hide-for-small-only hide-for-medium-only" style="width: 50%; top: 17%; height: 77%;"
         @click="onTouchOverlay"></div>

    <div v-if="organization && organization.facilities && organization.facilities .length <= 0"
         class="grid-x grid-padding-x grid-padding-y">
      <NoFacilityCard functionTitle="Kinder"></NoFacilityCard>
    </div>

    <div v-else class="grid-x grid-padding-x grid-padding-y">
      <div class="cell large-6">
        <md-table id="children-table-id" ref="childrenTable" v-model="searched" md-sort="urgency" md-sort-order="desc"
                  md-card md-fixed-header :class="childrenTableClass"
                  @md-selected="onSelect" :md-selected-value.sync="selectedRows">
          <md-table-toolbar>

            <div class="page-container md-layout-column">
              <md-drawer class="md-right" :md-active.sync="moreFilterVisible" style="width: 80%" md-swipeable>
                <div class="md-layout">
                  <div class="md-layout-item md-size-10 text-center" style="padding: 7px;">
                    <md-button class="md-icon-button md-raised md-primary" @click="resetChildFilter">
                      <md-icon>undo</md-icon>
                      <md-tooltip md-direction="bottom">Alle Filtereinstellungen zurücksetzen
                      </md-tooltip>
                    </md-button>
                  </div>
                  <div class="md-layout-item md-size-90">
                    <md-tabs v-if="childFilter" md-elevation="1" style="height: 100%;" @md-changed="closeChildFilter">
                      <md-tab id="Allgemein" md-label="Allgemein">
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary"
                                     v-model="childFilter.handicapped">Behinderung
                        </md-checkbox>
                        <br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary"
                                     v-model="childFilter.migrantBackground">Migrationshintergrund
                        </md-checkbox>
                        <br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary"
                                     v-model="childFilter.nutritionInfo.porkMeatAllowed">Darf Schweinefleisch essen
                        </md-checkbox>
                        <br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary"
                                     v-model="childFilter.possiblePreschooler">Mögliches Vorschulkind
                        </md-checkbox>
                        <br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary"
                                     v-model="childFilter.preschooler">Vorschulkind
                        </md-checkbox>
                        <br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary"
                                     v-model="childFilter.shareInformationAllowed">Datenweitergabe-Erlaubnis
                        </md-checkbox>
                        <br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary"
                                     v-model="childFilter.sibling">Geschwister
                        </md-checkbox>
                        <br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary"
                                     v-model="childFilter.gettingWirtschaftlicheJugendhilfe">Wirtschaftliche
                          Jugendhilfe
                        </md-checkbox>
                        <br>
                      </md-tab>
                      <md-tab id="Gesundheit" md-label="Gesundheit">
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary"
                                     v-model="childFilter.nutritionInfo.allergic">Allergien
                        </md-checkbox>
                        <br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary"
                                     v-model="childFilter.firstMeaslesVaccinationDate">Keine erste Masernimpfung
                        </md-checkbox>
                        <br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary"
                                     v-model="childFilter.secondMeaslesVaccinationDate">Keine zeite Masernimpfung
                        </md-checkbox>
                        <br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary"
                                     v-model="childFilter.exemptionMeaslesVaccination">Freistellung Masernimpfung
                        </md-checkbox>
                        <br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary"
                                     v-model="childFilter.exemptionSecondMeaslesVaccination">Freistellung 2.
                          Masernimpfung
                        </md-checkbox>
                        <br>
                      </md-tab>
                      <md-tab id="Zustimmungen" md-label="Zustimmungen">
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-accent"
                                     v-model="childFilter.parentalConsents.dsgvoConfirmationObtained">Einwilligung(en)
                          Datenverarbeitung <b style="color: #ff5252;">unvollständig</b>
                        </md-checkbox>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary"
                                     v-model="childFilter.parentalConsents.applyPlasterSalveAllowed">Pflaster
                          applizieren / Salbengabe
                        </md-checkbox>
                        <br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary"
                                     v-model="childFilter.parentalConsents.beingPhotographedPrivatelyAllowed">
                          Fotonutzung für interne Zwecke erlaubt
                        </md-checkbox>
                        <br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary"
                                     v-model="childFilter.parentalConsents.beingPhotographedPubliclyAllowed">
                          Fotonutzung für öffentliche Zwecke erlaubt
                        </md-checkbox>
                        <br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary"
                                     v-model="childFilter.parentalConsents.contactToAnimalsAllowed">Kontakt mit Tieren
                          erlaubt
                        </md-checkbox>
                        <br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary"
                                     v-model="childFilter.parentalConsents.goSwimmingAllowed">Schwimmbadbesuch inkl.
                          Schwimmen erlaubt
                        </md-checkbox>
                        <br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary"
                                     v-model="childFilter.parentalConsents.removeTickAllowed">Zeckenentfernung erlaubt
                        </md-checkbox>
                        <br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary"
                                     v-model="childFilter.parentalConsents.ridingCarAllowed">Beförderung in
                          Kraftfahrzeugen erlaubt
                        </md-checkbox>
                        <br>
                        <md-checkbox @change="maintainChildFilterActivityState" class="md-primary"
                                     v-model="childFilter.parentalConsents.applySuncreamAllowed">Sonnencreme anwenden
                          erlaubt
                        </md-checkbox>
                        <br>
                      </md-tab>
                      <md-tab id="Individuell" md-label="Individuell">
                        <div
                            v-if="selectedFacility && selectedFacility.facilitySettings && selectedFacility.facilitySettings.freeAttributes">
                          <div v-for="attribute of selectedFacility.facilitySettings.freeAttributes"
                               :key="attribute.refNr + attribute.name + attribute.stringValue">
                            <div v-if="attribute.type === 'BOOLEAN'">
                              <md-checkbox @change="maintainChildFilterActivityState" class="md-primary"
                                           v-model="attribute.booleanValue">{{ attribute.name }}
                              </md-checkbox>
                              <br>
                            </div>
                          </div>
                          <div v-if="facilityHasNoFreeAttributesCheckboxes(selectedFacility)">
                            <h6>
                              Für die Kinder der Einrichtung <b style="color: coral">{{ selectedFacility.name }}</b>
                              sind keine individuellen Merkmale vom Typ <i>Checkbox</i> angelegt.<br><br>
                              Im Bereich <a @click="onSaveAndExitTo('/Verwaltung/Anpassungen')">Verwaltung /
                              Anpassungen</a>
                              können individuelle Merkmale festgelegt werden.
                            </h6>
                          </div>
                        </div>
                        <div v-else>
                          <h5><i>Bitte wählen Sie eine Einrichtung aus, um die individuellen Merkmale nutzen zu
                            können.</i></h5>
                        </div>
                      </md-tab>
                      <md-tab id="close" md-label="" md-icon="cancel">
                      </md-tab>
                    </md-tabs>
                  </div>
                </div>
              </md-drawer>

              <md-content>
                <div class="md-layout md-gutter">
                  <div class="md-layout-item md-size-5 filter-menu" @click="moreFilterVisible = true">
                    <div class="filter-label">mehr FILTER <span v-if="childFilterActiveCounter > 0"
                                                                class="flash-orange-red"><b><i>aktiv</i></b></span>
                    </div>
                    <md-tooltip md-direction="right">
                      Klicken für weitere Filter
                    </md-tooltip>
                  </div>
                  <div class="md-layout-item">
                    <div class="md-toolbar-section-start grid-x grid-padding-x" style="padding-top: 0.5rem;">
                      <div class="cell large-3 medium-3 small-6">
                        <p v-if="!archiveActive" class="md-title ai-number">{{ searched.length }} Kind<span
                            v-if="searched.length !== 1">er</span></p>
                        <p v-else class="md-title ai-number" style="color: orangered">{{ searched.length }} Kind<span
                            v-if="searched.length !== 1">er</span></p>
                        <p v-if="(!archiveActive && children.length - searched.length > 0) || (archiveActive && archivedChildren.length - searched.length > 0)"
                           style="color: crimson; margin: 0;">
                          <span v-if="!archiveActive">{{ children.length - searched.length }}</span>
                          <span v-else>{{ archivedChildren.length - searched.length }}</span> nicht sichtbar
                          <md-tooltip md-direction="bottom" md-delay="500">
                            <b>Warum nicht sichtbar?</b><br><br>
                            <span>Durch setzen von Filtern wird die Liste eingeschränkt.</span><br><br>
                            <span>Klicken Sie auf <md-button class="md-icon-button md-raised md-primary"
                                                             style="background: cornflowerblue; margin-bottom: 10px">
                                <md-icon style="color: white">select_all</md-icon>
                              </md-button> um alle Einträge zu sehen.</span>
                          </md-tooltip>
                        </p>
                        <p v-else style="color: seagreen; margin: 0;">
                          Alle sichtbar
                        </p>
                      </div>
                      <div class="cell large-3 medium-3 small-6">
                        <div v-if="sending">
                          <vue-simple-spinner></vue-simple-spinner>
                        </div>
                        <div v-else>
                          <div class="md-layout">
                            <div class="md-layout-item">
                              <md-button class="md-icon-button md-raised md-primary"
                                         style="background: cornflowerblue" @click="onShowAllChildren">
                                <md-icon>select_all</md-icon>
                                <md-tooltip md-direction="bottom" style="max-width: 500px">
                                  <span v-if="!archiveActive">Alle <b>{{
                                      children.length
                                    }}</b> Kinder der Einrichtung <b>{{ selectedFacilityName }}</b> anzeigen</span>
                                  <span v-else>Alle <b>{{ archivedChildren.length }}</b> archivierten Kinder der Einrichtung <b>{{
                                      selectedFacilityName
                                    }}</b> anzeigen</span>
                                </md-tooltip>
                              </md-button>
                            </div>
                            <div class="md-layout-item">
                              <md-button class="md-icon-button md-raised md-primary"
                                         @click="$router.push('/Drucken/Listen')">
                                <md-icon>print</md-icon>
                                <md-tooltip md-direction="bottom">
                                  Aktuelle Liste drucken
                                </md-tooltip>
                              </md-button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="!isSage && availableFacilities && availableFacilities.length > 0"
                           class="cell large-6 medium-6 small-12">
                        <md-field>
                          <label>Einrichtung</label>
                          <md-select v-model="selectedFacilityReferenceNumber"
                                     :disabled="sending || availableFacilities.length <= 1"
                                     @md-selected="onSelectFacility">
                            <md-option v-for="facility of availableFacilities" :value="facility.referenceNumber"
                                       :key="facility.id">
                              {{ facility.longName }}
                            </md-option>
                          </md-select>
                        </md-field>
                      </div>
                      <div v-else class="cell large-6 medium-6 small-12">&nbsp;<br><br></div>
                      <div class="cell large-6 medium-6 small-12">
                        <md-field :style="displayElement(!yearFilterEnabled)">
                          <label>Bereiche</label>
                          <md-select v-model="selectedDivisions" :disabled="sending" @md-selected="onSelectDivision"
                                     multiple>
                            <md-option v-for="division of labels.divisionTypes" :value="division" :key="division">
                              {{ labels.divisiontype[division] }}
                            </md-option>
                          </md-select>
                        </md-field>
                        <md-field :style="displayElement(yearFilterEnabled)">
                          <label>Bereich</label>
                          <md-select v-model="filterSettings.divisionType" :disabled="sending"
                                     @md-selected="getFilteredChildren">
                            <md-option v-for="division of availableTargetFacilityTypes" :value="division"
                                       :key="division">
                              {{ labels.divisiontype[division] }}
                            </md-option>
                          </md-select>
                        </md-field>
                      </div>
                      <div v-if="!archiveActive" class="cell large-6 medium-6 small-12">
                        <md-field>
                          <label>Gruppen</label>
                          <md-select v-model="selectedGroups" :disabled="sending" @md-selected="onSelectGroup" multiple>
                            <md-option v-for="group of groups" :value="group.key" :key="group.id">
                              {{ group.name }}
                            </md-option>
                          </md-select>
                        </md-field>
                      </div>
                      <div v-if="!archiveActive" class="cell large-6 medium-6 small-12">
                        <md-field :style="displayElement(!yearFilterEnabled)">
                          <label>Filter</label>
                          <md-select v-model="selectedFilter" :disabled="sending" @md-selected="onSelectFilter"
                                     multiple>
                            <md-option v-for="filter of filters" :value="filter.id" :key="filter.id">
                              {{ filter.name }}
                            </md-option>
                          </md-select>
                        </md-field>
                        <md-field :style="displayElement(yearFilterEnabled)">
                          <label>Jahr</label>
                          <md-select v-if="filterSettings.kgj" v-model="filterSettings.year" :disabled="sending"
                                     @md-selected="getFilteredChildren">
                            <md-option v-for="year of filterYears" :value="year" :key="year">
                              {{ filterKigaYears[year] }}
                            </md-option>
                          </md-select>
                          <md-select v-else v-model="filterSettings.year" :disabled="sending"
                                     @md-selected="getFilteredChildren">
                            <md-option v-for="year of filterYears" :value="year" :key="year">
                              {{ year }}
                            </md-option>
                          </md-select>
                        </md-field>
                      </div>
                      <div class="cell large-6 medium-6 small-12">
                        <md-field :style="displayElement(!yearFilterEnabled)" md-clearable
                                  class="md-toolbar-section-end">
                          <md-input placeholder="Suche Nachname des Kindes ..." v-model="search"
                                    @input="searchOnTable"/>
                        </md-field>
                        <md-switch :style="displayElement(yearFilterEnabled)" class="md-primary"
                                   v-model="filterSettings.kgj" @change.prevent="getFilteredChildren"
                                   :disabled="sending">
                          <p>Kindergarten-Jahr</p>
                          <md-tooltip>Jahreszeitraum von September bis August</md-tooltip>
                        </md-switch>
                      </div>
                      <div v-if="archiveActive" class="cell large-6 medium-6 small-6">
                        <md-field>
                          <label>Jahr Austritt</label>
                          <md-select v-model="selectedYears" :disabled="sending" @md-selected="onSelectArchiveYear"
                                     multiple>
                            <md-option v-for="year of archiveYears" :value="year" :key="year">
                              {{ year }}
                            </md-option>
                          </md-select>
                        </md-field>
                      </div>
                      <div v-if="archiveActive" class="cell large-6 medium-6 small-6">
                        <md-field>
                          <label>Monat Austritt</label>
                          <md-select v-model="selectedMonths" :disabled="sending" @md-selected="onSelectArchiveYear"
                                     multiple>
                            <md-option v-for="(month, index) of $material.locale.months" :value="month" :key="index">
                              {{ month }}
                            </md-option>
                          </md-select>
                        </md-field>
                      </div>
                      <div class="cell" style="padding-bottom: 15px;">
                        <div class="grid-x grid-padding-x">
                          <div class="cell medium-6 large-6 hide-for-small-only">
                            <div v-if="togglingAddMode" class="grid-x grid-padding-x">
                              <div class="cell small-8">
                                <h3 style="font-size: x-large"><p style="text-align:center; margin: 0;">Moment bitte
                                  ... </p></h3>
                              </div>
                              <div class="cell small-4 text-left">
                                <vue-simple-spinner></vue-simple-spinner>
                              </div>
                            </div>
                            <div v-else class="grid-x">
                              <div class="cell small-4 text-left">
                                <div
                                    v-if="!archiveActive && !yearFilterEnabled && !presenceViewEnabled && !isDocumentation && canWrite">
                                  <md-switch id="multi-child-selection-switch-id" v-model="multiChildSelection"
                                             @change="toggleMultiChildSelection" class="md-primary">
                                    <md-icon>list</md-icon>
                                  </md-switch>
                                  <md-tooltip>
                                    <span v-if="multiChildSelection && selectedRows && selectedRows.length === 0">Bitte zuerst <b>Kinder auswählen</b> und dann die gewünschte <b>Funktion ausführen</b>.</span>
                                    <span v-else>Mehrfach-Auswahl aktivieren</span>
                                  </md-tooltip>
                                  <md-tooltip>
                                    <span
                                        v-if="selectedFacilityReferenceNumber && selectedFacilityReferenceNumber === '*'">Bitte zuerst eine Einrichtung auswählen</span>
                                  </md-tooltip>
                                </div>
                              </div>
                              <div class="cell small-4 text-center">
                                <div v-if="!archiveActive && !multiChildSelection && !isDocumentation">
                                  <md-switch id="year-filter-switch-id" v-model="yearFilterEnabled"
                                             @change="toggleYearFilter" class="md-primary" :disabled="sending">
                                    <md-icon>calendar_today</md-icon>
                                  </md-switch>
                                  <md-tooltip><span v-if="yearFilterEnabled">Bitte <b>Einrichtung</b>, <b>Bereich</b> und gewünschtes <b>Jahr</b> wählen</span>
                                    <span v-else>Jahresfilter aktivieren</span>
                                  </md-tooltip>
                                </div>
                              </div>
                              <div class="cell small-4 text-right">
                                <div v-if="!archiveActive && !multiChildSelection && !isDocumentation">
                                  <md-switch id="presence-view-switch-id" v-model="presenceViewEnabled"
                                             @change="togglePresenceView" class="md-primary" :disabled="sending">
                                    <md-icon>check</md-icon>
                                  </md-switch>
                                  <md-tooltip><span v-if="presenceViewEnabled">Bitte für jedes <b>Kind</b> die <b>Anwesenheit</b> überprüfen.</span>
                                    <span v-else>Anwesenheitsübersicht aktivieren</span>
                                  </md-tooltip>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-if="!yearFilterEnabled && !presenceViewEnabled" class="cell medium-6 large-6">
                            <div v-if="multiChildSelection">
                              <div
                                  v-if="!archiveActive && canWrite && (isFacility || isTreasurer || isAdmin || isFacilityAdmin || isManagement)">
                                <md-button @click="onAddFeeMultiChildSelectionDialog"
                                           :disabled="!selectedRows || (selectedRows && selectedRows.length <= 0)"
                                           class="md-icon-button md-raised md-primary">
                                  <md-icon>euro_symbol</md-icon>
                                  <md-tooltip v-if="selectedRows">Für <b>{{ selectedRows.length }}</b> ausgewählte<span
                                      v-if="selectedRows.length === 1">s</span> Kind<span
                                      v-if="selectedRows.length !== 1">er</span> <b>Gebühr zuweisen</b></md-tooltip>
                                </md-button>
                                <md-button @click="onDeclareSiblingsMultiChildSelectionDialog"
                                           :disabled="!selectedRows || (selectedRows && selectedRows.length <= 1)"
                                           class="md-icon-button md-raised md-third">
                                  <md-icon>group</md-icon>
                                  <md-tooltip v-if="selectedRows"><b>{{ selectedRows.length }}</b> ausgewählte<span
                                      v-if="selectedRows.length === 1">s</span> Kind<span
                                      v-if="selectedRows.length !== 1">er</span> als <b>Geschwister</b> festlegen /
                                    auflösen
                                  </md-tooltip>
                                </md-button>
                                <md-button v-if="false"
                                           @click="onDeclareCoronaCompensationMultiChildSelectionDialog"
                                           :disabled="!selectedRows || (selectedRows && selectedRows.length <= 1)"
                                           class="md-icon-button md-raised md-forth">
                                  <md-icon>local_hospital</md-icon>
                                  <md-tooltip v-if="selectedRows">Für <b>{{ selectedRows.length }}</b> ausgewählte<span
                                      v-if="selectedRows.length === 1">s</span> Kind<span
                                      v-if="selectedRows.length !== 1">er</span> den <b>Corona Beitragsersatz</b>
                                    festlegen
                                  </md-tooltip>
                                </md-button>
                              </div>
                            </div>
                            <div v-else>
                              <div
                                  v-if="!archiveActive && canWrite && (isFacility || isAdmin || isFacilityAdmin || isManagement)">
                                <md-button @click="onAddChildDialog"
                                           :disabled="togglingAddMode || (editActive && selected !== null)"
                                           class="md-icon-button md-raised md-primary">
                                  <md-icon>person_add</md-icon>
                                  <md-tooltip>Neues Kind hinzufügen</md-tooltip>
                                </md-button>
                                <md-button @click="onAddSiblingDialog"
                                           :disabled="!selected || editActive"
                                           class="md-icon-button md-raised md-third">
                                  <md-icon>group_add</md-icon>
                                  <md-tooltip>Geschwister anlegen</md-tooltip>
                                </md-button>
                                <md-button @click="onDeleteChildDialog"
                                           :disabled="!selected || editActive || !(isAdmin || isFacilityAdmin || isManagement)"
                                           class="md-icon-button md-raised md-accent">
                                  <md-icon>delete</md-icon>
                                  <md-tooltip>Datensatz Kind löschen</md-tooltip>
                                </md-button>
                                <md-button @click="onChangeExitDateDialog"
                                           :disabled="!selected || editActive"
                                           class="md-icon-button md-raised md-forth">
                                  <md-icon>exit_to_app</md-icon>
                                  <md-tooltip>Austrittsdatum für Kind ändern</md-tooltip>
                                </md-button>
                                <md-button @click="onTransferToFacilityDialog"
                                           :disabled="!selected || editActive"
                                           class="md-icon-button md-raised md-sixth">
                                  <md-icon>arrow_forward</md-icon>
                                  <md-tooltip>Übertritt zum ausgewählten Datum für Kind durchführen</md-tooltip>
                                </md-button>
                                <md-button @click="onMoveToFacilityDialog"
                                           :disabled="!selected || editActive"
                                           class="md-icon-button md-raised md-seventh">
                                  <md-icon>autorenew</md-icon>
                                  <md-tooltip>Sofortiger Wechsel der Einrichtung für Kind durchführen</md-tooltip>
                                </md-button>
                                <md-button @click="onArchiveChildDialog"
                                           :disabled="!selected || editActive || !(isAdmin || isFacilityAdmin || isManagement)"
                                           class="md-icon-button md-raised md-fifth">
                                  <md-icon>archive</md-icon>
                                  <md-tooltip>Kind archivieren</md-tooltip>
                                </md-button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </md-content>
            </div>

          </md-table-toolbar>

          <md-table-empty-state v-if="search"
                                md-label="Kein Kind gefunden"
                                :md-description="`Kein Kind mit dem Suchbegriff '${search}' gefunden. Versuchen Sie es mit einem anderen Begriff.`">
          </md-table-empty-state>

          <md-table-row :id="item.id" slot="md-table-row" slot-scope="{ item }" :class="getClass(item.id)"
                        :md-selectable="childSelectionMode" :style="absenceStyle(!item.hereToday)">
            <md-table-cell style="padding-left: 0; padding-right: 0" md-label="Gen" md-sort-by="masterdata.sex">
              <span v-if="item.masterdata.sex === 'MALE'" :class="item.masterdata.sex">&#9794;</span>
              <span v-else-if="item.masterdata.sex === 'FEMALE'" :class="item.masterdata.sex">&#9792;</span>
              <span v-else-if="item.masterdata.sex === 'DIVERSE'" :class="item.masterdata.sex">&#9954;</span>
            </md-table-cell>
            <md-table-cell style="padding-left: 0; padding-right: 0" md-label="Vorname"
                           md-sort-by="masterdata.firstname">
              <ImageView :imageId="item.imageId"
                         size="micro"></ImageView>&nbsp;&nbsp;&nbsp;&nbsp;{{ item.masterdata.firstname }}
            </md-table-cell>
            <md-table-cell style="padding-left: 0; padding-right: 0" md-label="Nachname"
                           md-sort-by="masterdata.lastname">
              {{ item.masterdata.lastname }}
            </md-table-cell>
            <md-table-cell style="padding-left: 0; padding-right: 0" md-label="Alter" md-sort-by="masterdata.birthdate">
              {{ item.ageTextual }}
            </md-table-cell>
            <md-table-cell style="padding-left: 0; padding-right: 0" md-label="Bereich" md-sort-by="divisiontype">
              {{ labels.divisiontype[item.divisiontype] }}
            </md-table-cell>
            <md-table-cell v-if="!presenceViewEnabled" md-label="KiBiG" md-sort-by="kibigName" style="padding: 0;">
              {{ item.kibigName }}<br>
              <span style="font-size: smaller">{{ item.kibigId }}</span>
            </md-table-cell>
            <md-table-cell v-if="!archiveActive && !presenceViewEnabled" style="padding-left: 0; padding-right: 0"
                           md-label="Alarme" md-sort-by="urgency">
              <AlertIcons :alerts="item.alerts" index="-1"
                          @updateAlertUrgency="item.urgency = arguments[0]"></AlertIcons>
            </md-table-cell>
            <md-table-cell v-if="archiveActive && !presenceViewEnabled" style="padding-left: 0; padding-right: 0"
                           md-label="Austritt" md-sort-by="exitDate">
              {{ item.exitDate | moment("DD.MM.YYYY") }}
            </md-table-cell>
            <md-table-cell v-if="presenceViewEnabled && !archiveActive" style="padding-left: 0; padding-right: 0"
                           md-label="anwesend">
              <md-checkbox v-model="item.hereToday" class="md-primary"
                           :disabled="sending || !(isFacility || isAdmin || isFacilityAdmin || isManagement) || !canWrite"
                           @change="onToggleChildAbsence(item, false)"></md-checkbox>
              <md-tooltip>Anwesenheit für <b>{{ today | moment("DD.MM.YYYY") }}</b> ändern</md-tooltip>
            </md-table-cell>
            <md-tooltip v-if="!archiveActive" md-delay="1000" style="max-width: 500px"><span v-if="!item.hereToday"
                                                                                             class="ai-warn-text">{{
                labels.absenceStatus[item.absenceStatusToday]
              }}</span>
            </md-tooltip>
          </md-table-row>
        </md-table>
      </div>
      <div class="cell large-6">
        <form novalidate @submit.prevent="validateChild">
          <md-card>
            <md-card-header style="height: 190px;">
              <div class="grid-x grid-padding-x">
                <div class="cell large-3 medium-5 small-5">
                  <div v-if="selectedChild && !isSage" style="height: 145px;">
                    <div v-if="selectedChild.parentalConsents.beingPhotographedPrivatelyAllowed">
                      <ImageView v-if="selectedChild.imageId" :imageId="selectedChild.imageId" size="small"></ImageView>
                      <div v-else-if="options.url" @click="onVdropzoneClick">
                        <div v-if="sending" style="margin-top: 4rem;margin-right: 1rem;">
                          <vue-simple-spinner></vue-simple-spinner>
                        </div>
                        <div v-else>
                          <vue-dropzone
                              v-if="!selectedChild.archived && (isFacility || isAdmin || isFacilityAdmin || isManagement) && canWrite"
                              ref="childImageDropzone" id="dropzone"
                              :options="options" style="font-size: small;"
                              @vdropzone-file-added="onVdropzoneFileAdded"
                              @vdropzone-error="onVdropzoneError"
                              @vdropzone-processing="onVdropzoneProcessing"
                              @vdropzone-success="onVdropzoneSuccess">
                          </vue-dropzone>
                          <img v-else src="../assets/icons/child-torso.png" class="default-image">
                        </div>
                      </div>
                      <br>
                    </div>
                    <div v-else>
                      <img src="../assets/icons/child-torso.png" class="default-image" alt="child-default">
                      <div style="margin-top: -120px; margin-left: -10px; text-align: center">
                        <p>Zustimmung<br>"Fotonutzung für interne Zwecke erlaubt"<br> nicht gegeben.</p>
                      </div>
                    </div>
                  </div>
                  <img v-else src="../assets/icons/child-torso.png" class="default-image" alt="child">
                </div>
                <div v-if="selectedChild" class="cell large-9 medium-7 small-7">
                  <div class="md-title">
                    <span v-if="selectedChild.masterdata.sex === 'MALE'"
                          :class="selectedChild.masterdata.sex">&#9794;</span>
                    <span v-else-if="selectedChild.masterdata.sex === 'FEMALE'" :class="selectedChild.masterdata.sex">&#9792;</span>
                    <span v-else-if="selectedChild.masterdata.sex === 'DIVERSE'" :class="selectedChild.masterdata.sex">&#9954;</span>
                    <span class="md-title">
                      {{ selectedChild.masterdata.fullName }}&nbsp;&nbsp;&nbsp;
                      <md-button v-if="!archiveActive" class="md-icon-button" style="margin-top: -5px;"
                                 :disabled="sending || !(isFacility || isAdmin || isFacilityAdmin || isManagement) || !canWrite"
                                 @click="onToggleChildAbsence(selectedChild, true)">
                        <md-icon v-if="isHereToday(selectedChild)" style="color: green;">check</md-icon>
                        <md-icon v-else style="color: red;">close</md-icon>
                        <md-tooltip>Anwesenheit für <b>{{ today | moment("DD.MM.YYYY") }}</b> ändern</md-tooltip>
                      </md-button>
                      <md-tooltip v-if="!archiveActive" md-direction="top" style="max-width: 500px">
                        <span v-if="isHereToday(selectedChild)" class="ai-success-text">{{
                            labels.absenceStatus[selectedChild.absenceStatusToday]
                          }}</span>
                        <span v-else class="ai-alert-text">{{
                            labels.absenceStatus[selectedChild.absenceStatusToday]
                          }}</span>
                      </md-tooltip>
                    </span>
                  </div>
                  <div class="md-subhead" style="opacity: 1; padding-top: 4px;">
                    <span v-if="selectedChild.ageTextual"
                          style="color: lightseagreen; font-size: 1.2rem; font-weight: 500;">{{
                        selectedChild.ageTextual
                      }}&nbsp;</span>
                    <b style="font-size: 1.5rem"
                       :class="selectedChild.divisiontype">{{ labels.divisiontype[selectedChild.divisiontype] }}</b>
                  </div>
                  <div style="margin-top: 10px;">
                    <span v-if="selectedChildAggregatedFees && organization.financeModuleActive"
                          style="color: cornflowerblue; font-size: 1.0rem; font-weight: 300;">
                      Gesamtgebühren (monatlich &#8709;): <b
                        style="font-size: 1.2rem">{{ selectedChildAggregatedFees.aggregatedAmount | currency }}</b>
                      <md-tooltip v-if="selectedChildAggregatedFees.details" md-direction="top"
                                  style="max-width: 800px">
                        <div><b>{{ selectedChildAggregatedFees.details.length }}</b> Einzelgebühr(en)</div>
                        <div v-for="fee of selectedChildAggregatedFees.fees" v-bind:key="fee.feeType">
                          {{
                            labels.feeType[fee.feeType]
                          }}&nbsp;<b>{{ parseFloat(fee.amount).toFixed(2).replace('.', ',') + ' €' }}</b>,
                          &nbsp;<b>{{ labels.paymentInterval[fee.interval] }}</b>,
                          &nbsp;von: <b>{{
                            fee.validFrom | moment("DD.MM.YYYY")
                          }}</b> bis <b>{{ fee.validUntil | moment("DD.MM.YYYY") }}</b>
                        </div>
                      </md-tooltip>
                    </span>
                    <span v-else style="font-size: 1.5rem;">&nbsp;</span>
                  </div>

                  <div class="grid-x" style="padding-top: 16px;">
                    <div v-if="selectedChild.groupReferences && !selectedChild.archived" class="cell small-10">
                      <div v-if="editGroups" class="md-layout">
                        <div class="md-layout-item md-size-70">
                          <md-field style="top: -20px;">
                            <label>Gruppen</label>
                            <md-select v-model="selectedChild.groupReferences" :disabled="sending"
                                       @md-selected="onUpdateChildGroups" multiple>
                              <md-option v-for="group of facilityGroups" :value="group.id" :key="group.id">
                                {{ group.name }}
                              </md-option>
                            </md-select>
                          </md-field>
                        </div>
                        <div class="md-layout-item md-size-30">
                          <md-button class="md-icon-button md-primary" @click="editGroups = false">
                            <md-icon>check</md-icon>
                            <md-tooltip>Bearbeitung beenden</md-tooltip>
                          </md-button>
                        </div>
                      </div>
                      <md-button v-else style="text-transform: none; height: 3rem;" @click="onEditChildGroups"
                                 :disabled="sending || !(isFacility || isAdmin || isFacilityAdmin || isManagement) || !canWrite">
                        Gruppe<span v-if="selectedChild.groupReferences.length > 1">n</span>
                        <br>
                        <b v-if="selectedChild.groupReferences.length === 0"
                           style="color: crimson; font-size: 1.1rem; font-weight: 400;">Nicht zugeordnet</b>
                        <span v-for="(groupReference, index) of selectedChild.groupReferences" :key="groupReference">
                          <span v-for="group of groups" :key="group.id">
                            <b v-if="group.id.includes(groupReference)"
                               style="color: chocolate; font-size: 1.1rem; font-weight: 400;">{{ group.name }}<span
                                v-if="index < selectedChild.groupReferences.length - 1">,&nbsp;&nbsp;</span></b>
                          </span>
                        </span>
                        <md-tooltip>Gruppen bearbeiten</md-tooltip>
                      </md-button>
                    </div>
                    <div v-if="!selectedChild.archived" class="cell small-2">
                      <div class="cell text-left">
                        <md-button class="md-icon-button md-raised md-accent" @click="deleteImageDialog"
                                   :disabled="!selectedChild.imageId || sending || !(isFacility || isAdmin || isFacilityAdmin || isManagement) || !canWrite">
                          <md-icon>delete</md-icon>
                          <md-tooltip md-direction="left">Bild von <b>{{ selectedChild.masterdata.firstname }}
                            {{ selectedChild.masterdata.lastname }}</b> löschen
                          </md-tooltip>
                        </md-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </md-card-header>

            <md-card-content v-if="selectedChild" style="height: 860px;">
              <div :style="displayElement(!presenceViewEnabled)">
                <md-tabs id="childManagementTabsId" md-elevation="1" md-dynamic-height @md-changed="onTabsChanged"
                         style="height: 860px;">
                  <template slot="md-tab" slot-scope="{ tab }">
                    <AlertIcons v-if="tab.data.index < 5" :alerts="selectedChild.alerts"
                                :index="tab.data.index"></AlertIcons>
                    <span class="md-tab-label" style="margin-top: 10px;">{{ tab.label }}</span>
                  </template>

                  <md-tab id="CHILD_DATA" md-label="Stamm-Daten" md-icon="list" :md-template-data="{index: 0}">
                    <div v-if="!isSage" class="grid-x grid-padding-x grid-padding-y" style="margin-bottom: 0;">
                      <div class="cell small-6">
                        <md-chip
                            v-if="!archiveActive && canWrite && (isFacility || isAdmin || isFacilityAdmin || isManagement)"
                            style="height: 50px">
                          <md-switch id="editActiveSwitchId" v-model="editActiveSwitch" value="true"
                                     @change="onToggleEditData(!editActive)" :disabled="togglingEditMode">
                            Daten bearbeiten
                          </md-switch>
                        </md-chip>
                        <div v-if="selectedChild.archived">
                          <h5 style="color: coral; text-transform: none; margin-bottom: 4px;"><i><b>archiviert</b>&nbsp; <span
                              style="font-size: small;">am {{selectedChild.archivingDate | moment("DD.MM.YYYY")}}</span></i></h5>
                        </div>
                      </div>
                      <div v-if="togglingEditMode" class="cell small-6">
                        <div class="grid-x grid-padding-x grid-padding-y" style="padding-top: 10px">
                          <div class="cell small-12 medium-2 text-left">
                            <vue-simple-spinner></vue-simple-spinner>
                          </div>
                          <div class="cell medium-10 hide-for-small-only"><h3 style="font-size: x-large">Moment bitte
                            ... </h3></div>
                        </div>
                      </div>
                      <div v-else class="cell small-6 text-right">
                        <md-button v-if="selectedChild.archived"
                                   :disabled="sending || !(isAdmin || isFacilityAdmin || isManagement) || !canWrite"
                                   class="md-icon-button md-raised md-fifth"
                                   @click="onArchiveChildDialog">
                          <md-icon>undo</md-icon>
                          <md-tooltip>Archivierung für <b>{{ selectedChild.masterdata.fullName }}</b> rückgängig machen
                          </md-tooltip>
                        </md-button>
                        <md-card-actions v-else style="padding-bottom: 0">
                          <div v-if="selectedChild && editActive" class="cell text-center">
                            <button v-if="dialogMode === 'update'" class="button success" style="margin-bottom: 0"
                                    :disabled="sending">
                              <i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Änderungen speichern
                            </button>
                          </div>
                        </md-card-actions>
                      </div>
                    </div>

                    <ChildTabs v-if="selectedChild"
                               ref="childTabsForm"
                               :mdCardContentStyle="'height: 615px; overflow-x: auto; padding: 0; margin-top: 0;'"
                               :mode="dialogMode"
                               :selectedChild="selectedChild"
                               :editActive="editActive"
                               @saveAndExitTo="onSaveAndExitTo"
                               :sending="sending"></ChildTabs>
                  </md-tab>
                  <md-tab id="CHILD_BOOKING_TIMES" md-label="Buchungszeiten" md-icon="access_time"
                          :md-template-data="{index: 1}" style="padding: 0; margin-top: 0.5rem;">
                    <md-card-content v-if="selectedChild" style="height: 100%">
                      <BookingTimesView v-if="selectedChild" ref="bookingTimesView" :child="selectedChild"
                                        :presenceViewEnabled="false"
                                        :childFacility="selectedChildFacility" @updateChildren="updateChildren"/>
                    </md-card-content>
                  </md-tab>
                  <md-tab v-if="isTreasurer || isAdmin || isFacilityAdmin || isManagement" id="CHILD_FEES"
                          md-label="Gebühren"
                          md-icon="euro_symbol" :md-template-data="{index: 2}" style="padding: 1px">
                    <md-card-content v-if="selectedChild"
                                     style="height: 100%; padding-top: 2rem; padding-left: 0; padding-right: 0;">
                      <FeesView :child="selectedChild" @updateChildren="updateChildren"/>
                    </md-card-content>
                  </md-tab>
                  <md-tab v-if="isTreasurer || isAdmin || isFacilityAdmin || isManagement" id="CHILD_CONTRACTS"
                          md-label="Verträge"
                          md-icon="person" :md-template-data="{index: 3}" style="padding: 1px">
                    <md-card-content v-if="selectedChild"
                                     style="height: 100%; padding-top: 2rem; padding-left: 0; padding-right: 0;">
                      <ContractsView :child="selectedChild" @updateChildren="updateChildren"/>
                    </md-card-content>
                  </md-tab>
                  <md-tab v-if="isFacility || isAdmin || isFacilityAdmin || isDocumentation" id="CHILD_DOCUMENTATION"
                          md-label="Dokumentation" md-icon="library_books" :md-template-data="{index: 4}"
                          style="padding: 0">
                    <md-card-content v-if="selectedChild"
                                     style="height: 100%; padding-top: 2rem; padding-left: 0; padding-right: 0;">
                      <DocumentationView :child="selectedChild" :isVisible="activeTabId === 'CHILD_DOCUMENTATION'"
                                         @updateChildren="updateChildren"/>
                    </md-card-content>
                  </md-tab>
                </md-tabs>
              </div>
              <div :style="displayElement(presenceViewEnabled)">

                <md-card>
                  <md-card-header>
                    <div class="md-title">
                      {{ selectedChild.masterdata.fullName }}&nbsp;&nbsp;&nbsp;
                      <md-button class="md-icon-button" style="margin-top: -5px;"
                                 @click="onToggleChildAbsence(selectedChild, true)"
                                 :disabled="sending || !(isFacility || isAdmin || isFacilityAdmin || isManagement) || !canWrite">
                        <md-icon v-if="isHereToday(selectedChild)" style="color: green;">check</md-icon>
                        <md-icon v-else style="color: red;">close</md-icon>
                        <md-tooltip>Anwesenheit für <b>{{ today | moment("DD.MM.YYYY") }}</b> ändern</md-tooltip>
                      </md-button>
                    </div>

                    <div v-if="isHereToday(selectedChild)"><span style="font-size: 1.2rem;">ist heute <b
                        style="color:green">anwesend</b></span></div>
                    <div v-else><span style="font-size: 1.2rem;">ist heute <b style="color:red">abwesend</b></span>
                    </div>
                    <md-tooltip md-delay="1000" style="max-width: 500px;"><span v-if="!isHereToday(selectedChild)"
                                                                                class="ai-alert-text">{{
                        labels.absenceStatus[selectedChild.absenceStatusToday]
                      }}</span>
                    </md-tooltip>
                  </md-card-header>

                  <md-card-content>
                    <BookingTimesView v-if="selectedChild" ref="absenceTimesView" :child="selectedChild"
                                      :presenceViewEnabled="presenceViewEnabled"
                                      :childFacility="selectedChildFacility" @updateChildren="updateChildren"
                                      @updateChildAbsence="updateChildAbsence"/>
                  </md-card-content>
                </md-card>

              </div>
            </md-card-content>

            <md-card-content v-else>
              <h5><i>Bitte wählen Sie ein Kind in der Liste aus</i></h5>
            </md-card-content>

          </md-card>
        </form>
      </div>
    </div>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDeleteChildDialog" class="delete-child-dialog">
      <div v-if="selected" class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title><img width="25px" src="../assets/icons/child-torso.png"/>&nbsp;&nbsp;&nbsp;Datensatz für <span
            class="group-title">{{ lastChild }}</span> löschen
        </md-dialog-title>
        <div class="cell">
          <md-content>Soll der Datensatz für das Kind <span class="group-title">{{ lastChild }}</span> wirklich gelöscht
            werden?
          </md-content>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>
        <div class="cell text-center">
          <md-checkbox v-model="absolutelySure" :disabled="sending">Ich bin mir absolut sicher!</md-checkbox>
        </div>
        <div class="cell text-center">
          <button @click="onDeleteChild" class="button alert" :disabled="sending || !absolutelySure">
            <i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Ja, Datensatz jetzt löschen
          </button>
          <button class="button success" style="margin-left: 1rem;" @click="showDeleteChildDialog = false" type="button"
                  :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="showDeleteChildDialog = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showArchiveChildDialog">
      <div v-if="selected" class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title><img width="25px" src="../assets/icons/child-torso.png"/>&nbsp;&nbsp;&nbsp;Datensatz für <span
            class="group-title">{{ lastChild }}</span>&nbsp;
          <span v-if="!archiveActive">archivieren</span><span v-else>wiederherstellen</span>
        </md-dialog-title>
        <div class="cell text-center">
          <span v-if="!archiveActive">
            <b style="color: orangered; font-size: larger;">ACHTUNG</b> Für archivierte Kinder erfolgt keine weitere Meldung an das KiBiG-Web!<br><br>
          </span>
          <md-content>Soll der Datensatz für das Kind <span class="group-title">{{ lastChild }}</span> wirklich <span
              v-if="!archiveActive">archiviert</span><span v-else>wiederherstellt</span> werden?
          </md-content>
        </div>
        <div class="cell text-center">
          <md-checkbox v-model="confirmArchive" class="md-primary"><span v-if="!archiveActive">Archivierung</span><span
              v-else>Wiederherstellung</span> bestätigen
          </md-checkbox>
          <br><br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
          <br>
          <button @click="onArchiveChild" class="button archive" :disabled="!confirmArchive || sending">
            <i v-if="!archiveActive" class="fi-archive"></i><i v-else class="fi-arrow-left"></i>
            &nbsp;&nbsp;&nbsp;Ja, Datensatz jetzt <span v-if="!archiveActive">archivieren</span><span v-else>wiederherstellen</span>
          </button>
          <button class="button success" style="margin-left: 1rem;" @click="showArchiveChildDialog = false"
                  type="button" :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="showArchiveChildDialog = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showChangeExitDateDialog" class="change-exit-date-dialog">
      <div v-if="selected" class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title><img width="25px" src="../assets/icons/child-torso.png"/>&nbsp;&nbsp;&nbsp;Austrittsdatum für
          <span class="group-title">{{ lastChild }}</span> ändern
        </md-dialog-title>
        <div class="cell">
          <div class="md-layout element-center" style="width: 200px">
            <div class="md-layout-item">
              <md-datepicker id="changed-exit-date-dp" :md-debounce="10000" :md-open-on-focus="false"
                             :md-disabled-dates="disabledLastDay" v-model="changedExitDate" md-immediately
                             :disabled="sending">
                <label>Neues Austrittsdatum</label>
              </md-datepicker>
            </div>
          </div>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>
        <div class="cell text-center">
          <button @click="onChangeExitDate" class="button success"
                  :disabled="sending || changedExitDate === 'Invalid date' || changedExitDate === ''">
            <i class="fi-calendar"></i>&nbsp;&nbsp;&nbsp;Austrittsdatum jetzt ändern
          </button>
          <button class="button alert" style="margin-left: 1rem;" @click="showChangeExitDateDialog = false"
                  type="button" :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="showChangeExitDateDialog = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showAddSiblingDialog" class="create-sibling-dialog">
      <div v-if="selected" class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title><img width="25px" src="../assets/icons/child-torso.png"/>&nbsp;&nbsp;&nbsp;Geschwister für
          <span class="group-title">{{ lastChild }}</span> anlegen
        </md-dialog-title>
        <div class="cell text-center">
          <div class="grid-x grid-padding-x">
            <div class="cell small-1 medium-2 large-3"></div>
            <div class="cell small-10 medium-8 large-6">
              <md-field>
                <label>Ziel-Einrichtung</label>
                <md-select v-model="childTransferData.targetFacilityReference"
                           @md-selected="setAvailableTargetFacilityTypes"
                           :disabled="sending || availableTargetFacilities.length < 1">
                  <md-option v-for="facility of availableTargetFacilities" :value="facility.referenceNumber"
                             :key="facility.id">
                    {{ facility.name }}
                  </md-option>
                </md-select>
                <md-tooltip>
                  Bei keiner Angabe wird das Geschwister in der Einrichtung von <b>{{ lastChild }}</b> angelegt.
                </md-tooltip>
              </md-field>
            </div>
            <div class="cell small-1 medium-2 large-3"></div>
          </div>
        </div>
        <div class="cell text-center">
          <md-content>
            Soll ein Geschwister für <span class="group-title">{{ lastChild }}</span> wirklich angelegt werden?
          </md-content>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>
        <div class="cell text-center">
          <button @click="onAddSibling" class="button success" :disabled="sending">
            <md-icon class="md-button-icon">group_add</md-icon>&nbsp;&nbsp;&nbsp;Ja, Geschwister anlegen
          </button>
          <button class="button primary" style="margin-left: 1rem;" @click="showAddSiblingDialog = false" type="button"
                  :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="showAddSiblingDialog = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDeclareSiblingsDialog">
      <div v-if="selectedRows && selectedRows.length > 0" class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title>
          <md-icon style="color: black; font-size: xx-large!important; margin-right: 1rem;">group</md-icon>
          Kinder als Geschwister festlegen
        </md-dialog-title>
        <div class="cell text-center">
          <md-content>Die Kinder
            <span v-for="(child, index) of selectedRows" :key="child.id"><span
                class="group-title">{{ child.masterdata.fullName }}</span>
              <b v-if="index < selectedRows.length - 2">, </b>
              <span v-if="index === selectedRows.length - 2"> und </span>
            </span>
            <br><br>
            <div class="text-center">
              <md-radio v-model="areSiblings" :value="true" class="md-primary">sind Geschwister</md-radio>
              <md-radio v-model="areSiblings" :value="false" class="md-primary">sind <b>keine</b> Geschwister</md-radio>
            </div>
          </md-content>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>
        <div class="cell text-center">
          <button @click="onDeclareSiblings" class="button success" :disabled="sending">
            <md-icon v-if="areSiblings" class="md-button-icon">group</md-icon>
            <md-icon v-else class="md-button-icon">people_outline</md-icon>&nbsp;&nbsp;&nbsp;Ja, sind <b
              v-if="!areSiblings">keine </b>Geschwister
          </button>
          <button class="button alert" style="margin-left: 1rem;" @click="showDeclareSiblingsDialog = false"
                  type="button" :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Abbrechen
          </button>
        </div>
        <button class="close-button" type="button" @click="showDeclareSiblingsDialog = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDeclareCoronaCompensationDialog" class="declare-corona-compensation-dialog">
      <div v-if="selectedRows && selectedRows.length > 0" class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title>
          <md-icon style="color: black; font-size: xx-large!important; margin-right: 1rem;">local_hospital</md-icon>
          Corona Beitragsersatz für {{ selectedRows.length }} Kinder festlegen
        </md-dialog-title>
        <div class="cell text-center">
          <md-content style="padding: 1rem">
            <ChildForm ref="childFormCompensation" :child="newChild" :compensation="true"
                       :sending="sending"></ChildForm>
          </md-content>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>
        <div class="cell text-center">
          <md-radio v-model="activateCoronaCompensation" value="true" class="md-primary"
                    :disabled="!hasCoronaCompensationOptionsSelected()">
            Beitragsersatz setzen
            <md-tooltip v-if="selectedRows"><span
                v-if="hasCoronaCompensationOptionsSelected()">Für <b>{{ selectedRows.length }}</b> Kinder wird der Corona-Beitragsersatz für die ausgewählten Monate <b>gesetzt</b></span>
            </md-tooltip>
          </md-radio>
          <md-radio v-model="activateCoronaCompensation" value="false" class="md-primary"
                    :disabled="!hasCoronaCompensationOptionsSelected()">
            Beitragsersatz löschen
            <md-tooltip v-if="selectedRows"><span
                v-if="hasCoronaCompensationOptionsSelected()">Für <b>{{ selectedRows.length }}</b> Kinder wird der Corona-Beitragsersatz für die ausgewählten Monate <b>gelöscht</b></span>
            </md-tooltip>
          </md-radio>
          <md-tooltip>
            <span v-if="!hasCoronaCompensationOptionsSelected()">Bitte zunächst Jahr(e) und Monat(e) für Beitragsersatz auswählen</span>
          </md-tooltip>
        </div>
        <div class="cell text-center">
          <button @click="onDeclareCoronaCompensation(activateCoronaCompensation)" class="button success"
                  :disabled="sending || activateCoronaCompensation === null || !hasCoronaCompensationOptionsSelected()">
            <md-icon class="md-button-icon">done_all</md-icon>&nbsp;&nbsp;&nbsp;Ja, Beitragsersatzänderungen für
            <b>{{ selectedRows.length }}</b> Kinder durchführen
          </button>
          <button class="button alert" style="margin-left: 1rem;" @click="showDeclareCoronaCompensationDialog = false"
                  type="button" :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Abbrechen
          </button>
        </div>
        <button class="close-button" type="button" @click="showDeclareCoronaCompensationDialog = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showTransferChildDialog" style="min-width: 75%;">
      <md-dialog-title>
        <div v-if="selected" class="grid-x grid-padding-x">
          <div class="cell">
            <img width="25px" src="../assets/icons/child-torso.png"/>
            <md-icon v-if="transferMoveDialogMode" class="md-button-icon">autorenew</md-icon>
            <md-icon v-else class="md-button-icon">arrow_forward</md-icon>
            <span v-if="transferMoveDialogMode">&nbsp;&nbsp;&nbsp;Wechsel</span>
            <span v-else>&nbsp;&nbsp;&nbsp;Übertritt</span>&nbsp;&nbsp;&nbsp;für&nbsp;
            <span class="group-title">{{ selected.masterdata.fullName }}</span>&nbsp;durchführen
          </div>
        </div>
      </md-dialog-title>

      <md-dialog-content>
        <div v-if="selected && selectedChildFacility" class="grid-x grid-padding-x">
          <div class="cell">
            <div class="grid-x grid-padding-x">
              <div class="cell large-6 medium-6 small-6">von Einrichtung<br><b class="group-title"
                                                                               style="color: cornflowerblue;">{{
                  selectedChildFacility.name
                }}</b></div>
              <div class="cell large-6 medium-6 small-6">von Bereich<br><b class="group-title"
                                                                           style="color: cornflowerblue;">{{
                  labels.divisiontype[selected.divisiontype]
                }}</b></div>
            </div>
            <br>
            <div class="grid-x grid-padding-x">
              <div v-if="availableTargetFacilities && availableTargetFacilities.length > 0"
                   class="cell large-6 medium-6 small-6 text-center">
                <md-field>
                  <label>Ziel-Einrichtung</label>
                  <md-select v-model="childTransferData.targetFacilityReference"
                             @md-selected="setAvailableTargetFacilityTypes"
                             :disabled="sending || availableTargetFacilities.length < 1">
                    <md-option v-for="facility of availableTargetFacilities" :value="facility.referenceNumber"
                               :key="facility.id">
                      {{ facility.name }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
              <div v-if="availableTargetFacilityTypes" class="cell large-6 medium-6 small-6 text-center">
                <md-field>
                  <label>Ziel-Bereich</label>
                  <md-select v-model="targetFacilityType"
                             :disabled="sending || availableTargetFacilityTypes.length < 1">
                    <md-option v-for="facilityType of availableTargetFacilityTypes" :value="facilityType"
                               :key="facilityType">
                      {{ labels.facilityType[facilityType] }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
          </div>
          <div v-if="!transferMoveDialogMode" class="cell text-center">
            <div class="md-layout">
              <div class="md-layout-item" style="padding-right: 16px;">
                <md-datepicker id="child-transfer-from-date-dp" :md-debounce="10000" :md-open-on-focus="false"
                               :md-disabled-dates="disabledFirstDay" v-model="childTransferData.from" md-immediately
                               :disabled="sending">
                  <label>Übertrittsdatum</label>
                </md-datepicker>
              </div>
              <div class="md-layout-item" style="padding-left: 16px;">
                <md-datepicker id="child-transfer-until-date-dp" :md-debounce="10000" :md-open-on-focus="false"
                               :md-disabled-dates="disabledLastDay" v-model="childTransferData.until" md-immediately
                               :disabled="sending">
                  <label>Austrittsdatum</label>
                  <md-tooltip>Vorläufiges Austrittsdatum für das neu angelegte Kind</md-tooltip>
                </md-datepicker>
              </div>
            </div>
          </div>
          <div class="cell text-center">
            <br>
            <md-content>Soll der <span v-if="transferMoveDialogMode">Wechsel</span><span v-else>Übertritt</span> für
              <span class="group-title">{{ selected.masterdata.fullName }}</span> wirklich durchgeführt werden?<br>
              <md-tooltip v-if="transferMoveDialogMode">Das Kind wird <b>sofort</b> in die Ziel-Einrichtung verschoben
                und ist somit in dessen jetziger Einrichtung <b>nicht mehr vorhanden</b>!
              </md-tooltip>
              <md-tooltip v-else>Es wird eine <b>Kopie</b> des Kindes in der Ziel-Einrichtung angelegt. Der Übertritt
                erfolgt zum angegebenen Datum.
              </md-tooltip>
            </md-content>
            <br>
            <md-progress-bar md-mode="indeterminate" v-if="sending"/>
            <br>
          </div>
          <div class="cell text-center">
            <button v-if="transferMoveDialogMode" @click="onMoveToFacility" class="button success"
                    :disabled="sending || !(childTransferData.targetFacilityReference)">
              <md-icon class="md-button-icon">autorenew</md-icon>&nbsp;&nbsp;&nbsp;Ja, Wechsel durchführen
            </button>
            <button v-else @click="onTransferToFacility" class="button success"
                    :disabled="sending || !(childTransferData.targetFacilityReference && childTransferData.from && childTransferData.until)">
              <md-icon class="md-button-icon">arrow_forward</md-icon>&nbsp;&nbsp;&nbsp;Ja, Übertritt durchführen
            </button>
            <button class="button primary" style="margin-left: 1rem;" @click="showTransferChildDialog = false"
                    type="button" :disabled="sending">
              <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
            </button>
          </div>
        </div>
      </md-dialog-content>

      <button class="close-button" type="button" @click="showTransferChildDialog = false">
        <span aria-hidden="true">&times;</span>
      </button>
    </md-dialog>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="confirmMissingChildDataDialog" class="confirm-incomplete-child-data-dialog">
      <div v-if="selected" class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title><img width="25px" src="../assets/icons/child-torso.png"/>&nbsp;&nbsp;&nbsp;Unvollständigen
          Datensatz für Kind bestätigen
        </md-dialog-title>
        <div class="cell text-center">
          <md-content>Soll der unvollständige Datensatz für das Kind <span class="group-title">{{ lastChild }}</span>
            wirklich gespeichert werden?
          </md-content>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>
        <div class="cell text-center">
          <button @click="updateChild" class="button success" :disabled="sending"><i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Ja,
            speichern
          </button>
          <button class="button alert" style="margin-left: 1rem;" @click="confirmMissingChildDataDialog = false"
                  type="button" :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="confirmMissingChildDataDialog = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDeleteChildImageDialog" class="delete-child-image-dialog">
      <div v-if="selected" class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title>
          <ImageView :imageId="selected.imageId" size="tiny"></ImageView>&nbsp;&nbsp;&nbsp;Bild von <span
            class="group-title">{{ lastChild }}</span> löschen
        </md-dialog-title>
        <div class="cell">
          <md-content class="text-center">Soll das Bild von <span class="group-title">{{ lastChild }}</span> wirklich
            gelöscht werden?
          </md-content>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>
        <div class="cell text-center">
          <div @click="onDeleteChildImage" class="button alert"><i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Ja, Bild jetzt
            löschen
          </div>
          <button class="button success" style="margin-left: 1rem;" @click="showDeleteChildImageDialog = false"
                  type="button" :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="showDeleteChildImageDialog = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="confirmSaveChangedDataDialog">
      <div v-if="selected" class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title>
          <img width="25px" src="../assets/icons/child-torso.png"/>&nbsp;&nbsp;&nbsp;Änderungen für Kind bestätigen
        </md-dialog-title>
        <div class="cell">
          <md-content>Es wurden Änderungen am Datensatz für das Kind <span class="group-title">{{ lastChild }}</span>
            durchgeführt. Sollen diese gespeichert werden?
          </md-content>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>
        <div class="cell text-center">
          <button @click="updateChild" class="button success" :disabled="sending"><i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Ja,
            speichern
          </button>
          <button class="button alert" style="margin-left: 1rem;" @click="onCancelSaveChanges" type="button"
                  :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="confirmSaveChangedDataDialog = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showComfortFunctionHint" class="show-comfort-function-dialog">
      <div class="grid-x grid-padding-x">
        <md-dialog-title>
          <md-icon>info</md-icon>&nbsp;&nbsp;&nbsp;Hinweis
        </md-dialog-title>
        <div class="cell text-center">
          <md-content>Für die Ausführung dieser Funktion wählen Sie bitte zunächst ein Kind in der Liste.
          </md-content>
        </div>
        <div class="cell text-center">
          <br>
          <button @click="showComfortFunctionHint = false" class="button success" :disabled="sending">
            <i class="fi-check"></i>&nbsp;&nbsp;&nbsp;Verstanden
          </button>
        </div>
        <button class="close-button" type="button" @click="showComfortFunctionHint = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showMaxNumberOfChildrenExceededHint" class="show-comfort-function-dialog">
      <div class="grid-x grid-padding-x">
        <md-dialog-title>
          <md-icon style="color: red">pan_tool</md-icon>&nbsp;&nbsp;&nbsp;LIZENZ-Überschreitung
        </md-dialog-title>
        <div class="cell text-center">
          <md-content>Die maximale Anzahl an aktuellen Kindern ist überschritten.
            <br><br>
            Vertragliche maximale Anzahl : <b>{{ organizationContract.maxNumberOfChildren }}</b>
            <br>
            Anzahl aktueller Kinder : <b style="color: red">{{ numberOfCurrentChildren }}</b>
            <br><br>
            <md-checkbox v-model="licenseViolationRemindMeLater" class="md-primary" @change="performLicenseCheck">
              Später daran erinnern
            </md-checkbox>
          </md-content>
        </div>
        <div class="cell text-center">
          <br>
          <router-link to="/Verwaltung/Traeger/Vertrag">
            <button @click="showMaxNumberOfChildrenExceededHint = false" class="button success" :disabled="sending">
              <i class="fi-wrench"></i>&nbsp;&nbsp;&nbsp;Vertrag anpassen
              <md-tooltip>Die <b>maximale Anzahl Kinder</b> in den Vertragsdaten bearbeiten</md-tooltip>
            </button>
          </router-link>
          <button style="margin-left: 1rem;" @click="showMaxNumberOfChildrenExceededHint = false" class="button warn"
                  :disabled="sending">
            <i class="fi-check"></i>&nbsp;&nbsp;&nbsp;Verstanden
          </button>
          <br>
        </div>
        <button class="close-button" type="button" @click="showMaxNumberOfChildrenExceededHint = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

    <EditChildDialog :showDialog="showEditChildDialog" :child="newChild" :mode="dialogMode"
                     :availableFacilities="allowedFacilities" :availableGroups="groups"
                     @saveAndExitTo="onSaveAndExitTo"
                     @updateChildSuccess="updateChildSuccess"
                     @confirmMissingData="confirmMissingData"
                     @closeEditChildDialog="showEditChildDialog = false"></EditChildDialog>

    <EditFeeDialog v-if="selectedRows && newChild && newChild.facilityReference && newChild.facilityReference !== '*'"
                   :showDialog="showEditFeeDialog" :children="selectedRows"
                   :child="newChild" :selectedFee="multiFee" :mode="dialogMode"
                   @updateFeeSuccess="updateFeeSuccess"
                   @closeEditFeeDialog="showEditFeeDialog = false"></EditFeeDialog>

    <ChildBriefRecord :child="selectedChild" :groups="groups"></ChildBriefRecord>

    <StickyMenu v-if="!archiveActive && !multiChildSelection && !isDocumentation" :menu="leftStickyMenu"
                @addSibling="onAddSiblingDialog"
                @changeExitDate="onChangeExitDateDialog" @addChild="onAddChildDialog"
                @transferToFacility="onTransferToFacilityDialog" @moveToFacility="onMoveToFacilityDialog"
                @deleteChild="onDeleteChildDialog" @archiveChild="onArchiveChildDialog"></StickyMenu>
  </div>
</template>

<script>
import jQuery from 'jquery';
import heic2any from 'heic2any';
import {imageProcessor, resize, sharpen} from 'ts-image-processor';
import b64toBlob from 'b64-to-blob';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Vue from 'vue';
import Spinner from 'vue-simple-spinner';

import ChildService from '../services/ChildService';
import GroupService from '../services/GroupService';
import EmployeeService from '../services/EmployeeService';
import HttpErrorHandler from '../services/HttpErrorHandler';
import HttpInterceptor from '../services/HttpInterceptor';

import AddressView from '../components/views/AddressView';
import ContactView from '../components/views/ContactView';
import PersonView from '../components/views/PersonView';
import ChildView from '../components/views/ChildView';
import BookingTimesView from '../components/views/BookingTimesView';
import FeesView from '../components/views/FeesView';
import ContractsView from '../components/views/ContractsView';
import ImageView from '../components/views/ImageView';

import AlertIcons from '../components/animations/AlertIcons';
import StickyMenu from '../components/menus/StickyMenu';
import ChildBriefRecord from '../components/fabs/ChildBriefRecord';

import PersonForm from '../components/forms/PersonForm';
import ChildForm from '../components/forms/ChildForm';
import AddressForm from '../components/forms/AddressForm';
import ContactForm from '../components/forms/ContactForm';

import ChildTabs from '../components/tabs/ChildTabs';

import Child from '../entities/Child';
import Person from '../entities/Person';
import Address from '../entities/Address';
import Contact from '../entities/Contact';
import Fee from '../entities/Fee';

import EditChildDialog from '../components/dialogs/EditChildDialog';
import EditFeeDialog from '../components/dialogs/EditFeeDialog';

import {validationMixin} from 'vuelidate';
import {Md5} from 'ts-md5/dist/md5';
import DocumentationView from "../components/views/DocumentationView";
import FacilityService from "../services/FacilityService";
import NoFacilityCard from "../components/cards/NoFacilityCard";
import BinaryService from "@/services/BinaryService";

const toLower = text => {
  return text.toString().toLowerCase();
};

const searchByLastname = (items, term) => {
  if (term) {
    return items.filter(item => toLower(item.masterdata.lastname).includes(toLower(term)));
  }

  return items;
};

const searchByDivision = (items, terms) => {
  if (terms) {
    return items.filter(item => {
      for (let term of terms) {
        if (toLower(item.divisiontype).includes(toLower(term))) {
          return true;
        }
      }
      return false;
    });
  }

  return items;
};

const searchByGroup = (items, terms) => {
  if (terms) {
    if (terms.length <= 0) {
      return items;
    }

    return items.filter(item => {
      for (let groupReference of item.groupReferences) {
        for (let term of terms) {
          if (toLower(groupReference).includes(toLower(term))) {
            return true;
          }
        }
      }
      return false;
    });
  }

  return items;
};

const filterChildren = (items, selectedFilter) => {
  return items.filter(item => {
    if (selectedFilter.includes('PAST') && item.filterState.includes('PAST')) {
      return true;
    } else if (selectedFilter.includes('CURRENT') && item.filterState.includes('CURRENT')) {
      return true;
    } else if (selectedFilter.includes('FUTURE') && item.filterState.includes('FUTURE')) {
      return true;
    } else if (selectedFilter.includes('FUTURE_EXIT') && item.filterState.includes('FUTURE_EXIT')) {
      return true;
    } else if (selectedFilter.includes('FUTURE_EXIT_CURRENT') && item.filterState.includes('FUTURE_EXIT_CURRENT')) {
      return true;
    } else {
      return (selectedFilter.length <= 0);
    }
  });
};

const searchByFeature = (items, childFilter) => {
  return items.filter(item => {
    const filterResults = [];
    if (childFilter.handicapped) {
      filterResults.push(childFilter.handicapped === item.handicapped);
    }
    if (childFilter.migrantBackground) {
      filterResults.push(childFilter.migrantBackground === item.migrantBackground);
    }
    if (childFilter.nutritionInfo.porkMeatAllowed) {
      filterResults.push(childFilter.nutritionInfo.porkMeatAllowed === item.nutritionInfo.porkMeatAllowed);
    }
    if (childFilter.possiblePreschooler) {
      filterResults.push(childFilter.possiblePreschooler === item.possiblePreschooler);
    }
    if (childFilter.preschooler) {
      filterResults.push(childFilter.preschooler === item.preschooler);
    }
    if (childFilter.shareInformationAllowed) {
      filterResults.push(childFilter.shareInformationAllowed === item.shareInformationAllowed);
    }
    if (childFilter.sibling) {
      filterResults.push(childFilter.sibling === item.sibling);
    }
    if (childFilter.gettingWirtschaftlicheJugendhilfe) {
      filterResults.push(childFilter.gettingWirtschaftlicheJugendhilfe === item.gettingWirtschaftlicheJugendhilfe);
    }

    if (childFilter.nutritionInfo.allergic) {
      filterResults.push(childFilter.nutritionInfo.allergic === item.nutritionInfo.allergic);
    }
    if (childFilter.firstMeaslesVaccinationDate) {
      return (item.firstMeaslesVaccinationDate === null);
    }
    if (childFilter.secondMeaslesVaccinationDate) {
      return (item.secondMeaslesVaccinationDate === null);
    }
    if (childFilter.exemptionMeaslesVaccination) {
      filterResults.push(childFilter.exemptionMeaslesVaccination === item.exemptionMeaslesVaccination);
    }
    if (childFilter.exemptionSecondMeaslesVaccination) {
      filterResults.push(childFilter.exemptionSecondMeaslesVaccination === item.exemptionSecondMeaslesVaccination);
    }

    if (childFilter.parentalConsents.dsgvoConfirmationObtained) {
      let result = !item.masterdata.dsgvoConfirmationObtained;
      for (let relatedPerson of item.relatedPersons) {
        if (relatedPerson.dsgvoConfirmationObtained === false) {
          result = true;
        }
      }
      filterResults.push(result);
    }

    if (childFilter.parentalConsents.applyPlasterSalveAllowed) {
      filterResults.push(childFilter.parentalConsents.applyPlasterSalveAllowed === item.parentalConsents.applyPlasterSalveAllowed);
    }
    if (childFilter.parentalConsents.beingPhotographedPrivatelyAllowed) {
      filterResults.push(childFilter.parentalConsents.beingPhotographedPrivatelyAllowed === item.parentalConsents.beingPhotographedPrivatelyAllowed);
    }
    if (childFilter.parentalConsents.beingPhotographedPubliclyAllowed) {
      filterResults.push(childFilter.parentalConsents.beingPhotographedPubliclyAllowed === item.parentalConsents.beingPhotographedPubliclyAllowed);
    }
    if (childFilter.parentalConsents.contactToAnimalsAllowed) {
      filterResults.push(childFilter.parentalConsents.contactToAnimalsAllowed === item.parentalConsents.contactToAnimalsAllowed);
    }
    if (childFilter.parentalConsents.goSwimmingAllowed) {
      filterResults.push(childFilter.parentalConsents.goSwimmingAllowed === item.parentalConsents.goSwimmingAllowed);
    }
    if (childFilter.parentalConsents.removeTickAllowed) {
      filterResults.push(childFilter.parentalConsents.removeTickAllowed === item.parentalConsents.removeTickAllowed);
    }
    if (childFilter.parentalConsents.ridingCarAllowed) {
      filterResults.push(childFilter.parentalConsents.ridingCarAllowed === item.parentalConsents.ridingCarAllowed);
    }
    if (childFilter.parentalConsents.applySuncreamAllowed) {
      filterResults.push(childFilter.parentalConsents.applySuncreamAllowed === item.parentalConsents.applySuncreamAllowed);
    }

    let result = true;
    for (const fs of filterResults) {
      if (!fs) {
        result = false;
      }
    }
    return result;
  });
};

const searchByFreeAttributes = (items, freeAttributesFilter) => {
  return items.filter(item => {
    const filterResults = [];
    freeAttributesFilter.forEach(freeAttributeOfFilter => {
      if (freeAttributeOfFilter.type === 'BOOLEAN') {
        if (freeAttributeOfFilter.booleanValue === true) {
          item.freeAttributes.forEach(freeAttributeOfChild => {
            if (freeAttributeOfChild.type === 'BOOLEAN' && freeAttributeOfChild.refNr === freeAttributeOfFilter.refNr) {
              filterResults.push(freeAttributeOfFilter.booleanValue === freeAttributeOfChild.booleanValue)
            }
          })
        }
      }
    })

    let result = true;
    for (const fs of filterResults) {
      if (!fs) {
        result = false;
      }
    }
    return result;
  });
}

const searchByExit = (items, years, months) => {
  return items.filter(item => {
    let exitYear = Vue.moment(item.exitDate, 'YYYY-MM-DD').format("YYYY");
    let exitMonth = Vue.moment(item.exitDate, 'YYYY-MM-DD').format("MMMM");
    return years.includes(exitYear) && months.includes(exitMonth);
  });
};

export default {
  name: 'Children',
  mixins: [validationMixin],

  components: {
    NoFacilityCard,
    DocumentationView,
    ImageView,
    vueDropzone: vue2Dropzone,
    EditChildDialog,
    EditFeeDialog,
    AddressView,
    ContactView,
    PersonView,
    ChildView,
    BookingTimesView,
    FeesView,
    ContractsView,
    AlertIcons,
    StickyMenu,
    ChildBriefRecord,
    ChildTabs,
    Child,
    Person,
    Address,
    ChildForm,
    PersonForm,
    AddressForm,
    ContactForm,
    'vue-simple-spinner': Spinner
  },

  created() {
    this.restoreUserSettings();
  },

  mounted() {
    if (this.$route.params['id']) {
      localStorage.setItem(this.user.md5 + '@selectedChildRowId', this.$route.params['id']);
      localStorage.setItem(this.user.md5 + '@openChildFromUrl', this.$route.params['id']);
    } else {
      localStorage.removeItem(this.user.md5 + '@openChildFromUrl');
    }

    if (!this.isDeleted) {
      this.reloadChildren();
    }

    window.addEventListener('resize', () => {
      this.resizeDialogElements();
    }, {passive: true});

    HttpErrorHandler.maintainDarkMode(this);
  },

  data() {
    return {
      disabledFirstDay: date => {
        const day = date.getDate();
        return day !== 1;
      },

      disabledLastDay: date => {
        const day = date.getDate();
        const dateString = date.getFullYear() + '-' + Number(date.getMonth() + 1) + '-' + Number(date.getDay() + 1);
        const lastDay = Number(Vue.moment(dateString).endOf('month').format('DD'));
        return day !== lastDay
      },

      today: Vue.moment(),

      renderTable: false,

      leftStickyMenu: {},
      sending: false,
      children: [],
      archivedChildren: [],
      searched: [],
      search: null,
      selected: null,
      selectedRows: [],
      showTransferChildDialog: false,
      transferMoveDialogMode: false,
      showAddSiblingDialog: false,
      showDeclareSiblingsDialog: false,
      showDeclareCoronaCompensationDialog: false,
      showDeleteChildDialog: false,
      showArchiveChildDialog: false,
      showChangeExitDateDialog: false,
      showDeleteChildImageDialog: false,
      showEditChildDialog: false,
      showEditFeeDialog: false,
      confirmMissingChildDataDialog: false,
      confirmSaveChangedDataDialog: false,
      success: false,
      lastChild: '',
      dialogMode: 'update',
      selectedChild: null,
      selectedChildFacility: null,
      selectedChildOriginal: null,
      selectedChildMd5Hash: null,
      selectedChildAggregatedFees: '',
      newChild: null,
      editActive: false,
      editActiveSwitch: false,
      availableFacilities: [],
      allowedFacilities: [],
      selectedFacilityReferenceNumber: null,
      selectedFacilityName: '',
      selectedFacility: null,
      selectedDivisions: [],
      selectedGroups: [],
      selectedGroupIds: [],
      groups: [],
      facilityGroups: [],
      selectedFilter: [],

      changedExitDate: '',

      childFilter: JSON.parse(JSON.stringify(Child.child)),
      childFilterActiveCounter: 0,
      lastSelectedFilterTabId: 'Allgemein',

      options: {
        url: '',
        maxFilesize: 20.0,
        headers: {
          'accept': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'Authorization': 'Bearer ' + sessionStorage.getItem('sose.access_token')
        },
        thumbnailWidth: 155,
        thumbnailHeight: 155,
        /*  previewTemplate:
            '<div class="dz-preview dz-file-preview">' +
            '  <div class="dz-details">' +
            '    <div class="dz-filename"><span data-dz-name></span></div>' +
            '    <div class="dz-size" data-dz-size></div>' +
            '    <img data-dz-thumbnail />' +
            '  </div>' +
            '  <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>' +
            '  <div class="dz-success-mark"><span>✔</span></div>' +
            '  <div class="dz-error-mark"><span>✘</span></div>' +
            '  <div class="dz-error-message"><span data-dz-errormessage></span></div>' +
            '</div>',*/

        dictDefaultMessage: 'Bild zum Hochladen hier fallen lassen oder zum Auswählen hier Klicken<br><br>(max. Größe 20MB)',
        acceptedFiles: '.jpg, .jpeg, .heic, .avif, .png',
        autoProcessQueue: false,
        autoQueue: false,
      },

      togglingEditMode: false,
      togglingAddMode: false,
      selectedRowId: 0,
      activeTabId: '',
      initialTableSortDone: false,

      showComfortFunctionHint: false,
      showMaxNumberOfChildrenExceededHint: false,
      licenseViolationRemindMeLater: false,
      numberOfCurrentChildren: 0,

      moreFilterVisible: false,
      archiveActive: false,
      archiveActiveToggle: false,

      selectedYears: [],
      archiveYears: [],
      selectedMonths: [],

      selectedDocumentation: null,
      confirmArchive: false,

      editDataToggledByOverlay: false,
      absolutelySure: false,

      editGroups: false,
      currentEditGroupReferences: [],

      availableTargetFacilities: [],
      targetFacilityType: null,
      availableTargetFacilityTypes: [],

      childTransferData: {
        sourceChildId: null,
        targetFacilityReference: null,
        targetDivisionType: null,
        from: '',
        until: '',
      },

      multiChildSelection: true,
      multiChildSelectionInitialized: false,

      multiFee: null,

      areSiblings: true,

      yearFilterEnabled: false,
      filterYears: [],
      filterKigaYears: {},
      filterSettings: {
        year: Vue.moment().format("YYYY"),
        kgj: true,
        facRef: '',
        divisionType: '',
      },

      presenceViewEnabled: false,
      toggleAbsenceTimeViewAllowed: true,
      activateCoronaCompensation: null,

      exitRoute: '',
    }
  },

  methods: {

    absenceStyle(absent) {
      return absent ? 'font-style: italic; color: grey;' : '';
    },

    displayElement(display) {
      return display ? '' : 'display: none'
    },

    initMultiSelection() {
      if (!this.multiChildSelectionInitialized) {
        this.multiChildSelectionInitialized = true;
        setTimeout(() => {
          this.multiChildSelection = false;
          this.toggleMultiChildSelection();
        }, 250);
      }
    },

    toggleMultiChildSelection() {
      if (this.selectedFacilityReferenceNumber && this.selectedFacilityReferenceNumber === '*') {
        setTimeout(() => {
          this.multiChildSelection = false;
          this.maintainMultiChildSelection();
        }, 50);
        return;
      }

      this.search = null;
      this.selected = null;
      this.selectedChild = null;
      const jqTableCellSelection = jQuery('.md-table-cell-selection');
      if (this.multiChildSelection) {
        jqTableCellSelection.children().children().first().show();
        jQuery('.md-table-row.md-has-selection.md-selected-single.md-primary').click();
        jqTableCellSelection.children().children().first().children().first().attr('style', 'padding-left: 0');
        jqTableCellSelection.attr('style', 'width: 45px;');
      } else {
        jqTableCellSelection.children().children().first().hide();
        jqTableCellSelection.children().children().first().children().first().attr('style', 'padding-left: 0');
        jqTableCellSelection.attr('style', 'width: 24px;');
        if (this.selectedRows && this.selectedRows.length > 0) {
          if (this.selectedRows && this.selectedRows.length < this.searched.length) {
            jqTableCellSelection.children().children().first().children().first().children().first().children().first().trigger("click");
          }
          setTimeout(() => jqTableCellSelection.children().children().first().children().first().children().first().children().first().trigger("click"), 125)
        }
      }
    },

    maintainMultiChildSelection() {
      setTimeout(() => {
        if (this.multiChildSelection) {
          jQuery('.md-table-cell-selection').show();
        } else {
          jQuery('.md-table-cell-selection').hide();
        }
      }, 50);
    },

    toggleYearFilter() {
      if (this.yearFilterEnabled && this.multiChildSelection) {
        jQuery('#multi-child-selection-switch-id').parent().trigger('click');
      }

      if (this.yearFilterEnabled) {
        this.onSelectFacility();
        this.childTransferData.targetFacilityReference = this.selectedFacilityReferenceNumber;
        this.setAvailableTargetFacilityTypes();
        this.getFilteredChildren();
      } else {
        this.reloadChildren();
      }
    },

    togglePresenceView() {
      if (this.presenceViewEnabled && this.multiChildSelection) {
        jQuery('#multi-child-selection-switch-id').parent().trigger('click');
      }
    },

    getFilteredChildren() {
      if (this.yearFilterEnabled) {
        this.sending = true;
        this.filterSettings.facRef = this.selectedFacilityReferenceNumber;
        FacilityService.getFilteredChildren(this.filterSettings)
            .then(response => {
              this.children = response.data;
              this.sending = false;
              this.searchOnTable();
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Filtern der Kinder für Einrichtung <b>' + this.selectedFacilityName + '</b> und Jahr <b>' + this.filterSettings.year + '</b>');
              this.sending = false;
            })
      }
    },

    initDatePickerSyncHandler(init) {
      if (init) {
        setTimeout(() => {
          jQuery('#changed-exit-date-dp').children('input').bind('blur', () => {
            this.changedExitDate = jQuery('#changed-exit-date-dp').children('input').val();
          });
          jQuery('#child-transfer-from-date-dp').children('input').bind('blur', () => {
            this.childTransferData.from = jQuery('#child-transfer-from-date-dp').children('input').val();
          });
          jQuery('#child-transfer-until-date-dp').children('input').bind('blur', () => {
            this.childTransferData.until = jQuery('#child-transfer-until-date-dp').children('input').val();
          });
        }, 500);
      } else {
        jQuery('#changed-exit-date-dp').children('input').unbind('blur');
        jQuery('#child-transfer-from-date-dp').children('input').unbind('blur');
        jQuery('#child-transfer-until-date-dp').children('input').unbind('blur');
      }
    },

    restoreUserSettings() {
      this.selectedFacilityReferenceNumber = localStorage.getItem(this.user.md5 + '@selectedFacilityReferenceNumber');
      this.archiveActive = (localStorage.getItem(this.user.md5 + '@archiveActive') === 'true');
      this.archiveActiveToggle = this.archiveActive;

      if (localStorage.getItem(this.user.md5 + '@selectedYears')) {
        this.selectedYears = JSON.parse(localStorage.getItem(this.user.md5 + '@selectedYears'));
      }

      if (localStorage.getItem(this.user.md5 + '@selectedDivisions')) {
        this.selectedDivisions = JSON.parse(localStorage.getItem(this.user.md5 + '@selectedDivisions'));
      }

      this.selectedGroups = [];
      if (localStorage.getItem(this.user.md5 + '@selectedGroups')) {
        this.selectedGroups = JSON.parse(localStorage.getItem(this.user.md5 + '@selectedGroups'));
      }

      this.selectedFilter = ['CURRENT', 'FUTURE'];
      if (localStorage.getItem(this.user.md5 + '@selectedFilterChildren')) {
        this.selectedFilter = JSON.parse(localStorage.getItem(this.user.md5 + '@selectedFilterChildren'));
      }

      if (sessionStorage.getItem('sose.licenseViolationReminder')) {
        this.licenseViolationRemindMeLater = JSON.parse(sessionStorage.getItem('sose.licenseViolationReminder'));
      }
    },

    restoreDialogSelections() {

      if (localStorage.getItem(this.user.md5 + '@selectedChildRowId')) {
        this.selectedRowId = localStorage.getItem(this.user.md5 + '@selectedChildRowId');
      }

      if (this.selectedRowId !== null && this.selectedRowId !== 0) {
        let maxTries = process.env.VUE_APP_RELOAD_MAX_TRIES;
        const retryIntervalId = setInterval(() => {

          if (this.selectedRowId === 0) {
            maxTries = 0;
            clearInterval(retryIntervalId);
          }

          let row = jQuery('#' + this.selectedRowId);
          if (row && row.length > 0) {
            clearInterval(retryIntervalId);
            if (localStorage.getItem(this.user.md5 + '@returnToDocumentationSelection')) {

              if (localStorage.getItem(this.user.md5 + '@selectedDocumentation')) {
                this.selectedDocumentation = JSON.parse(localStorage.getItem(this.user.md5 + '@selectedDocumentation'));
              }

              if (this.selectedDocumentation) {
                setTimeout(() => {
                  this.selectChildId(this.selectedRowId);

                  setTimeout(() => {
                    jQuery('span:contains("Dokumentation")').trigger('click');
                  }, 250);
                }, 250);
              }
            } else {
              setTimeout(() => {
                if (this.selectedRowId === localStorage.getItem(this.user.md5 + '@openChildFromUrl')) {
                  this.selectChildId(this.selectedRowId);
                }
              }, 250);
            }
          } else {
            maxTries--;
            if (maxTries <= 0) {
              clearInterval(retryIntervalId);
            }
          }
        }, 250);
      }
    },

    closeChildFilter(id) {
      if (id === 'close') {
        jQuery('div:contains("' + this.lastSelectedFilterTabId + '")').trigger('click');
        this.moreFilterVisible = false;
      } else {
        this.lastSelectedFilterTabId = id;
      }
    },

    determineSelectedGroupIds() {
      this.selectedGroupIds = [];
      for (const key of this.selectedGroups) {
        for (const group of this.groups) {
          if (group.key === key) {
            this.selectedGroupIds.push(group.id);
          }
        }
      }
    },

    initData() {
      this.determineSelectedGroupIds();

      if (this.labels && this.labels.divisiontype && this.selectedDivisions && this.selectedDivisions.length <= 0) {
        this.selectedDivisions = [];
        Object.keys(this.labels.divisiontype).forEach(key => {
          this.selectedDivisions.push(key);
        });
      }

      if (this.archiveYears.length <= 0) {
        for (let i = 0; i < 11; i++) {
          let year = Vue.moment('01-01-' + String(Number(Vue.moment().format('YYYY')) - i), 'DD-MM-YYYY').format('YYYY');
          this.archiveYears.push(year);
        }
      }

      if (this.selectedYears.length <= 0) {
        for (let i = 0; i < 11; i++) {
          let year = Vue.moment('01-01-' + String(Number(Vue.moment().format('YYYY')) - i), 'DD-MM-YYYY').format('YYYY');
          this.selectedYears.push(year);
        }
      }

      for (let month of this.$material.locale.months) {
        this.selectedMonths.push(month);
      }

      if (this.filterYears.length <= 0) {
        for (let i = 0; i <= 12; i++) {
          let year = Vue.moment('01-01-' + String(Number(Vue.moment().format('YYYY')) + 2 - i), 'DD-MM-YYYY').format('YYYY');
          this.filterYears.push(year);
          this.filterKigaYears[year] = '09/' + year + ' - 08/' + Number(Number(year) + Number(1));
        }
      }
    },

    onShowAllChildren() {
      this.resetChildFilter();
      if (!this.archiveActive) {
        this.selectedFilter = [];
        this.selectedGroups = [];
      } else {
        this.selectedYears = this.archiveYears;
        for (let month of this.$material.locale.months) {
          this.selectedMonths.push(month);
        }
      }

      this.selectedDivisions = ["HORT", "UNDEFINED", "KINDERGARTEN", "KRIPPE"];
      this.search = '';
    },

    resetChildFilter() {
      this.childFilterActiveCounter = 0;
      this.childFilter = JSON.parse(JSON.stringify(Child.child));
      if (this.selectedFacility && this.selectedFacility.facilitySettings && this.selectedFacility.facilitySettings.freeAttributes) {
        this.selectedFacility.facilitySettings.freeAttributes.forEach(attribute => {
          if (attribute.type === 'BOOLEAN') {
            attribute.booleanValue = false;
          }
        })
      }
      this.searchOnTable();
    },

    getAllReadableEmployees() {
      if (!this.isSage) {
        this.sending = true;
        EmployeeService.getReadableEmployees()
            .then(response => {
              this.$store.commit('availableOrganizationEmployees', response.data);
              this.sending = false;
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der lesbaren Mitarbeiter für Benutzer <b>' + this.user.firstName + ' ' + this.user.lastName + '</b>');
              this.sending = false;
            })
      }
    },

    getAllOrganizationGroups() {
      this.sending = true;
      GroupService.getAllOrganizationGroups()
          .then(response => {
            this.groups = response.data;
            this.sending = false;
            this.initData();
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Gruppen für Träger <b>' + this.organization.name + '</b>');
            this.sending = false;
          })
    },

    buildAvailableFacilities() {
      this.availableFacilities = this.getAvailableFacilities;

      if (!this.selectedFacilityReferenceNumber && this.availableFacilities.length > 0) {
        this.selectedFacilityReferenceNumber = this.availableFacilities[0].referenceNumber;
        this.selectedFacility = this.availableFacilities[0];
      }

      this.allowedFacilities = [];
      for (let facility of this.availableFacilities) {
        if (facility.referenceNumber !== '*') {
          this.allowedFacilities.push(facility);
        }
      }

      this.getAllOrganizationGroups();
    },

    reloadChildren() {
      let maxTries = process.env.VUE_APP_RELOAD_MAX_TRIES;
      const reloadIntervalId = setInterval(() => {
        if (this.dataAvailable && this.isAuthenticated) {
          if (this.isSage) {
            this.getAllChildren();
          } else if (!this.isAdmin) {
            if (!this.selectedFacilityReferenceNumber) {
              this.selectedFacilityReferenceNumber = this.user.allowedFacilities[0].referenceNumber;
              this.selectedFacility = this.availableFacilities[0];
            }
          }

          this.getAllReadableEmployees();
          this.buildAvailableFacilities();
          this.onSelectFacility();

          clearInterval(reloadIntervalId);
          jQuery('.md-content.md-table-content.md-scrollbar').attr('style', 'height: 675px');

          this.restoreDialogSelections();

        } else {
          this.sending = true;
          maxTries--;
          if (maxTries <= 0) {
            this.sending = false;
            clearInterval(reloadIntervalId);
            HttpErrorHandler.handleError(null, this, 'Fehler beim Laden von Benutzer / Organisation / Labels');
          }
        }
      }, 250);
    },

    getAllChildren() {
      this.sending = true;
      ChildService.getAllChildren()
          .then(response => {
            this.children = response.data;
            this.sending = false;
            this.searchOnTable();
            this.initMultiSelection();
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden aller Kinder');
            this.sending = false;
          })
    },

    getAllOrganizationChildren() {
      this.sending = true;
      ChildService.getAllOrganizationChildren()
          .then(response => {
            this.children = response.data;
            this.sending = false;
            this.searchOnTable();
            this.initMultiSelection();
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Kinder für Träger <b>' + this.organization.name + '</b>');
            this.sending = false;
          })
    },

    getAllArchivedOrganizationChildren() {
      this.sending = true;
      ChildService.getAllArchivedOrganizationChildren()
          .then(response => {
            this.archivedChildren = response.data;
            this.sending = false;
            this.searchOnTable();
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der archivierten Kinder für Träger <b>' + this.organization.name + '</b>');
            this.sending = false;
          })
    },

    getAllOrganizationFacilityChildren(referenceNumber) {
      if (!(this.organization && this.organization.facilities && this.organization.facilities.length > 0)) {
        return;
      }
      if (!referenceNumber || referenceNumber === 'null') {
        return;
      }
      this.sending = true;
      ChildService.getAllOrganizationFacilityChildren(referenceNumber)
          .then(response => {
            this.children = response.data;
            this.sending = false;
            this.searchOnTable();
            this.initMultiSelection();
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Kinder für Träger <b>' + this.organization.name + '</b> und Einrichtung mit RefNr <b>' + referenceNumber + '</b>');
            this.sending = false;
          })
    },

    getAllArchivedOrganizationFacilityChildren(referenceNumber) {
      if (!(this.organization && this.organization.facilities && this.organization.facilities.length > 0)) {
        return;
      }
      if (!referenceNumber || referenceNumber === 'null') {
        return;
      }
      this.sending = true;
      ChildService.getAllArchivedOrganizationFacilityChildren(referenceNumber)
          .then(response => {
            this.archivedChildren = response.data;
            this.sending = false;
            this.searchOnTable();
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der archivierten Kinder für Träger <b>' + this.organization.name + '</b> und Einrichtung mit RefNr <b>' + referenceNumber + '</b>');
            this.sending = false;
          })
    },

    updateChild() {
      if (this.selectedChild && !this.selectedChild.archived && this.canWrite) {
        this.sending = true;
        this.success = false;
        ChildService.updateChild(this.selectedChild)
            .then(response => {
              this.selected = response.data;
              this.selectedChild = this.completeChildData(response.data);
              this.success = true;
              this.sending = false;
              this.updateChildSuccess();
              this.exitChildrenOverview();
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Speichern der Änderungen für Kind '
                  + this.selectedChild.masterdata.firstname + ' ' + this.selectedChild.masterdata.lastname);
              this.sending = false;
            })
      }
    },

    updateChildSuccess(newChild) {
      this.confirmMissingChildDataDialog = false;
      this.confirmSaveChangedDataDialog = false;

      if (this.dialogMode === 'add') {
        this.newChild = JSON.parse(JSON.stringify(newChild));
        this.lastChild = this.newChild.masterdata.firstname + ' ' + this.newChild.masterdata.lastname;
        this.$store.commit('successMsg', 'Das neue Kind&nbsp;<b>' + this.lastChild + '</b>&nbsp;wurde erfolgreich hinzugefügt.');
        this.success = true;
        this.children.push(this.newChild);
        this.search = null;
        this.onShowAllChildren();
        this.selectedRowId = this.newChild.id;
        localStorage.setItem(this.user.md5 + '@selectedChildRowId', this.selectedRowId);
        this.updateChildren(this.newChild);
      }

      if (this.dialogMode === 'update') {
        this.lastChild = this.selectedChild.masterdata.firstname + ' ' + this.selectedChild.masterdata.lastname;
        this.$store.commit('successMsg', 'Die Änderungen für Kind&nbsp;<b>' + this.lastChild + '</b>&nbsp;wurden erfolgreich gespeichert!');
        this.success = true;
        this.editDataToggledByOverlay = true;
        jQuery('#editActiveSwitchId').parent().trigger('click');
        this.updateChildren(this.selectedChild);
      }

      this.success = true;
      if (this.$refs.childTabsForm) {
        this.$refs.childTabsForm.reset();
      }
    },

    updateSelectedChildren(updatedChildren) {
      updatedChildren.forEach(updatedChild => {
        this.updateChildInList(updatedChild);
      })

      setTimeout(() => jQuery('#multi-child-selection-switch-id').parent().click(), 250);
    },

    updateChildInList(updatedChild) {
      for (let i = 0; i < this.searched.length; i++) {
        if (updatedChild.id === this.searched[i].id) {
          this.searched[i] = JSON.parse(JSON.stringify(updatedChild));
        }
      }
      for (let i = 0; i < this.children.length; i++) {
        if (updatedChild.id === this.children[i].id) {
          this.children[i] = JSON.parse(JSON.stringify(updatedChild));
        }
      }
    },

    updateChildren(updatedChild) {
      this.updateChildInList(updatedChild);

      if (!this.presenceViewEnabled) {
        this.searchOnTable();
      }

      if (this.dialogMode === 'add') {
        this.selectChildId(updatedChild.id);
      } else {
        setTimeout(() => jQuery('#' + this.selectedRowId).trigger('click'), 250);
      }
    },

    selectChildId(id) {
      setTimeout(() => {
        jQuery('#' + id).trigger('click');
        let list = jQuery('#children-table-id').children().first().next().next();
        list.animate({scrollTop: document.getElementById(id).offsetTop}, 'slow');
      }, 10);
    },

    createNewChild() {
      this.newChild = JSON.parse(JSON.stringify(Child.child));
      this.newChild = this.completeChildData(this.newChild);
      this.newChild = JSON.parse(JSON.stringify(this.newChild));
      this.newChild.facilityReference = this.selectedFacilityReferenceNumber;
    },

    onAddFeeMultiChildSelectionDialog() {
      this.multiFee = JSON.parse(JSON.stringify(Fee.fee));
      this.createNewChild();
      this.dialogMode = 'add';
      setTimeout(() => this.showEditFeeDialog = true, 250);
    },

    onDeclareSiblingsMultiChildSelectionDialog() {
      this.areSiblings = true;
      this.showDeclareSiblingsDialog = true;
    },

    onDeclareSiblings() {
      if (this.selectedRows) {
        this.success = false;
        this.sending = true;
        let childIds = [];
        this.selectedRows.forEach(child => childIds.push(child.id));
        FacilityService.declareSiblings(childIds, this.areSiblings, null)
            .then((response) => {
              this.sending = false;
              this.success = true;
              this.showDeclareSiblingsDialog = false;

              if (this.areSiblings) {
                this.$store.commit('successMsg', this.selectedRows.length + ' ausgewählte Kinder wurden erfolgreich als Geschwister festgelegt.');
              } else {
                this.$store.commit('successMsg', 'Der Geschwisterstatus der ' + this.selectedRows.length + ' ausgewählten Kinder wurde erfolgreich aufgehoben.');
              }

              let updatedChildren = response.data;
              for (let child of updatedChildren) {
                if (this.children) {
                  for (let i = 0; i < this.children.length; i++) {
                    if (child.id === this.children[i].id) {
                      this.children[i] = JSON.parse(JSON.stringify(child));
                    }
                  }
                }
                this.searchOnTable();
              }
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Ändern des Geschwisterstatus für die ausgewählten Kinder.');
              this.sending = false;
            })
      }
    },

    onDeclareCoronaCompensationMultiChildSelectionDialog() {
      this.createNewChild();
      if (this.selectedRows && this.selectedRows.length > 0) {
        this.newChild.beitragsersatz = this.selectedRows[0].beitragsersatz;
        Object.keys(this.newChild.beitragsersatz).forEach(key => {
          this.newChild.beitragsersatz[key] = false;
        })
      }
      this.activateCoronaCompensation = null;
      this.showDeclareCoronaCompensationDialog = true;
      setTimeout(() => {
        if (this.$refs.childFormCompensation) {
          this.$refs.childFormCompensation.buildCompensationData();
          this.$refs.childFormCompensation.selectedCompensationYears = [];
        }
      }, 250);
    },

    hasCoronaCompensationOptionsSelected() {
      let result = false;
      if (this.newChild) {
        Object.keys(this.newChild.beitragsersatz).forEach(key => {
          if (this.newChild.beitragsersatz[key]) {
            result = true;
          }
        });
      }

      if (!result) {
        this.activateCoronaCompensation = null;
      }
      return result;
    },

    onDeclareCoronaCompensation(activate) {
      if (this.newChild) {
        let childIds = [];
        let compensation = {};
        this.selectedRows.forEach(child => childIds.push(child.id));

        Object.keys(this.newChild.beitragsersatz).forEach(key => {
          if (this.newChild.beitragsersatz[key]) {
            compensation[key] = activate;
          }
        });

        this.setCoronaCompensation({childIds: childIds, beitragsersatz: compensation})
      }
    },

    setCoronaCompensation(command) {
      this.success = false;
      this.sending = true;
      ChildService.setCoronaCompensation(command)
          .then((response) => {
            this.success = true;
            this.sending = false;
            this.$store.commit('successMsg', 'Die Änderungen für den Corona-Beitragsersatz wurden für &nbsp;<b>' + this.selectedRows.length + '</b>&nbsp; Kinder erfolgreich durchgeführt.');
            this.updateSelectedChildren(response.data);
            this.showDeclareCoronaCompensationDialog = false;
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Ändern des Corona-Beitragsersatz für &nbsp;<b>' + this.selectedRows.length + '</b>&nbsp; Kinder');
            this.sending = false;
          })
    },

    onAddChildDialog() {
      this.selected = null;
      this.selectedChild = null;
      this.editActive = false;
      this.editActiveSwitch = false;
      this.togglingAddMode = false;

      this.createNewChild();

      if (this.selectedFacilityReferenceNumber !== '*') {
        this.newChild.facilityReference = this.selectedFacilityReferenceNumber;
      }
      if (this.allowedFacilities.length === 1) {
        this.newChild.facilityReference = this.allowedFacilities[0].referenceNumber;
      }
      if (this.allowedFacilities.length <= 0) {
        this.newChild.facilityReference = '';
      }
      this.onToggleAddData(false);

      this.dialogMode = 'add';
      setTimeout(() => this.showEditChildDialog = true, 200);
      setTimeout(() => this.selectedRows = [], 250);
    },

    onChangeExitDateDialog() {
      if (this.selected) {
        this.lastChild = this.selectedChild.masterdata.firstname + ' ' + this.selectedChild.masterdata.lastname;
        this.changedExitDate = Vue.moment(this.selectedChild.exitDate, 'YYYY-MM-DD').format('DD.MM.YYYY');
        this.showChangeExitDateDialog = true;
        this.initDatePickerSyncHandler(true);
      } else {
        this.showComfortFunctionHint = true;
      }
    },

    onChangeExitDate() {
      if (this.selected) {
        this.success = false;
        this.sending = true;
        ChildService.changeExitDate(this.selected.id, Vue.moment(this.changedExitDate, 'DD.MM.YYYY').format('YYYY-MM-DD'))
            .then((response) => {
              this.selected = response.data;
              this.selectedChild = response.data;
              this.updateChildren(response.data);

              this.sending = false;
              this.$store.commit('successMsg', 'Das Austrittsdatum für Kind &nbsp;<b>' + this.lastChild + '</b>&nbsp;wurde erfolgreich geändert!');
              this.success = true;

              this.showChangeExitDateDialog = false;
              this.initDatePickerSyncHandler(false);
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Ändern des Austrittsdatums für Kind : '
                  + this.selectedChild.masterdata.firstname + ' ' + this.selectedChild.masterdata.lastname);
              this.sending = false;
            })
      }
    },

    onArchiveChildDialog() {
      this.confirmArchive = false;
      if (this.selected) {
        this.lastChild = this.selectedChild.masterdata.firstname + ' ' + this.selectedChild.masterdata.lastname;
        this.showArchiveChildDialog = true;
      } else {
        this.showComfortFunctionHint = true;
      }
    },

    onArchiveChild() {
      if (this.selected) {
        this.success = false;
        this.sending = true;
        ChildService.toggleArchiveState(this.selected.id)
            .then(() => {
              this.sending = false;
              if (this.archiveActive) {
                this.$store.commit('successMsg', 'Der Datensatz für Kind &nbsp;<b>' + this.lastChild + '</b>&nbsp;wurde erfolgreich wiederherstellt.');
              } else {
                this.$store.commit('successMsg', 'Der Datensatz für Kind &nbsp;<b>' + this.lastChild + '</b>&nbsp;wurde erfolgreich archiviert.');
              }
              this.success = true;
              this.search = null;
              this.selected = null;
              this.selectedChild = null;
              this.showArchiveChildDialog = false;
              this.reloadChildren();
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Archivieren des Datensatz für Kind : '
                  + this.selectedChild.masterdata.firstname + ' ' + this.selectedChild.masterdata.lastname);
              this.sending = false;
            })
      }
    },

    onDeleteChildDialog() {
      if (this.selected) {
        this.absolutelySure = false;
        this.lastChild = this.selectedChild.masterdata.firstname + ' ' + this.selectedChild.masterdata.lastname;
        this.showDeleteChildDialog = true;
      } else {
        this.showComfortFunctionHint = true;
      }
    },

    onDeleteChild() {
      if (this.selected) {
        this.success = false;
        this.sending = true;
        ChildService.deleteChild(this.selected.id)
            .then(() => {
              this.sending = false;
              this.$store.commit('successMsg', 'Der Datensatz für Kind &nbsp;<b>' + this.lastChild + '</b>&nbsp;wurde erfolgreich gelöscht.');
              this.success = true;
              this.search = null;
              this.selected = null;
              this.selectedChild = null;
              this.showDeleteChildDialog = false;
              this.reloadChildren();
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Löschen des Datensatz für Kind : '
                  + this.selectedChild.masterdata.firstname + ' ' + this.selectedChild.masterdata.lastname);
              this.sending = false;
            })
      }
    },

    onAddSiblingDialog() {
      if (this.selected) {
        this.initAvailableTargetFacilities();
        this.dialogMode = 'add';
        this.lastChild = this.selectedChild.masterdata.firstname + ' ' + this.selectedChild.masterdata.lastname;
        this.showAddSiblingDialog = true;
      } else {
        this.showComfortFunctionHint = true;
      }
    },

    onAddSibling() {
      if (this.selected) {
        this.success = false;
        this.sending = true;
        FacilityService.declareSiblings([this.selected.id], true, this.childTransferData.targetFacilityReference)
            .then(response => {
              this.sending = false;
              this.$store.commit('successMsg', 'Geschwister für Kind &nbsp;<b>' + this.lastChild + '</b>&nbsp;wurde erfolgreich angelegt!');
              this.success = true;
              this.search = null;
              this.selected = null;
              this.selectedChild = null;
              this.showAddSiblingDialog = false;
              this.updateChildSuccess(response.data[0]);
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Anlegen des Geschwister für Kind : <b>'
                  + this.selectedChild.masterdata.fullName + '</b>');
              this.sending = false;
            })
      }
    },

    initAvailableTargetFacilities() {
      this.targetFacilityType = null;
      this.childTransferData.targetFacilityReference = null;
      this.availableTargetFacilities = [];
      this.availableFacilities.forEach((facility) => {
        if (facility.referenceNumber !== '*') {
          this.availableTargetFacilities.push(facility);
        }
      });
    },

    onMoveToFacilityDialog() {
      if (this.selected) {
        this.initAvailableTargetFacilities();
        this.transferMoveDialogMode = true;
        this.showTransferChildDialog = true;
        this.initDatePickerSyncHandler(true);
      } else {
        this.showComfortFunctionHint = true;
      }
    },

    onTransferToFacilityDialog() {
      if (this.selected) {
        this.initAvailableTargetFacilities();
        this.transferMoveDialogMode = false;
        this.showTransferChildDialog = true;
        this.initDatePickerSyncHandler(true);
      } else {
        this.showComfortFunctionHint = true;
      }
    },

    setAvailableTargetFacilityTypes() {
      this.targetFacilityType = null;
      this.availableTargetFacilityTypes = [];
      if (this.childTransferData.targetFacilityReference) {
        let targetFacility = null;
        this.availableFacilities.forEach((facility) => {
          if (facility.referenceNumber === this.childTransferData.targetFacilityReference) {
            targetFacility = facility;
          }
        });

        if (targetFacility) {
          let restTypes = [];
          this.labels.facilityTypes.forEach((type) => {
            if (type !== 'HAUSFUERKINDER') {
              restTypes.push(type);
            }
          });

          this.labels.facilityTypes.forEach((type) => {
            if (type === targetFacility.facilityType) {
              if (type === 'HAUSFUERKINDER') {
                this.availableTargetFacilityTypes = restTypes;
              } else {
                this.availableTargetFacilityTypes.push(type);
              }
            }
          });

          if (this.availableTargetFacilityTypes.length === 1) {
            this.targetFacilityType = this.availableTargetFacilityTypes[0];
          }
        }
      }
    },

    onMoveToFacility() {
      let childMoveData = JSON.parse(JSON.stringify(this.childTransferData));
      childMoveData.sourceChildId = this.selectedChild.id;
      childMoveData.targetDivisionType = this.targetFacilityType;
      childMoveData.from = '';
      childMoveData.until = '';
      this.moveToFacility(childMoveData);
    },

    moveToFacility(moveInfo) {
      if (this.selected) {
        this.success = false;
        this.sending = true;
        FacilityService.moveToFacility(this.selected.facilityReference, moveInfo)
            .then(() => {
              this.sending = false;
              this.$store.commit('successMsg', 'Der Wechsel von Kind &nbsp;<b>' + this.selected.masterdata.fullName + '</b>&nbsp;wurde erfolgreich durchgeführt!');
              this.success = true;
              this.showTransferChildDialog = false;
              this.reloadChildren();
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Wechsel von Kind <b>' + this.selectedChild.masterdata.fullName + '</b>');
              this.sending = false;
            })
      }
    },

    onTransferToFacility() {
      let childTransferData = JSON.parse(JSON.stringify(this.childTransferData));
      childTransferData.sourceChildId = this.selectedChild.id;
      childTransferData.targetDivisionType = this.targetFacilityType;
      childTransferData.from = this.childTransferData.from;
      childTransferData.until = this.childTransferData.until;
      childTransferData.from = Vue.moment(childTransferData.from, 'DD.MM.YYYY').format('YYYY-MM-DD');
      childTransferData.until = Vue.moment(childTransferData.until, 'DD.MM.YYYY').format('YYYY-MM-DD');
      this.transferToFacility(childTransferData);
    },

    transferToFacility(transferInfo) {
      if (this.selected) {
        this.success = false;
        this.sending = true;
        FacilityService.transferToFacility(this.selected.facilityReference, transferInfo)
            .then(response => {
              this.sending = false;
              this.$store.commit('successMsg', 'Der Übertritt von Kind &nbsp;<b>' + this.selected.masterdata.fullName + '</b>&nbsp;wurde erfolgreich durchgeführt!');
              this.success = true;
              this.showTransferChildDialog = false;

              if (this.selectedChild.facilityReference === this.childTransferData.targetFacilityReference) {
                this.updateChildren(response.data);
              } else {
                this.dialogMode = 'add';
                this.updateChildSuccess(response.data);
              }
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Übertritt von Kind <b>' + this.selectedChild.masterdata.fullName + '</b>');
              this.sending = false;
            })
      }
    },

    getAggregatedChildFees(childId) {
      this.sending = true;
      ChildService.getAggregatedChildFees(childId)
          .then(response => {
            this.selectedChildAggregatedFees = response.data;
            this.sending = false;
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Gebühren für Kind mit Id <b>' + childId + '</b>');
            this.sending = false;
          })
    },

    maintainChildFilterActivityState(state) {
      if (state) {
        this.childFilterActiveCounter++;
      } else {
        this.childFilterActiveCounter--;
      }
      this.searchOnTable();
    },

    completeMissingInitialDataForChildren() {
      this.children.forEach((child, index) => {
        if (child.currentContract) {
          if (!child.currentContract.signingPerson) {
            this.children[index].currentContract.signingPerson = JSON.parse(JSON.stringify(Person.person));
          }
        }
      });
    },

    searchOnTable() {
      this.completeMissingInitialDataForChildren();

      if (this.multiChildSelection) {
        this.selectedRows = [];
      }

      if (this.archiveActive) {
        this.searched = this.archivedChildren;
      } else {
        this.searched = filterChildren(this.children, this.selectedFilter);
      }

      this.searched = searchByDivision(this.searched, this.selectedDivisions);
      if (!this.archiveActive) {
        this.searched = searchByGroup(this.searched, this.selectedGroupIds);
      }
      this.searched = searchByLastname(this.searched, this.search);

      this.searched = searchByFeature(this.searched, this.childFilter);

      if (this.selectedFacility && this.selectedFacility.facilitySettings) {
        this.searched = searchByFreeAttributes(this.searched, this.selectedFacility.facilitySettings.freeAttributes);
      }

      if (this.archiveActive) {
        this.searched = searchByExit(this.searched, this.selectedYears, this.selectedMonths);
      }

      this.resizeDialogElements();

      if (!this.initialTableSortDone && this.$refs.childrenTable) {
        this.initialTableSortDone = true;
        setTimeout(() => {
          if (this.$refs.childrenTable) {
            this.$refs.childrenTable.sortTable();
          }
        }, 50);
      }

      localStorage.setItem(this.user.md5 + '@selectedYears', JSON.stringify(this.selectedYears));

      this.preparePrintTableList();
      this.preparePrintTableFilter();

      setTimeout(() => jQuery('table').removeAttr('style'), 250);

      this.maintainMultiChildSelection();
      this.performLicenseCheck()
    },

    performLicenseCheck() {
      this.numberOfCurrentChildren = filterChildren(this.children, ['CURRENT']).length;
      sessionStorage.setItem('sose.licenseViolationReminder', this.licenseViolationRemindMeLater);
      if (!this.licenseViolationRemindMeLater) {
        this.showMaxNumberOfChildrenExceededHint = this.numberOfCurrentChildren > this.organizationContract.maxNumberOfChildren;
      }
    },

    resizeDialogElements() {
      if (document.getElementById('children-overview-id')) {
        let width = jQuery('.md-card.md-table').width();

        // Table header
        setTimeout(() => jQuery('.md-table-head-label').attr('style', 'padding: 0px 0px 0px 24px;'), 10);
        setTimeout(() => jQuery('table').width(width), 20);

        // Table rows
        setTimeout(() => jQuery('.md-table-cell-container').attr('style', 'padding: 6px 0px 6px 10px;'), 30);
        setTimeout(() => jQuery('.md-table-cell').attr('style', 'padding: 6px 0px 6px 0px;'), 40);
        setTimeout(() => jQuery('.md-content.md-table-content.md-scrollbar').width(width), 50);

        // Navigation
        jQuery('nav').hide().show();
      }
    },

    preparePrintTableList() {
      let list = [];

      let i = 1;
      for (let item of this.searched) {

        let groups = '';
        for (let groupReference of item.groupReferences) {
          for (let group of this.groups) {
            if (group.id.includes(groupReference)) {
              groups += group.name + ' ';
            }
          }
        }

        let firstMeaslesVaccinationDate = this.booleanText(true) + ' ' + Vue.moment(item.firstMeaslesVaccinationDate, 'YYYY-MM-DD').format('DD.MM.YYYY')
        if (firstMeaslesVaccinationDate.toLowerCase().includes('invalid date')) {
          firstMeaslesVaccinationDate = this.booleanText(false);
        }

        let secondMeaslesVaccinationDate = this.booleanText(true) + ' ' + Vue.moment(item.secondMeaslesVaccinationDate, 'YYYY-MM-DD').format('DD.MM.YYYY')
        if (secondMeaslesVaccinationDate.toLowerCase().includes('invalid date')) {
          secondMeaslesVaccinationDate = this.booleanText(false);
        }

        if (!item.contactMother) {
          item.contactMother = {
            businessPhone: null,
            email: null,
            mobilePhone: null,
            phone: null,
          };
        }

        if (!item.contactFather) {
          item.contactFather = {
            businessPhone: null,
            email: null,
            mobilePhone: null,
            phone: null,
          };
        }

        if (!item.contactMother2) {
          item.contactMother2 = {
            businessPhone: null,
            email: null,
            mobilePhone: null,
            phone: null,
          };
        }

        if (!item.contactFather2) {
          item.contactFather2 = {
            businessPhone: null,
            email: null,
            mobilePhone: null,
            phone: null,
          };
        }

        if (!item.currentContract) {
          item.currentContract = {
            contractReferenceNumber: null,
            signingPerson: {
              fullName: null,
            },
            paymentMethod: null,
            account: {
              accountOwner: null,
              bankname: null,
              iban: null,
              bic: null,
              mandateReference: null,
              dateOfMandateGranting: null,
            }
          };
        }

        let config1 = {
          '_Nr': i++,
          '_Geschlecht': this.sexIcon([item.masterdata.sex]),
          'Vorname': item.masterdata.firstname,
          'Nachname': item.masterdata.lastname,
          '_Geburtstag': item.masterdata.birthdate !== null ? Vue.moment(item.masterdata.birthdate, 'YYYY-MM-DD').format('DD.MM.YYYY') : '',
          'Alter': item.ageTextual,

          '>Adresse': true,
          '_Straße': item.masterdata.address.street,
          '_Hausnummer': item.masterdata.address.streetNumber,
          '_PLZ': item.masterdata.address.zipcode,
          '_Ort': item.masterdata.address.placeOfResidence,
        }

        let config2 = {
          '>Merkmale': true,
          '_Bereich': this.labels.divisiontype[item.divisiontype],
          'Gruppe(n)': groups,
          '_Förderkommune': item.promotionCommunityName,
          '_Eintritt': item.entryDate !== null ? Vue.moment(item.entryDate, 'YYYY-MM-DD').format('DD.MM.YYYY') : '',
          '_Austritt': item.exitDate !== null ? Vue.moment(item.exitDate, 'YYYY-MM-DD').format('DD.MM.YYYY') : '',
          '_kibigId': item.kibigId,
          '_kibigName': item.kibigName,
        }

        let config3 = {};
        let value = '';
        for (const emergencyPersonId of item.emergencyPersonIds) {
          item.relatedPersons.forEach(person => {
            if (person.id === emergencyPersonId) {
              let phoneNumbers = (person.contact.mobilePhone ? '&blacksquare; ' + person.contact.mobilePhone + ' ' : '') +
                  (person.contact.businessPhone ? '&blacksquare; ' + person.contact.businessPhone + ' ' : '') +
                  (person.contact.phone ? '&blacksquare; ' + person.contact.phone + ' ' : '');
              value += person.fullName + ':<br>' + phoneNumbers + '<br>';
            }
          });
        }
        config3 = {
          '>Notfall': true,
          'Notfall Nummern': value,
          'Alternative Nummer': item.emergencyPhonenumber,
        };

        let config4 = {
          '>Kinderarzt': true,
          '_Arzt Name': item.healthInformation.pediatrician.fullName,
          '_Arzt Tel (Praxis)': item.healthInformation.pediatrician.contact.businessPhone,
          '_Arzt Tel (mobil)': item.healthInformation.pediatrician.contact.mobilePhone,
          '_Arzt Tel (privat)': item.healthInformation.pediatrician.contact.phone,
          '_Arzt Straße': item.healthInformation.pediatrician.address.street,
          '_Arzt Hausnummer': item.healthInformation.pediatrician.address.streetNumber,
          '_Arzt PLZ': item.healthInformation.pediatrician.address.zipcode,
          '_Arzt Ort': item.healthInformation.pediatrician.address.placeOfResidence,

          '>Krankenversicherung': true,
          '_Versicherungnehmer': this.getPersonName(item, item.healthInformation.insuranceHolderPersonId),
          '_privat versichert': this.booleanText(item.healthInformation.privateInsurance),
          '_Versicherung Name': item.healthInformation.insuranceName,
          '_Versicherung Nummer': item.healthInformation.insuranceNumber,
          '_Versicherung Email': item.healthInformation.insuranceContact.email,
          '_Versicherung Telefon': item.healthInformation.insuranceContact.businessPhone,
        }

        let config5 = {};
        if (item.currentContract) {
          config5 = {
            '>Vertrag': true,
            '_Vertragsnummer': item.currentContract.contractReferenceNumber,
            '_Vertragspartner': item.currentContract.signingPerson.fullName,
            '_Zahlungsmethode': item.currentContract.paymentMethod,
            '>Bankverbindung': true,
            '_Kontoinhaber': item.currentContract.account.accountOwner,
            '_Name der Bank': item.currentContract.account.bankname,
            '_IBAN': item.currentContract.account.iban,
            '_BIC': item.currentContract.account.bic,
            '_Referenz-Mandat': item.currentContract.account.mandateReference,
            '_Datum Mandatserteilung': item.currentContract.account.dateOfMandateGranting !== null ? Vue.moment(item.currentContract.account.dateOfMandateGranting, 'YYYY-MM-DD').format('DD.MM.YYYY') : '',
          }
        }

        let config6 = {
          '>Kontaktpersonen': true,
          'Mutter (Mobil)': item.contactMother.mobilePhone,
          '_Mutter (Zuhause)': item.contactMother.phone,
          '_Mutter (Arbeit)': item.contactMother.businessPhone,
          '_Mutter (Email)': item.contactMother.email,

          'Vater (Mobil)': item.contactFather.mobilePhone,
          '_Vater (Zuhause)': item.contactFather.phone,
          '_Vater (Arbeit)': item.contactFather.businessPhone,
          '_Vater (Email)': item.contactFather.email,

          'Mutter 2 (Mobil)': item.contactMother2.mobilePhone,
          '_Mutter 2 (Zuhause)': item.contactMother2.phone,
          '_Mutter 2 (Arbeit)': item.contactMother2.businessPhone,
          '_Mutter 2 (Email)': item.contactMother2.email,

          'Vater 2 (Mobil)': item.contactFather2.mobilePhone,
          '_Vater 2 (Zuhause)': item.contactFather2.phone,
          '_Vater 2 (Arbeit)': item.contactFather2.businessPhone,
          '_Vater 2 (Email)': item.contactFather2.email,

          '>Zeiten': true,
          '_Bringzeit': item.bringzeit,
          '_Kommzeit': item.kommzeit,
          '_Gehzeit': item.gehzeit,

          '>Allgemein': true,
          '_Behinderung': this.booleanText(item.handicapped),
          '_Migrationshintergrund': this.booleanText(item.migrantBackground),
          '_Schweinefleisch': this.booleanText(item.nutritionInfo.porkMeatAllowed),
          '_Vorschulkind': this.booleanText(item.preschooler),
          '_Datenweitergabe': this.booleanText(item.shareInformationAllowed),
          '_Geschwister': this.booleanText(item.sibling),
          '_Jugendhilfe': this.booleanText(item.gettingWirtschaftlicheJugendhilfe),

          '>Gesundheit': true,
          '_Allergien': this.booleanText(item.nutritionInfo.allergic),
          '1. Masernimpfung': firstMeaslesVaccinationDate,
          '2. Masernimpfung': secondMeaslesVaccinationDate,
          '_Freistellung Masernimpfung': this.booleanText(item.exemptionMeaslesVaccination),
          '_Freistellung 2. Masernimpfung': this.booleanText(item.exemptionSecondMeaslesVaccination),

          '>Zustimmungen': true,
          '_Datenverarbeitung': this.booleanText(item.parentalConsents.dsgvoConfirmationObtained),
          '_Pflaster / Salben': this.booleanText(item.parentalConsents.applyPlasterSalveAllowed),
          '_Foto (intern)': this.booleanText(item.parentalConsents.beingPhotographedPrivatelyAllowed),
          '_Foto (öffentlich)': this.booleanText(item.parentalConsents.beingPhotographedPubliclyAllowed),
          '_Kontakt mit Tieren': this.booleanText(item.parentalConsents.contactToAnimalsAllowed),
          '_Schwimmbadbesuch': this.booleanText(item.parentalConsents.goSwimmingAllowed),
          '_Zeckenentfernung': this.booleanText(item.parentalConsents.removeTickAllowed),
          '_Beförderung in KFZ': this.booleanText(item.parentalConsents.ridingCarAllowed),
          '_Sonnencreme': this.booleanText(item.parentalConsents.applySuncreamAllowed),
        }

        const config7 = {'>Individuell': true};
        item.freeAttributes.forEach((attribute) => {
          const key = '_' + attribute.name;
          if (attribute.type === 'BOOLEAN') {
            config7[key] = this.booleanText(attribute.booleanValue);
          } else if (attribute.type === 'INTEGER') {
            config7[key] = attribute.intValue;
          } else if (attribute.type === 'DOUBLE') {
            config7[key] = attribute.doubleValue;
          } else if (attribute.type === 'STRING') {
            config7[key] = attribute.stringValue;
          }
        });

        let configAll = {
          ...config1,
          ...config2,
          ...config3,
          ...config4,
          ...config5,
          ...config6,
          ...config7,
        }

        list.push(configAll);
      }
      this.$store.commit('printTableList', list);
      this.$store.commit('printTableType', '');
    },

    booleanText(value) {
      return value ? '<span style="color: forestgreen">&#10003;</span>' : '<span style="color: red">&#10005;</span>';
    },

    getPersonName(child, personId) {
      let personName = '';
      child.relatedPersons.forEach(person => {
        if (person.id === personId) {
          personName = person.fullName;
        }
      });
      return personName;
    },

    sexIcon(value) {
      const sexIcons = {};
      sexIcons['MALE'] = '<span style="color: dodgerblue;font-size: 1rem;">&#9794;</span>';
      sexIcons['FEMALE'] = '<span style="color: hotpink;font-size: 1rem;">&#9792;</span>';
      sexIcons['DIVERSE'] = '<span style="color: darkviolet;font-size: 1rem;">&#9954;</span>';

      return (sexIcons[value]);
    },

    preparePrintTableFilter() {
      let filters = {};
      filters['divisions'] = this.selectedDivisions;

      let selectedGroupINames = [];
      for (const key of this.selectedGroups) {
        for (const group of this.groups) {
          if (group.key === key) {
            selectedGroupINames.push(group.name);
          }
        }
      }
      filters['groups'] = selectedGroupINames;

      let selectedFacilityNames = [];
      for (const facility of this.availableFacilities) {
        if (facility.referenceNumber === this.selectedFacilityReferenceNumber) {
          selectedFacilityNames.push(facility.name);
        }
      }
      filters['facilities'] = selectedFacilityNames;

      let selectedFilterNames = [];
      for (const id of this.selectedFilter) {
        for (const filter of this.filters) {
          if (filter.id === id) {
            selectedFilterNames.push(filter.name);
          }
        }
      }

      filters['filters'] = selectedFilterNames;

      this.$store.commit('printTableFilters', filters);
    },

    getClass: ({id, active}) => ({
      'md-primary': id === id,
      'inactive-child': active === false,
    }),

    resetStates() {
      this.success = false;
    },

    completeChildData(child) {
      if (child !== null) {
        for (let relatedPerson of child.relatedPersons) {
          if (relatedPerson === null) {
            relatedPerson = JSON.parse(JSON.stringify(Person.person));
          }
          if (relatedPerson.address === null) {
            relatedPerson.address = JSON.parse(JSON.stringify(Address.address));
          }
          if (relatedPerson.contact === null) {
            relatedPerson.contact = JSON.parse(JSON.stringify(Contact.contact));
          }
        }

        if (child.masterdata.address === null) {
          child.masterdata.address = JSON.parse(JSON.stringify(Address.address));
        }

        if (child.freeAttributes === null || (child.freeAttributes && child.freeAttributes.length <= 0)) {
          if (this.selectedFacility && this.selectedFacility.facilitySettings && this.selectedFacility.facilitySettings.freeAttributes) {
            child.freeAttributes = this.selectedFacility.facilitySettings.freeAttributes;
          }
        }

        // ToDo: Migration code to be removed on 31.12.2020
        child.relatedPersons.forEach((person) => {
          if (person.fullName === child.emergencyName) {
            let found = false;
            child.emergencyPersonIds.forEach((id) => {
              if (id === person.id) {
                found = true;
              }
            })
            if (!found) {
              child.emergencyPersonIds.push(person.id);
              child.emergencyName = '';
            }
          }
        });

        return this.resolveSiblingNames(child);
      }
    },

    resolveSiblingNames(child) {
      child.siblingNames = [];
      let siblingNamesString = '';
      if (child.sibling && child.siblings && child.siblings.length > 0) {
        let foundSiblings = 0;
        for (const sibId of child.siblings) {
          for (const kid of this.children) {
            if (sibId === kid.id) {
              if (siblingNamesString.length > 0) {
                siblingNamesString += ', ';
              }
              siblingNamesString += kid.masterdata.fullName;
              child.siblingNames.push(kid.masterdata.fullName);
              foundSiblings++;
            }
          }
          for (const kid of this.archivedChildren) {
            if (sibId === kid.id) {
              if (siblingNamesString.length > 0) {
                siblingNamesString += ', ';
              }
              siblingNamesString += kid.masterdata.fullName;
              child.siblingNames.push(kid.masterdata.fullName);
              foundSiblings++;
            }
          }
        }

        if (foundSiblings < child.siblings.length) {
          siblingNamesString += ' +' + (child.siblings.length - foundSiblings);
        }
      }

      child.siblingNamesString = siblingNamesString;
      return child;
    },

    onSelectArchiveYear() {
      this.searchOnTable();
    },

    onSelect(item) {
      if (item && item.id) {
        jQuery('#' + this.selectedRowId).removeClass('md-selected-single');

        this.sending = true;
        setTimeout(() => {
          jQuery('#childManagementTabsId').children('.md-content.md-tabs-content').attr('style', 'height: 100%');
          this.sending = false;
        }, 50);

        if (this.selectedChild) {
          this.selectedChild.relatedPersons = [];
        }
        this.selectedRowId = item.id;
        localStorage.setItem(this.user.md5 + '@selectedChildRowId', this.selectedRowId);

        this.dialogMode = 'update';
        this.selected = JSON.parse(JSON.stringify(item));
        this.selected = this.completeChildData(this.selected);

        setTimeout(() => {
          this.selectedChild = JSON.parse(JSON.stringify(this.selected));

          localStorage.setItem(this.user.md5 + '@selectedChild', JSON.stringify(this.selectedChild));

          this.options.url = '';
          setTimeout(() => {
            if (this.selected) {
              this.options.url = this.onVdropzoneUpload;
            }
          }, 250);
          this.selectedChildAggregatedFees = '';

          if (this.selectedChild.active && !this.presenceViewEnabled) {
            this.getAggregatedChildFees(this.selectedChild.id);
          }

          if (this.$refs.childTabsForm) {
            this.$refs.childTabsForm.selectedFirstTab();
          }

          for (let facility of this.availableFacilities) {
            if (facility.referenceNumber === this.selectedChild.facilityReference) {
              this.selectedChildFacility = facility;
            }
          }

          if (this.editGroups) {
            this.getAllFacilityGroups(this.selectedChild.facilityReference);
          }
        }, 5);
      } else {
        this.selected = null;
        this.selectedChild = null;
        if (this.selectedRowId) {
          if (localStorage.getItem(this.user.md5 + '@returnToDocumentationSelection')) {
            jQuery('#' + this.selectedRowId).trigger('click');
          } else {
            if (this.selectedRowId === localStorage.getItem(this.user.md5 + '@openChildFromUrl')) {
              jQuery('#' + this.selectedRowId).trigger('click');
            }
          }

          this.selectedRowId = 0;
        }
        localStorage.setItem(this.user.md5 + '@selectedChild', JSON.stringify(this.selectedChild));
      }

      this.maintainMultiChildSelection();
    },

    onSelectFacility() {
      if (this.yearFilterEnabled) {
        if (this.selectedFacilityReferenceNumber === '*') {
          this.selectedFacilityReferenceNumber = this.availableFacilities[1].referenceNumber;
        }
      }

      if (this.selectedFacilityReferenceNumber !== '' && this.selectedFacilityReferenceNumber !== 'undefined') {

        localStorage.setItem(this.user.md5 + '@selectedFacilityReferenceNumber', this.selectedFacilityReferenceNumber);

        this.selectedFacilityName = '';
        this.selectedFacility = null;
        for (let facility of this.availableFacilities) {
          if (facility.referenceNumber === this.selectedFacilityReferenceNumber) {
            this.selectedFacilityName = facility.name;
            this.selectedFacility = facility;
          }
        }

        if (this.editActive) {
          jQuery('#editActiveSwitchId').parent().trigger('click');
        }

        this.selected = null;
        this.selectedChild = null;

        if (this.yearFilterEnabled) {
          this.childTransferData.targetFacilityReference = this.selectedFacilityReferenceNumber;
          this.setAvailableTargetFacilityTypes();
          if (!this.availableTargetFacilityTypes.includes(this.filterSettings.divisionType)) {
            if (this.availableTargetFacilityTypes.length > 0) {
              this.filterSettings.divisionType = this.availableTargetFacilityTypes[0];
            } else {
              this.filterSettings.divisionType = '';
            }
          }
          this.getFilteredChildren();
        } else {
          if (this.selectedFacilityReferenceNumber === '*') {
            this.getAllOrganizationChildren();
            this.getAllArchivedOrganizationChildren();
          } else {
            this.getAllOrganizationFacilityChildren(this.selectedFacilityReferenceNumber);
            this.getAllArchivedOrganizationFacilityChildren(this.selectedFacilityReferenceNumber);
          }

          this.toggleMultiChildSelection();
        }
      }

      this.$store.commit('selectedFacilityReferenceNumber', this.selectedFacilityReferenceNumber);
    },

    onSelectDivision() {
      localStorage.setItem(this.user.md5 + '@selectedDivisions', JSON.stringify(this.selectedDivisions));
      this.searchOnTable();
    },

    onSelectGroup() {
      localStorage.setItem(this.user.md5 + '@selectedGroups', JSON.stringify(this.selectedGroups));
      this.determineSelectedGroupIds();
      this.searchOnTable();
    },

    onSelectFilter() {
      localStorage.setItem(this.user.md5 + '@selectedFilterChildren', JSON.stringify(this.selectedFilter));
      this.searchOnTable();
    },

    onToggleArchive() {
      if (!this.archiveActive) {

        if (this.multiChildSelection) {
          this.multiChildSelection = false;
          this.toggleMultiChildSelection();
        }
        if (this.presenceViewEnabled) {
          this.presenceViewEnabled = false;
          this.togglePresenceView();
        }
        if (this.yearFilterEnabled) {
          this.yearFilterEnabled = false;
          this.toggleYearFilter();
        }

      }
      this.onSelect(null);

      setTimeout(() => {
        this.archiveActive = !this.archiveActive;
        localStorage.setItem(this.user.md5 + '@archiveActive', this.archiveActive);
        this.searchOnTable();
      }, 300);
    },

    validateChild() {
      if (this.isDocumentation) {
        return;
      }
      if (this.dialogMode === 'update') {
        if (this.$refs.childTabsForm) {
          this.$refs.childTabsForm.validateChild();
        }

        if (!this.$refs.childTabsForm.invalid) {
          this.updateChild();
        } else {
          this.confirmMissingData();
        }
      }
    },

    confirmMissingData() {
      if (this.canWrite) {
        this.lastChild = this.selectedChild.masterdata.firstname + ' ' + this.selectedChild.masterdata.lastname;
        this.confirmMissingChildDataDialog = true;
      }
    },

    onTouchOverlay() {
      if (this.$refs && this.$refs.childTabsForm) {
        this.$refs.childTabsForm.$refs.childForm.validateChild();
      }

      setTimeout(() => {
        if (this.selectedChild && this.selectedChildMd5Hash !== Md5.hashAsciiStr(JSON.stringify(this.selectedChild))) {
          jQuery('span:contains("Stamm-Daten")').trigger('click');
          this.lastChild = this.selectedChild.masterdata.firstname + ' ' + this.selectedChild.masterdata.lastname;
          this.confirmSaveChangedDataDialog = true;
        } else {
          this.editDataToggledByOverlay = true;
          jQuery('#editActiveSwitchId').parent().trigger('click');
          this.onTabsChanged(this.activeTabId);
          this.exitChildrenOverview();
        }
      }, 250);
    },

    onSaveAndExitTo(route) {
      this.exitRoute = route;
      this.onTouchOverlay()
    },

    exitChildrenOverview() {
      if (this.exitRoute.length > 0) {
        this.$router.push(this.exitRoute);
      }
    },

    onCancelSaveChanges() {
      this.editDataToggledByOverlay = true;
      this.confirmSaveChangedDataDialog = false;
      if (this.$refs.childTabsForm) {
        this.$refs.childTabsForm.reset();
      }
      this.selectedChild = JSON.parse(JSON.stringify(this.selectedChildOriginal));
      jQuery('#editActiveSwitchId').parent().trigger('click');

      this.exitChildrenOverview();
    },

    onToggleEditData(isEditorActive) {
      if (!isEditorActive && !this.editDataToggledByOverlay) {
        this.editActiveSwitch = 'true';
        this.onTouchOverlay();
      } else {
        this.editDataToggledByOverlay = false;
        this.toggleEditData(isEditorActive);
      }
    },

    toggleEditData(isEditorActive) {
      if (!this.editActive && !this.togglingEditMode) {
        this.togglingEditMode = true;
        setTimeout(() => {
          this.togglingEditMode = false;
        }, 500);
      }

      if (isEditorActive) {
        setTimeout(() => {
          this.selectedChildMd5Hash = Md5.hashAsciiStr(JSON.stringify(this.selectedChild));
          this.selectedChildOriginal = JSON.parse(JSON.stringify(this.selectedChild));
        }, 250);
      }

      setTimeout(() => this.editActive = isEditorActive, 10);
      jQuery('#overlayLeft').toggleClass('md-overlay');
    },

    onToggleAddData(isEditorActive) {
      if (!this.editActive && !this.togglingAddMode) {
        this.togglingAddMode = true;
        setTimeout(() => {
          this.togglingAddMode = false;
        }, 1000);
      }
      setTimeout(() => this.editActive = isEditorActive, 10);
    },

    onVdropzoneUpload() {
      return '';
    },

    onVdropzoneFileAdded(file) {
      this.sending = true;
      this.$refs.childImageDropzone.removeAllFiles(true);
      this.convertImage(file);
    },

    convertImage(file) {
      heic2any({
        blob: file,
        toType: "image/jpeg",
        quality: 0.1,
      }).then((conversionResult) => {
        this.processImage(file, conversionResult, this);
      }).catch((e) => {
        console.log('convertImage error : ' + JSON.stringify(e));
        this.processImage(file, file, this);
      });
    },

    processImage(file, blob, instance) {
      let reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        let base64data = reader.result;
        imageProcessor
            .src(base64data)
            .pipe(
                resize({
                  maxWidth: 500,
                  maxHeight: 500,
                }),
                sharpen({
                  sharpness: 50 / 100,
                }),
            ).then(resultBase64 => {
          resultBase64 = resultBase64.substring(resultBase64.indexOf('base64,') + 7);
          const resultBlob = b64toBlob(resultBase64, 'image/jpeg');
          instance.uploadImage(instance.selected.id, file, resultBlob);
        });
      }
    },

    uploadImage(childId, file, blob) {
      this.sending = true;
      BinaryService.uploadChildImage(childId, file, blob)
          .then(response => {
            this.onVdropzoneSuccess(file, response.data);
          })
          .catch(e => {
            this.sending = false;
            HttpErrorHandler.handleError(e, this, 'Fehler beim Speichern des Bildes <b>' + file.upload.filename + '</b>');
          })
    },

    onVdropzoneClick() {
      HttpInterceptor.doRefreshToken();
      this.options.headers.Authorization = 'Bearer ' + sessionStorage.getItem('sose.access_token');
      this.success = false;
    },

    onVdropzoneProcessing() {
      HttpInterceptor.doRefreshToken();
      this.options.headers.Authorization = 'Bearer ' + sessionStorage.getItem('sose.access_token');
    },

    onVdropzoneError(file, message) {
      let errMsg = 'Fehler beim Laden der Datei: <b>' + file.upload.filename + '</b><br>';
      if (message) {
        errMsg += '<br><b>' + message + '</b>';
      }
      if (message.status) {
        errMsg += '<br><b>' + message.status + '</b>';
      }
      if (message.error) {
        errMsg += ' ' + message.error;
      }
      if (message.message) {
        errMsg += '<br>' + message.message;
      }
      HttpErrorHandler.handleError(null, this, errMsg);

      if (message.error === 'invalid_token') {
        HttpInterceptor.forceLogout();
      }
      this.$refs.childImageDropzone.removeAllFiles(true);
    },

    onVdropzoneSuccess(file, response) {
      this.$store.commit('successMsg', 'Die Datei <b>' + file.upload.filename + '</b> wurde erfolgreich hochgeladen.');
      setTimeout(() => {
        this.selected = JSON.parse(JSON.stringify(response));
        this.selected = this.completeChildData(this.selected);
        this.selectedChild = JSON.parse(JSON.stringify(this.selected));
        this.updateChildrenImageId(this.selectedChild);
        this.sending = false;
      }, 500);
    },

    deleteImageDialog() {
      if (this.selected) {
        this.lastChild = this.selectedChild.masterdata.firstname + ' ' + this.selectedChild.masterdata.lastname;
        this.showDeleteChildImageDialog = true;
      }
    },

    onDeleteChildImage() {
      if (this.selected) {
        this.success = false;
        this.sending = true;
        ChildService.deleteImageOfChild(this.selected.id)
            .then(response => {
              this.showDeleteChildImageDialog = false;
              this.sending = false;
              this.selected = JSON.parse(JSON.stringify(response.data));
              this.selected = this.completeChildData(this.selected);
              this.selectedChild = JSON.parse(JSON.stringify(this.selected));
              this.updateChildrenImageId(this.selectedChild);
              this.$store.commit('successMsg', 'Das Bild für Kind &nbsp;<b>' + this.lastChild + '</b>&nbsp;wurde erfolgreich gelöscht.');
              this.success = true;
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Löschen des Bildes von Kind : ' + this.lastChild);
              this.sending = false;
            })
      }
    },

    updateChildrenImageId(updatedChild) {
      for (let i = 0; i < this.searched.length; i++) {
        if (updatedChild.id === this.searched[i].id) {
          this.searched[i].imageId = updatedChild.imageId;
        }
      }
      for (let i = 0; i < this.children.length; i++) {
        if (updatedChild.id === this.children[i].id) {
          this.children[i].imageId = updatedChild.imageId;
        }
      }
      this.searchOnTable();
    },

    onTabsChanged(tabId) {
      this.activeTabId = tabId;
      setTimeout(() => jQuery('#childManagementTabsId').children('.md-content.md-tabs-content').attr('style', 'height: 100%'), 50);
      if (tabId === 'CHILD_BOOKING_TIMES') {
        this.$refs.bookingTimesView.resetIndices();
        this.$refs.bookingTimesView.onTodaysBookingMonth();
      }
    },

    onEditChildGroups() {
      this.getAllFacilityGroups(this.selectedChild.facilityReference);
    },

    getAllFacilityGroups(facilityReferenceNo) {
      this.sending = true;
      GroupService.getAllFacilityGroups(facilityReferenceNo)
          .then(response => {
            this.facilityGroups = response.data;
            this.sending = false;
            this.editGroups = true;
            this.currentEditGroupReferences = this.selectedChild.groupReferences;
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden aller Gruppen für Einrichtung mit Nummer <b>' + facilityReferenceNo + '</b>');
            this.sending = false;
          })
    },

    onUpdateChildGroups(selection) {
      selection.forEach((groupId) => {
        if (!this.currentEditGroupReferences.includes(groupId) && !this.sending) {
          this.assignChildToGroup(this.selectedChild, groupId);
        }
      });

      this.currentEditGroupReferences.forEach((groupId) => {
        if (!selection.includes(groupId) && !this.sending) {
          this.removeChildFromGroup(this.selectedChild, groupId);
        }
      });
    },

    assignChildToGroup(child, groupId) {
      if (child && groupId) {
        this.sending = true;
        GroupService.assignChildToGroup(child.id, groupId)
            .then(() => {
              this.sending = false;
              this.currentEditGroupReferences = this.selectedChild.groupReferences;
              this.updateChildren(this.selectedChild);
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Zuweisen von Kind <b>' + child.masterdata.fullName +
                  '</b> zur Gruppe mit Id <b>' + groupId + '</b>');
              this.sending = false;
            })
      }
    },

    removeChildFromGroup(child, groupId) {
      if (child && groupId) {
        this.sending = true;
        GroupService.removeChildFromGroup(child.id, groupId)
            .then(() => {
              this.sending = false;
              this.currentEditGroupReferences = this.selectedChild.groupReferences;
              this.updateChildren(this.selectedChild);
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Entfernen von Kind <b>' + child.masterdata.fullName +
                  '</b> aus Gruppe mit Id <b>' + groupId + '</b>');
              this.sending = false;
            })
      }
    },

    updateFeeSuccess() {
      this.reloadChildren();
    },


    toggleChildAbsence(child, date) {
      ChildService.toggleChildAbsence(child.id, date)
          .then((response) => {
            child.absenceStatusToday = response.data.absenceStatusToday;
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Ändern des Anwesenheit von Kind <b>' + child.masterdata.fullName + '</b>');
          })
    },

    onToggleChildAbsence(child, updateParent) {
      if (this.toggleAbsenceTimeViewAllowed) {
        if (this.$refs.absenceTimesView && this.selectedChild && this.selectedChild.id === child.id) {
          this.$refs.absenceTimesView.onToggleChildAbsence(child, Vue.moment().format('YYYY-MM-DD'), updateParent);
        } else {
          this.toggleChildAbsence(child, Vue.moment().format('YYYY-MM-DD'))
        }
      }
      this.toggleAbsenceTimeViewAllowed = true;
    },

    updateChildAbsence(child) {
      this.toggleAbsenceTimeViewAllowed = false;
      jQuery('#' + child.id).find('.md-checkbox-container').click();
    },

    isHereToday(child) {
      return child.absenceStatusToday.toString().startsWith('HERE');
    },

    facilityHasNoFreeAttributesCheckboxes(facility) {
      let result = true;
      facility.facilitySettings.freeAttributes.forEach(attribute => {
        if (attribute.type === 'BOOLEAN') {
          result = false;
        }
      })
      return result;
    },

  },

  computed: {

    childrenTableClass() {
      if (this.archiveActive) {
        return 'children-table-archive';
      } else {
        return 'children-table';
      }
    },

    fullViewClass() {
      if (this.archiveActive) {
        return 'archive-active';
      }
    },

    dataAvailable() {
      return this.organization && this.organization.facilities && this.user && this.user.allowedFacilities &&
          this.user.allowedFacilities.length > 0 && this.labels &&
          this.getAvailableFacilities && this.getAvailableFacilities.length > 0;
    },

    isAuthenticated() {
      return this.$store.getters.authenticated;
    },

    isAdmin() {
      return this.$store.getters.admin;
    },

    isFacilityAdmin() {
      return this.$store.getters.facilityAdmin;
    },

    isDeleted() {
      return this.$store.getters.deleted;
    },

    isTreasurer() {
      return this.$store.getters.treasurer
    },

    isFacility() {
      return this.$store.getters.facility
    },

    isDocumentation() {
      return this.$store.getters.documentation
    },

    isManagement() {
      return this.$store.getters.management
    },

    isSage() {
      return this.$store.getters.sage;
    },

    canWrite() {
      return this.$store.getters.canWrite;
    },

    user() {
      return this.$store.getters.user;
    },

    organization() {
      return this.$store.getters.organization;
    },

    organizationContract() {
      return this.$store.getters.organizationContract;
    },

    getAvailableFacilities() {
      return this.$store.getters.availableFacilities;
    },

    labels() {
      return this.$store.getters.labels
    },

    filters() {
      return this.$store.getters.filters
    },

    isDevEnv() {
      return process.env.NODE_ENV === 'development';
    },

    childSelectionMode() {
      if (this.multiChildSelection) {
        return 'multiple';
      } else {
        return 'single';
      }
    },
  }
}
</script>

<style lang="scss" scoped>

.md-icon-button {
  width: 40px;
  min-width: 40px;
  height: 40px;
  margin: 6px 6px 0 0;
}

.md-dialog /deep/ .md-dialog-container {
  max-width: 800px;
}

.md-dialog.create-sibling-dialog /deep/ .md-dialog-container {
  max-width: 700px !important;
}

.md-dialog.delete-child-dialog /deep/ .md-dialog-container {
  max-width: 700px !important;
}

.md-dialog.change-exit-date-dialog /deep/ .md-dialog-container {
  max-width: 700px !important;
}

.md-dialog.delete-child-image-dialog /deep/ .md-dialog-container {
  max-width: 700px !important;
}

.md-dialog.show-comfort-function-dialog /deep/ .md-dialog-container {
  max-width: 600px !important;
}

.md-dialog.declare-corona-compensation-dialog /deep/ .md-dialog-container {
  max-width: 700px !important;
}

.md-dialog.confirm-incomplete-child-data-dialog /deep/ .md-dialog-container {
  max-width: 700px !important;
}

.children-table {
  height: 1050px;
  overflow-x: hidden;
}

.children-table-archive {
  height: 1047px;
  overflow-x: hidden;
}

.md-button-icon {
  font-size: 20px !important;
  height: 15px;
  color: black !important;
}

h5 {
  font-weight: 400;
  color: lightseagreen;
}

hr {
  margin-top: 8px;
  margin-bottom: 8px;
}

.md-toolbar .md-tabs {
  padding-left: 0px;
}

.md-tab {
  padding-left: 8px;
  padding-right: 8px;
  min-width: 200px;
}

.md-toolbar.md-theme-default.md-transparent .md-title {
  color: cornflowerblue;
  margin-left: 0;
}

.default-image {
  width: 155px;
  height: 155px;
  border-radius: 10px;
  object-fit: cover;
  opacity: 0.15;
}

.md-tooltip {
  font-size: medium;
  height: auto;
  white-space: normal;
}

.md-checkbox {
  margin: 0 0 0 16px;
}

.md-switch .md-switch-label {
  padding-left: 8px;
}

</style>

<style>
.dropzone {
  padding: 0;
  width: 155px;
  height: 155px;
}

.dropzone .dz-message {
  text-align: center;
  margin: 1.5em 1em;
}

.dropzone .dz-preview {
  margin: 0;
}

.dropzone .dz-preview .dz-progress {
  top: 85%;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 22px;
}

.inactive-child {
  color: gray;
  font-style: italic;
}

.flash-orange-red {
  color: orangered !important;
  opacity: 1 !important;
  animation-duration: 2s;
  animation-delay: 1s;
  animation-name: flashActive;
  animation-iteration-count: 100;
}

@keyframes flashActive {
  0% {
    font-size: 14px !important;
    opacity: 1;
  }

  50% {
    font-size: 24px !important;
    opacity: 0;
  }

  100% {
    font-size: 14px !important;
    opacity: 1;
  }
}
</style>

import PriceList from "../entities/PriceList"

export default {

  name: 'Fee',

  fee: {
    active: true,
    amount: 0,
    baseAmount: 0,
    baseAmountNoDiscount: 0,
    discountAbsolute: 0,
    discountPercentage: 0,
    feeType: '',
    interval: 'MONTHLY',
    intervalStartMonth: 0,
    lastBilledDate: null,
    monthlyAmount: 0,
    monthlyTotalDiscountAmount: 0,
    name: '',
    pricelist: PriceList.priceList,
    refNr: 0,
    signedAmount: 350,
    signedMonthlyAmount: 350,
    taxRelevant: false,
    totalDiscountAmount: 0,
    usingPriceList: false,
    validFrom: null,
    validUntil: null,
  }
}
<template>
  <div v-if="child" class="tab-content">
    <br>
    <div class="grid-x grid-padding-x grid-margin-x">
      <div class="cell small-10">
        <p class="md-title ai-number" style="font-size: 20px">{{documentation.length}}
          &nbsp;Dokumentation<span v-if="documentation.length !== 1">en</span> für&nbsp;
          <b>{{child.masterdata.fullName}}</b>&nbsp;
        </p>
        <br>
        <br>
      </div>
      <div class="cell small-2 text-center">
        <vue-simple-spinner v-if="sending" style="margin-top: -6px"></vue-simple-spinner>
      </div>
    </div>

    <md-tabs md-dynamic-height>
      <template slot="md-tab" slot-scope="{ tab }">
        <DocumentationIconView :type="tab.data.type"/>
        <span class="md-tab-label" style="margin-top: 10px;">{{ tab.label }} <i class="badge" v-if="tab.data.badge">{{ tab.data.badge }}</i></span>
        <md-tooltip v-if="tab.data.tooltip" md-direction="bottom">{{ tab.data.tooltip }}</md-tooltip>
      </template>
      <md-tab id="tab-notes" style="padding: 1px" md-label="Notizen" md-icon="edit"
              :md-template-data="{type: 'NOTE', badge: documentationTypeCount['NOTE']}">
        <DocumentationListView :documentation="documentation" :child="child" type="NOTE" :sending="sending"
                               @updateDocumentation="updateDocumentation"/>
      </md-tab>
      <md-tab id="tab-watch" style="padding: 1px" md-label="Beobachtungen" md-icon="."
              :md-template-data="{type: 'CHILD_OBSERVATION', badge: documentationTypeCount['CHILD_OBSERVATION']}">
        <DocumentationListView :documentation="documentation" :child="child" type="CHILD_OBSERVATION" :sending="sending"
                               @updateDocumentation="updateDocumentation"/>
      </md-tab>
      <md-tab id="tab-parents" style="padding: 1px" md-label="Eltern" md-icon="."
              :md-template-data="{type: 'PARENTS_TALK', badge: documentationTypeCount['PARENTS_TALK']}">
        <DocumentationListView :documentation="documentation" :child="child" type="PARENTS_TALK" :sending="sending"
                               @updateDocumentation="updateDocumentation"/>
      </md-tab>
      <md-tab id="tab-therapy" style="padding: 1px" md-label="Therapeuten" md-icon="."
              :md-template-data="{type: 'THERAPIST_TALK', badge: documentationTypeCount['THERAPIST_TALK']}">
        <DocumentationListView :documentation="documentation" :child="child" type="THERAPIST_TALK" :sending="sending"
                               @updateDocumentation="updateDocumentation"/>
      </md-tab>
      <md-tab id="tab-perik" style="padding: 1px" md-label="perik" md-icon="."
              :md-template-data="{type: 'QUESTIONNAIRE_PERIK', badge: documentationTypeCount['QUESTIONNAIRE_PERIK'], tooltip: 'Positive Entwicklung und Resilienz im Kindergartenalltag'}">
        <DocumentationListView :documentation="documentation" :child="child" type="QUESTIONNAIRE_PERIK"
                               :sending="sending" @updateDocumentation="updateDocumentation"/>
      </md-tab>
      <md-tab id="tab-sismik-seldak" style="padding: 1px" :md-label="tabLabel(child.migrantBackground)" md-icon="."
              :md-template-data="{type: tabType(child.migrantBackground), badge: tabBadge(child.migrantBackground), tooltip: tabTooltip(child.migrantBackground)}">
        <DocumentationListView v-if="child.migrantBackground"
                               :documentation="documentation" :child="child" type="QUESTIONNAIRE_SISMIK"
                               :sending="sending" @updateDocumentation="updateDocumentation"/>
        <DocumentationListView v-else
                               :documentation="documentation" :child="child" type="QUESTIONNAIRE_SELDAK"
                               :sending="sending" @updateDocumentation="updateDocumentation"/>
      </md-tab>
    </md-tabs>
  </div>
</template>

<script>
  import jQuery from 'jquery';
  import Spinner from 'vue-simple-spinner';
  import DocumentationService from '../../services/DocumentationService';
  import HttpErrorHandler from '../../services/HttpErrorHandler';

  import DocumentationListView from "./DocumentationListView";
  import DocumentationIconView from "./DocumentationIconView";

  export default {
    name: 'DocumentationView',

    components: {
      DocumentationListView,
      DocumentationIconView,
      'vue-simple-spinner': Spinner
    },

    props: {
      child: {
        required: true
      },
      isVisible: {
        required: true,
      }
    },

    watch: {
      child() {
        this.getDocumentation();
      },

      isVisible() {
        this.getDocumentation();
      },
    },

    data() {
      return {
        documentation: [],
        documentationChildId: 0,
        documentationTypeCount: {},

        sending: false,
        selectedDocumentation: null,
      }
    },

    methods: {

      tabLabel(migration) {
        return this.labels.documentationType[this.tabType(migration)];
      },

      tabTooltip(migration) {
        if (migration) {
          return 'Sprachverhalten und Interesse an Sprache bei Migrantenkindern in Kindertageseinrichtungen';
        } else {
          return 'Sprachentwicklung und Literacy bei deutschsprachig aufwachsenden Kindern';
        }
      },

      tabType(migration) {
        if (migration) {
          return 'QUESTIONNAIRE_SISMIK';
        } else {
          return 'QUESTIONNAIRE_SELDAK';
        }
      },

      tabBadge(migration) {
        if (migration) {
          return this.documentationTypeCount['QUESTIONNAIRE_SISMIK'];
        } else {
          return this.documentationTypeCount['QUESTIONNAIRE_SELDAK'];
        }
      },

      restoreDialogSelections() {
        if (localStorage.getItem(this.user.md5 + '@returnToDocumentationSelection')) {
          localStorage.removeItem(this.user.md5 + '@returnToDocumentationSelection');

          if (localStorage.getItem(this.user.md5 + '@selectedDocumentation')) {
            this.selectedDocumentation = JSON.parse(localStorage.getItem(this.user.md5 + '@selectedDocumentation'));
          }

          if (this.selectedDocumentation !== null) {
            setTimeout(() => {
              let tabLabel = this.labels.documentationType[this.selectedDocumentation.type];
              if (this.selectedDocumentation.type === 'PARENTS_TALK') {
                tabLabel = 'Eltern';
              }
              if (this.selectedDocumentation.type === 'THERAPIST_TALK') {
                tabLabel = 'Therapeuten';
              }
              let tab = jQuery('span:contains(' + tabLabel + ')');

              if (tab) {
                tab.trigger('click');
              }
              let selected = jQuery('#' + this.selectedDocumentation.id);
              if (selected) {
                selected.trigger('click');
              }
            }, 50);
          }
        }
      },

      updateDocumentation() {
        this.getDocumentation4Child(this.child.id)
      },

      getDocumentation() {
        if (this.documentationChildId !== this.child.id && this.isVisible) {
          this.documentation = [];
          this.documentationChildId = this.child.id;
          this.getDocumentation4Child(this.child.id)
        }
      },

      getDocumentation4Child(childId) {
        this.sending = true;
        DocumentationService.getDocumenation(childId)
          .then(response => {
            this.documentation = response.data;
            this.sending = false;
            this.determineDocumentTypeCount();
            this.restoreDialogSelections();
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Dokumentation für Kind mit Id ' + childId);
            this.sending = false;
          })
      },

      determineDocumentTypeCount() {
        this.documentationTypeCount = {};
        for (let doc of this.documentation) {
          if (!this.documentationTypeCount[doc.type]) {
            this.documentationTypeCount[doc.type] = 1;
          } else {
            this.documentationTypeCount[doc.type] += 1;
          }
        }
      }

    },

    computed: {
      user() {
        return this.$store.getters.user;
      },

      labels() {
        return this.$store.getters.labels
      },
    }
  }
</script>

<style lang="scss" scoped>
  .tab-content {
    padding: 0 8px 0 8px;
  }

  .md-tooltip {
    font-size: medium;
    white-space: normal;
  }

  .badge {
    width: 19px;
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 2px;
    right: 2px;
    background: green;
    border-radius: 100%;
    color: #fff;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -.05em;
  }
</style>
<template>
  <div v-if="contract" class="grid-x grid-padding-x">
    <div class="cell large-6 medium-6 small-12">
      <md-field :class="getValidationClass('contractReferenceNumber')">
        <label for="contractReferenceNumber">Vertragsnummer</label>
        <md-input name="contractReferenceNumber" id="contractReferenceNumber" autocomplete="off"
                  v-model="contract.contractReferenceNumber" :disabled="sending"/>
        <span class="md-error"
              v-if="!($v.contract.contractReferenceNumber.required)">Vertragsnummer ist notwendig</span>
      </md-field>
    </div>
    <div class="cell large-6 medium-6 small-12">
      <md-field v-if="contract">
        <label>Zahlungsmethode</label>
        <md-select v-model="contract.paymentMethod" autocomplete="off" :disabled="sending">
          <md-option v-for="method of labels.paymentMethods" v-bind:value="method" :key="method">
            {{ labels.paymentMethod[method] }}
          </md-option>
          <span class="md-error" v-if="!($v.contract.paymentMethod.required)">Zahlungsmethode ist notwendig</span>
        </md-select>
      </md-field>
    </div>

    <div class="cell small-12 medium-12 large-12">
      <div class="md-layout">
        <div class="md-layout-item" style="padding-right: 15px;">
          <md-datepicker id="valid-from-date-dp" :md-debounce="10000" :md-open-on-focus="false" v-model="validFromDate"
                         md-immediately :disabled="sending"
                         :class="getValidationClass('validFrom')">
            <label>Gültig von</label>
            <span class="md-error" v-if="!($v.contract.validFrom.required)">Gültig von Datum ist notwendig</span>
          </md-datepicker>
        </div>
        <div class="md-layout-item" style="padding-left: 15px;">
          <md-datepicker id="valid-until-date-dp" :md-debounce="10000" :md-open-on-focus="false"
                         v-model="validUntilDate" md-immediately :disabled="sending"
                         :class="getValidationClass('validUntil')" @md-opened="onOpenValidUntilDatePicker">
            <label>Gültig bis</label>
            <span class="md-error" v-if="!($v.contract.validUntil.required)">Gültig bis Datum ist notwendig</span>
          </md-datepicker>
        </div>
      </div>
    </div>

    <div v-if="mode === 'update'" class="cell small-12 medium-12 large-12">
      <div class="grid-x grid-padding-y" style="padding-top: 16px;">
        <div class="cell small-6">
          <h5>Vertragsdokumente
            <span v-if="contract && contract.signingPerson && contract.signingPerson.fullName"
                  class="hide-for-small-only">für <b>{{ labels.salutation[contract.signingPerson.salutation] }} {{ contract.signingPerson.fullName }}</b></span>
          </h5>
        </div>
        <div class="cell small-6">
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>
      </div>
      <div class="grid-x grid-padding-y">
        <div class="cell large-7 medium-12 small-12">
          <md-chip v-if="contract.templateDocumentAvailable" class="md-primary">
            <span>{{ contract.contractFileName }}</span>
          </md-chip>
          <md-chip v-else md-disabled>
            <i>Kein Dokument erzeugt.</i>
          </md-chip>
        </div>
        <div class="cell large-5 medium-12 small-12">
          <div class="grid-x grid-padding-y">
            <div class="cell small-8">
              <div>
                <button :disabled="contract.templateDocumentAvailable" class="button success" type="button" @click="onCreateContractTemplateDocument()">Dokument erzeugen
                  <md-tooltip>Hier können Sie ein neues Vertragsdokument basierend auf der aktiven Vertrags-Druckvorlage erzeugen.</md-tooltip>
                </button>
              </div>
            </div>
            <div class="cell small-4">
              <md-button class="md-icon-button md-raised md-accent"
                         @click="onDeleteContractTemplateDocument()"
                         :disabled="!contract.templateDocumentAvailable || sending || !(isAdmin || isFacilityAdmin || isTreasurer || isManagement) || !canWrite">
                <md-icon>delete</md-icon>
                <md-tooltip>Erzeugtes Vertragsdokument löschen</md-tooltip>
              </md-button>
              <md-button class="md-icon-button md-raised md-third"
                         @click="onDownloadContractTemplateDocument()"
                         :disabled="!contract.templateDocumentAvailable || sending || !(isAdmin || isFacilityAdmin || isTreasurer || isManagement) || !canWrite">
                <md-icon>archive</md-icon>
                <md-tooltip>Erzeugtes Vertragsdokument <b>{{trimString(contract.contractFileName)}}</b> herunterladen</md-tooltip>
              </md-button>
            </div>
          </div>
        </div>
      </div>
      <div class="grid-x grid-padding-y">
        <div class="cell large-7 medium-12 small-12">
          <md-chip v-if="contract.documentAvailable" class="md-primary">
            <span>{{ contract.documentFileName }}</span>
          </md-chip>
          <md-chip v-else md-disabled>
            <i>Kein Dokument hinterlegt.</i>
          </md-chip>
        </div>
        <div class="cell large-5 medium-12 small-12">
          <div class="grid-x grid-padding-y">
            <div class="cell small-8">
              <div v-if="contract.documentAvailable">
                <button disabled class="button secondary" type="button">Dokument hochladen
                </button>
              </div>
              <div v-else @click="onVdropzoneClick()">
                <vue-dropzone
                    :disabled="sending || sending || !(isAdmin || isFacilityAdmin || isTreasurer || isManagement) || !canWrite || contract.documentAvailable"
                    :options="optionsContractDocument"
                    @vdropzone-error="onVdropzoneError"
                    @vdropzone-mounted="onVdropzoneMounted"
                    @vdropzone-processing="onVdropzoneProcessing"
                    @vdropzone-success="onVdropzoneSuccess"
                    id="contractDocumentFileDropzone"
                    ref="vueDropzoneRef"
                    v-if="optionsContractDocument.url">
                </vue-dropzone>
                <md-tooltip>Hier können Sie ein Dokument von Ihrem Computer auswählen und für diesen Vetrag hinterlegen.
                </md-tooltip>
              </div>
            </div>
            <div class="cell small-4">
              <md-button class="md-icon-button md-raised md-accent" @click="onDeleteContractDocument()"
                         :disabled="!contract.documentAvailable || sending || !(isAdmin || isFacilityAdmin || isTreasurer || isManagement) || !canWrite">
                <md-icon>delete</md-icon>
                <md-tooltip>Hochgeladenes Dokument löschen</md-tooltip>
              </md-button>
              <md-button class="md-icon-button md-raised md-forth" @click="onDownloadContractDocument()"
                         :disabled="!contract.documentAvailable || sending || !(isAdmin || isFacilityAdmin || isTreasurer || isManagement) || !canWrite">
                <md-icon>archive</md-icon>
                <md-tooltip>basierend auf der aktiven Druckvorlage <b>{{contract.templateFileName}}</b></md-tooltip>
              </md-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDeleteContractDocumentDialog">
      <div class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title>
          <i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Vertragsdokument <span
            class="title">{{ contract.referenceNumber }}</span> löschen
        </md-dialog-title>
        <div class="cell text-center">
          <md-content>Soll das Vertragsdokument <br><br><span class="title"><b>{{ contract.documentFileName }}</b></span><br><br>
            wirklich gelöscht werden?
          </md-content>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
          <br>
        </div>
        <div class="cell text-center">
          <button :disabled="sending" @click="deleteContractDocument()" class="button alert"><i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Ja,
            Vertragsdokument jetzt löschen
          </button>
          <button :disabled="sending" @click="showDeleteContractDocumentDialog = false" class="button success"
                  style="margin-left: 1rem;" type="button">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button @click="showDeleteContractDocumentDialog = false" class="close-button" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDeleteContractTemplateDocumentDialog">
      <div class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title>
          <i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Erzeugtes Vertragsdokument löschen
        </md-dialog-title>
        <div class="cell text-center">
          <md-content>Soll das erzeugte Vertragsdokument <br><br><span class="title"><b>{{ contract.contractFileName }}</b></span><br><br>
            wirklich gelöscht werden?
          </md-content>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
          <br>
        </div>
        <div class="cell text-center">
          <button :disabled="sending" @click="deleteContractTemplateDocument()" class="button alert"><i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Ja,
            erzeugtes Vertragsdokument jetzt löschen
          </button>
          <button :disabled="sending" @click="showDeleteContractTemplateDocumentDialog = false" class="button success"
                  style="margin-left: 1rem;" type="button">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button @click="showDeleteContractTemplateDocumentDialog = false" class="close-button" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showCreateContractPdfDialog">
      <div class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title>
          <i class="fi-page-pdf"></i>&nbsp;&nbsp;&nbsp;Vertragsdokument <span
            class="title">{{ contract.referenceNumber }}</span> erzeugen
        </md-dialog-title>
        <div class="cell">
          <md-content>Soll das Vertragsdokument für diesen Vertrag basierend auf der aktiven Vertrags-Druckvorlage wirklich neu erzeugt werden?
            <br>Sollte ein vorher bereits erzeugtes Vertragsdokument bestehen, so wird dieses dadurch ersetzt!
          </md-content>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
          <br>
        </div>
        <div class="cell text-center">
          <button :disabled="sending" @click="createContractPdf()" class="button"><i class="fi-page-pdf"></i>&nbsp;&nbsp;&nbsp;Ja,
            Vertragsdokument jetzt neu erzeugen
          </button>
          <button :disabled="sending" @click="showCreateContractPdfDialog = false" class="button success"
                  style="margin-left: 1rem;" type="button">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button @click="showCreateContractPdfDialog = false" class="close-button" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>
  </div>
</template>

<script>

import jQuery from 'jquery';
import {validationMixin} from 'vuelidate';
import vueDropzone from 'vue2-dropzone';
import Vue from 'vue';
import Spinner from 'vue-simple-spinner';
import {
  required,
} from 'vuelidate/lib/validators';
import HttpInterceptor from "@/services/HttpInterceptor";
import HttpErrorHandler from "@/services/HttpErrorHandler";
import BinaryService from "@/services/BinaryService";
import ChildService from "@/services/ChildService";

export default {
  name: 'ContactForm',
  components: {vueDropzone, 'vue-simple-spinner': Spinner},
  mixins: [validationMixin],

  props: {
    contract: {
      required: true
    },
    child: {
      required: true
    },
    mode: {
      required: true
    }
  },

  data: () => ({
    invalid: true,

    validFromDate: null,
    validUntilDate: null,

    sending: false,

    downloadUrl: null,
    downloadLink: null,
    downloadBlob: null,

    optionsContractDocument: {
      url: '',
      maxFilesize: 20.0, // 20480 KB
      headers: {
        'accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ' + sessionStorage.getItem('sose.access_token')
      },
      createImageThumbnails: false,
      thumbnailWidth: 250,
      thumbnailHeight: 50,
      previewTemplate: '<div class="dz-preview dz-file-preview"></div>',
      dictDefaultMessage: 'Dokument hochladen',
      acceptedFiles: '',
    },

    uploadFileName: null,
    initialContractDocumentLoadSuccess: false,
    initialContractDocumentLoadFailure: false,

    showDeleteContractDocumentDialog: false,
    showDeleteContractTemplateDocumentDialog: false,
    showCreateContractPdfDialog: false,
  }),

  created() {
    this.buildUploadUrl();
    this.formatValidFromDate();
    this.formatValidUntilDate();
    this.initDatePickerSyncHandler(true);
  },

  beforeDestroy() {
    this.initDatePickerSyncHandler(false);
    this.cleanDownloads();
  },

  validations: {
    contract: {
      contractReferenceNumber: {
        required,
      },
      paymentMethod: {
        required,
      },
      validFrom: {
        required,
      },
      validUntil: {
        required,
      },
    },
  },

  methods: {

    trimString(name) {
      return name ? name.replaceAll('_', ' ') : '';
    },

    initDatePickerSyncHandler(init) {
      if (init) {
        setTimeout(() => {
          jQuery('#valid-from-date-dp').children('input').bind('blur', () => {
            this.validFromDate = jQuery('#valid-from-date-dp').children('input').val();
            this.writeBackDates();
          });
          jQuery('#valid-until-date-dp').children('input').bind('blur', () => {
            this.validUntilDate = jQuery('#valid-until-date-dp').children('input').val();
            this.writeBackDates();
          });
        }, 500);
      } else {
        jQuery('#valid-from-date-dp').children('input').unbind('blur');
        jQuery('#valid-until-date-dp').children('input').unbind('blur');
      }
    },

    formatValidFromDate() {
      if (this.validFromDate === null) {
        if (this.contract && this.contract.validFrom) {
          this.validFromDate = Vue.moment(this.contract.validFrom).format('DD.MM.YYYY');
        }
      }
    },

    formatValidUntilDate() {
      if (this.validUntilDate === null) {
        if (this.contract && this.contract.validUntil) {
          this.validUntilDate = Vue.moment(this.contract.validUntil).format('DD.MM.YYYY');
        }
      }
    },

    onOpenValidUntilDatePicker() {
      if ((this.contract.validFrom && !this.contract.validUntil) ||
          (this.validFromDate && !this.validUntilDate)) {
        this.contract.validUntil = this.contract.validFrom;
        this.validUntilDate = this.validFromDate;
      }
    },

    getValidationClass(fieldName) {
      let field = this.$v.contract[fieldName];

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },

    writeBackDates() {
      if (this.validFromDate) {
        this.contract.validFrom = Vue.moment(this.validFromDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
      } else {
        this.contract.validFrom = null;
      }

      if (this.validUntilDate) {
        this.contract.validUntil = Vue.moment(this.validUntilDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
      } else {
        this.contract.validUntil = null;
      }
    },

    validateContact() {
      this.$v.$touch();
      this.writeBackDates();
      this.invalid = this.$v.$invalid;
    },

    buildUploadUrl() {
      this.optionsContractDocument.url = '';
      setTimeout(() => this.optionsContractDocument.url = this.$store.getters.serverUrl + 'binary/parentcontract/upload/child/' + this.child.id + '/contract/' + this.contract.refNr, 100);
    },

    onVdropzoneMounted() {
      jQuery('#contractDocumentFileDropzone').removeClass().addClass('button select-contract-document-button');
    },

    onVdropzoneProcessing(file) {
      HttpInterceptor.doRefreshToken();
      this.optionsContractDocument.headers.Authorization = 'Bearer ' + sessionStorage.getItem('sose.access_token');
      this.sending = file.processing;
      this.uploadFileName = file.upload.filename;
      this.initialContractDocumentLoadSuccess = false;
      this.initialContractDocumentLoadFailure = false;
    },

    onVdropzoneClick() {
      HttpInterceptor.doRefreshToken();
      this.optionsContractDocument.headers.Authorization = 'Bearer ' + sessionStorage.getItem('sose.access_token');
    },

    onVdropzoneError(file, message) {
      this.initialContractDocumentLoadFailure = true;
      let errMsg = 'Fehler beim Lesen der Datei: <b>' + file.upload.filename + '</b><br>';
      if (message.status) {
        errMsg += '<br><b>' + message.status + '</b>';
      }
      if (message.error) {
        errMsg += ' ' + message.error;
      }
      if (message.message) {
        errMsg += '<br>' + message.message;
      }

      this.sending = false;
      HttpErrorHandler.handleError(null, this, errMsg);

      if (message.error === 'invalid_token') {
        HttpInterceptor.forceLogout();
      }
    },

    onVdropzoneSuccess(file, response) {
      this.sending = false;
      this.initialContractDocumentLoadSuccess = true;
      this.$store.commit('successMsg', 'Die Datei <b>' + file.upload.filename + '</b> wurde erfolgreich eingelesen.');
      this.updateContractDocumentDataFromChild(response);
    },

    updateContractDocumentDataFromChild(child) {
      let updatedChild = JSON.parse(JSON.stringify(this.child));
      child.contracts.forEach((contract, i) => {
        if (this.contract.refNr === contract.refNr) {
          updatedChild.contracts[i] = JSON.parse(JSON.stringify(this.contract));
          updatedChild.contracts[i].documentAvailable = contract.documentAvailable;
          updatedChild.contracts[i].documentFileName = contract.documentFileName;
          updatedChild.contracts[i].documentId = contract.documentId;
          updatedChild.contracts[i].templateDocumentAvailable = contract.templateDocumentAvailable;
          updatedChild.contracts[i].templateFileName = contract.templateFileName;
          updatedChild.contracts[i].contractFileName = contract.contractFileName;
          updatedChild.contracts[i].templateId = contract.templateId;
        }
      })
      this.$emit("updateContract", updatedChild);
    },

    updateContractDocumentDataFromContract(updatedContract) {
      let updatedChild = JSON.parse(JSON.stringify(this.child));
      updatedChild.contracts.forEach((contract, i) => {
        if (updatedContract.refNr === contract.refNr) {
          updatedChild.contracts[i] = JSON.parse(JSON.stringify(this.contract));
          updatedChild.contracts[i].documentAvailable = updatedContract.documentAvailable;
          updatedChild.contracts[i].documentFileName = updatedContract.documentFileName;
          updatedChild.contracts[i].documentId = updatedContract.documentId;
          updatedChild.contracts[i].templateDocumentAvailable = updatedContract.templateDocumentAvailable;
          updatedChild.contracts[i].templateFileName = updatedContract.templateFileName;
          updatedChild.contracts[i].contractFileName = updatedContract.contractFileName;
          updatedChild.contracts[i].templateId = updatedContract.templateId;
        }
      })
      this.$emit("updateContract", updatedChild);
    },

    onDeleteContractDocument() {
      if (this.contract) {
        this.showDeleteContractDocumentDialog = true;
      }
    },

    deleteContractDocument() {
      if (this.contract) {
        this.sending = true;
        BinaryService.deleteParentContractDocument(this.child.id, this.contract.refNr)
            .then((response) => {
              this.sending = false;
              this.showDeleteContractDocumentDialog = false;
              this.$store.commit('successMsg', 'Das Dokument für Vertrag <b>' + this.contract.contractReferenceNumber + '</b> wurde erfolgreich gelöscht.');
              this.updateContractDocumentDataFromChild(response.data);
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Löschen des Vertragsdokument für <b>' + this.contract.contractReferenceNumber + '</b>');
              this.sending = false;
            })
      }
    },

    onCreateContractTemplateDocument() {
      if (this.contract) {
        this.showCreateContractPdfDialog = true;
      }
    },

    createContractPdf() {
      this.sending = true;
      this.success = false;
      ChildService.createContractPdf(this.child.id, this.contract.refNr)
          .then(response => {
            this.sending = false;
            this.showCreateContractPdfDialog = false;
            this.$store.commit('successMsg', 'Das Vertragsdokument für Vertrag <b>' + this.contract.contractReferenceNumber + '</b> wurde erfolgreich erzeugt.');
            this.updateContractDocumentDataFromContract(response.data);
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Erzeugen des Vertrags mit der Nr ' + this.contract.contractReferenceNumber + ' für Kind ' + this.child.masterdata.fullName);
            this.sending = false;
          })
    },

    onDeleteContractTemplateDocument() {
      if (this.contract) {
        this.showDeleteContractTemplateDocumentDialog = true;
      }
    },

    deleteContractTemplateDocument() {
      if (this.contract) {
        this.sending = true;
        BinaryService.deleteParentContractTemplateDocument(this.child.id, this.contract.refNr)
            .then((response) => {
              this.sending = false;
              this.showDeleteContractTemplateDocumentDialog = false;
              this.$store.commit('successMsg', 'Das erzeugte Vertragsdokument für Vertrag <b>' + this.contract.contractReferenceNumber + '</b> wurde erfolgreich gelöscht.');
              this.updateContractDocumentDataFromChild(response.data);
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Löschen des erzeugten Vertragsdokument für <b>' + this.contract.contractReferenceNumber + '</b>');
              this.sending = false;
            })
      }
    },

    onDownloadContractDocument() {
      if (this.contract) {
        this.downloadContractDocument();
      }
    },

    downloadContractDocument() {
      if (this.contract) {
        BinaryService.downloadParentContractDocument(this.contract.documentId)
            .then((response) => {

              setTimeout(() => {
                this.cleanDownloads();
                this.downloadBlob = new Blob([response.data], {type: 'application/octet-stream'});
                this.downloadUrl = window.URL.createObjectURL(this.downloadBlob);
                this.downloadLink = document.createElement('a');
                const id = this.contract.documentFileName;
                this.downloadLink.href = this.downloadUrl;
                this.downloadLink.setAttribute('download', id);
                this.downloadLink.setAttribute('id', id);
                document.body.appendChild(this.downloadLink);
                this.downloadLink.click();
              }, 250);
              this.$store.commit('successMsg', 'Das Dokument für Vertrag <b>' + this.contract.contractReferenceNumber + '</b> wurde erfolgreich heruntergeladen.');
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Herunterladen des Vertragsdokument für <b>' + this.contract.contractReferenceNumber + '</b>');
            })
      }
    },

    cleanDownloads() {
      if (this.downloadUrl) {
        this.downloadBlob = null;
        document.body.removeChild(this.downloadLink);
        this.downloadLink = null;
      }
    },


    onDownloadContractTemplateDocument() {
      if (this.contract) {
        this.downloadContractTemplateDocument();
      }
    },

    downloadContractTemplateDocument() {
      if (this.contract) {
        BinaryService.downloadParentContractDocument(this.contract.templateId)
            .then((response) => {

              setTimeout(() => {
                this.cleanDownloads();
                this.downloadBlob = new Blob([response.data], {type: 'application/octet-stream'});
                this.downloadUrl = window.URL.createObjectURL(this.downloadBlob);
                this.downloadLink = document.createElement('a');
                const id = this.contract.contractFileName;
                this.downloadLink.href = this.downloadUrl;
                this.downloadLink.setAttribute('download', id);
                this.downloadLink.setAttribute('id', id);
                document.body.appendChild(this.downloadLink);
                this.downloadLink.click();
              }, 250);
              this.$store.commit('successMsg', 'Das erzeugte Vertragsdokument für Vertrag <b>' + this.contract.contractReferenceNumber + '</b> wurde erfolgreich heruntergeladen.');
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Herunterladen des erzeugten Vertragsdokument für <b>' + this.contract.contractReferenceNumber + '</b>');
            })
      }
    },

  },

  computed: {
    labels() {
      return this.$store.getters.labels
    },

    isAdmin() {
      return this.$store.getters.admin;
    },

    isFacilityAdmin() {
      return this.$store.getters.facilityAdmin;
    },

    isManagement() {
      return this.$store.getters.management;
    },

    isTreasurer() {
      return this.$store.getters.treasurer;
    },

    canWrite() {
      return this.$store.getters.canWrite;
    },
  }

};
</script>

<style lang="scss" scoped>
h5 {
  font-weight: 400;
  color: lightseagreen;
}

.pdf-frame {
  width: 100%;
  margin: 0 auto;
}

.md-content {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  overflow: auto;
}

.select-contract-document-button {
  margin: 0;
}

.md-dialog /deep/ .md-dialog-container {
  max-width: 900px;
}


</style>
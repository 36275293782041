<template>
  <div>
    <md-dialog :md-click-outside-to-close="false" id="createNewFeeDialog" :md-active.sync="showDialog" @md-closed="closeDialog">

      <md-dialog-title>
        <form novalidate @submit.prevent="validateFee">

          <div class="grid-x grid-padding-x">
            <div v-if="mode === 'view'" class="cell large-5 medium-8 small-8">
              <i class="fi-eye"></i>&nbsp;&nbsp;&nbsp;Gebühr einsehen
            </div>
            <div v-else-if="mode === 'update'" class="cell large-5 medium-8 small-8">
              <i class="fi-pencil"></i>&nbsp;&nbsp;&nbsp;
              <span v-if="selectedFeeTemplate">Gebührenvorlage bearbeiten</span>
              <span v-else>Gebühr bearbeiten</span>
            </div>
            <div v-else-if="mode === 'add'" class="cell large-5 medium-8 small-8">
              <span v-if="selectedFeeTemplate">
                <img width="38px" src="../../assets/icons/fee-template.png" alt="Gebührenvorlage"/>&nbsp;&nbsp;&nbsp;Neue Gebührenvorlage
              </span>
              <span v-else>
                <md-icon style="height: 10px; color: black;">euro_symbol</md-icon>&nbsp;&nbsp;&nbsp;Neue Gebühr
              </span>
              <span v-if="children">für {{children.length}} Kind<span v-if="children.length !== 1">er</span></span>
              anlegen
            </div>
            <div class="cell large-2 medium-4 small-4" style="margin-top: -10px;">
              <SessionTime></SessionTime>
              <br>
            </div>
            <div class="cell large-5 text-center">
              <button v-if="mode === 'update'" class="button success" type="submit" :disabled="sending">
                <i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Änderungen speichern
              </button>
              <button v-else-if="mode === 'add'" class="button success" type="submit" :disabled="sending">
                <span v-if="selectedFeeTemplate">
                  <img width="18px" src="../../assets/icons/fee-template.png" alt="Gebührenvorlage"/>&nbsp;&nbsp;&nbsp;Vorlage anlegen
                </span>
                <span v-else>
                  <md-icon style="height: 10px">euro_symbol</md-icon>&nbsp;&nbsp;&nbsp;Gebühr anlegen
                </span>
              </button>
              <button v-if="mode !== 'view'" class="button alert" style="margin-left: 1rem;" @click="closeDialog" type="button" :disabled="sending">
                <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Abbrechen
              </button>
            </div>
          </div>

        </form>
      </md-dialog-title>

      <md-dialog-content id="dialogContentId">
        <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        <br>

        <div v-if="selectedFeeTemplate" class="grid-x grid-padding-x">
          <div class="cell medium-6 small-12">
            <md-field v-if="feeTemplate" :class="getValidationClass('name')">
              <label for="template-name">Name der Vorlage</label>
              <md-input name="template-name" id="template-name" autocomplete="off" v-model="feeTemplate.name" :disabled="sending"/>
              <span class="md-error" v-if="!$v.feeTemplate.name.required">Name der Vorlage ist notwendig</span>
            </md-field>
          </div>
          <div class="cell medium-6 small-12" style="padding-top: 12px;">
<!--            <md-checkbox class="md-primary" v-model="feeTemplate.defaultTemplate">Als Standardvorlage verwenden<br></md-checkbox>-->
          </div>
        </div>

        <div v-if="fee && !selectedFeeTemplate" class="grid-x grid-padding-x">
          <div class="cell large-6 medium-12 small-12">
            <md-field v-if="feeTemplates && feeTemplates.length > 0">
              <label>Gebührenvorlage auswählen</label>
              <md-select v-model="selectedFeeTemplateRefNr" @md-selected="onSelectFeeTemplate" :disabled="sending">
                <md-option v-for="feeTemplate of feeTemplates" v-bind:value="feeTemplate.refNr" :key="feeTemplate.refNr">
                  {{feeTemplate.name}}
                  <md-tooltip md-direction="right" style="font-size: medium; height: auto;">
                    <b style="color: lightskyblue">{{feeTemplate.name}}</b><br>
                    Gebührenart: <b>{{labels.feeType[feeTemplate.fee.feeType]}}</b><br>
                    Zahlungsintervall: <b>{{labels.paymentInterval[feeTemplate.fee.interval]}}</b><br>
                    <span v-if="feeTemplate.fee.pricelist && feeTemplate.fee.pricelist.name">Preisliste: <b>{{ feeTemplate.fee.pricelist.name }}</b><br></span>
                    Basisbetrag: <b>{{feeTemplate.fee.baseAmount | currency}}</b><br>
                    Ermäßigung: <b>{{feeTemplate.fee.discountPercentage}}%</b><br>
                    Nachlass: <b>{{feeTemplate.fee.discountAbsolute | currency}}</b><br>
                    zu bezahlen: <b>{{feeTemplate.fee.amount | currency}}</b>
                    <span><br>steuer-relevant: <md-icon v-if="feeTemplate.fee.taxRelevant === true" style="color: greenyellow; margin-top: -5px">done</md-icon>
                    <md-icon v-else style="color: white; margin-top: -5px">remove</md-icon>
                    </span><br>
                  </md-tooltip>
                </md-option>
              </md-select>
            </md-field>
            <div v-else style="padding-top: 26px;">
              <router-link to="/Finanzen/Gebuehrenvorlagen"><span style="font-size: 1.2rem;">Keine Gebührenvorlagen vorhanden</span>
                <md-tooltip md-direction="bottom" style="font-size: medium;">Gebührenvorlagen bearbeiten</md-tooltip>
              </router-link>
              <br><br>
            </div>
          </div>
          <div class="cell large-6 medium-0 small-0">
          </div>
          <div class="cell medium-6 small-12">
            <md-field :class="getValidationClass('name')">
              <label for="name">Name der Gebühr</label>
              <md-input name="name" id="name" autocomplete="off" v-model="fee.name" :disabled="sending"/>
              <span class="md-error" v-if="!$v.fee.name.required">Name der Gebühr ist notwendig</span>
            </md-field>
          </div>
          <div class="cell medium-6 small-12 fee-billed-info">
            <span class="hide-for-small-only"><br><br></span>
            <p v-if="fee.lastBilledDate">
              <md-icon style="color: #42A85F">check</md-icon>
              Zuletzt abgerechnet am {{fee.lastBilledDate | moment("DD.MM.YYYY") }}
            </p>
            <p v-else>
              <md-icon style="color: grey">remove</md-icon>
              Gebühr noch nicht abgerechnet
            </p>
            <span class="hide-for-medium-only hide-for-large"><br></span>
          </div>
        </div>

        <div v-if="fee" class="grid-x grid-padding-x">
          <div class="cell large-3 medium-6 small-12">
            <md-field :class="getValidationClass('feeType')">
              <label>Gebührenart</label>
              <md-select v-model="fee.feeType" autocomplete="off"
                         @md-selected="onSelectPaymentInterval" :disabled="sending">
                <md-option v-for="feeType of labels.feeTypes" v-bind:value="feeType" :key="feeType">
                  {{labels.feeType[feeType]}}
                </md-option>
              </md-select>
              <span class="md-error" v-if="!$v.fee.feeType.required">Gebührenart ist notwendig</span>
            </md-field>
          </div>

          <div class="cell large-3 medium-6 small-12">
            <md-field v-if="fee">
              <label>Zahlungsintervall</label>
              <md-select v-model="fee.interval" autocomplete="off" @md-selected="onSelectPaymentInterval" :disabled="sending">
                <md-option v-for="interval of labels.paymentIntervals" v-bind:value="interval" :key="interval">
                  {{labels.paymentInterval[interval]}}
                </md-option>
              </md-select>
            </md-field>
          </div>

          <div class="cell large-6 medium-12 small-12">
            <div v-if="!selectedFeeTemplate" class="md-layout">
              <div class="md-layout-item" style="padding-right: 15px;">
                <md-datepicker id="valid-from-date-dp" :md-debounce="10000" :md-open-on-focus="false" v-model="validFromDate" md-immediately :disabled="sending"
                               :class="getValidationClass('validFrom')" :md-disabled-dates="disabledFirstDay"
                               @md-closed="onCloseValidFromDatePicker">
                  <label>Gültig von</label>
                  <span class="md-error" v-if="$v.fee.validFrom.$model === 'Invalid date'">Gültig von Datum ist notwendig</span>
                </md-datepicker>
              </div>
              <div class="md-layout-item" style="padding-left: 15px;">
                <md-datepicker id="valid-until-date-dp" :md-debounce="10000" :md-open-on-focus="false" v-model="validUntilDate" md-immediately :disabled="sending"
                               :class="getValidationClass('validUntil')" :md-disabled-dates="disabledLastDay"
                               @md-closed="onCloseValidUntilDatePicker" @md-opened="onOpenValidUntilDatePicker">
                  <label>Gültig bis</label>
                  <span class="md-error" v-if="$v.fee.validUntil.$model === 'Invalid date'">Gültig bis Datum ist notwendig</span>
                </md-datepicker>
              </div>
            </div>
          </div>

          <div v-if="!feeTemplate" class="cell large-3 medium-6 small-12">
            <md-field v-if="fee">
              <label>Monat 1. Abbuchung</label>
              <md-select v-model="fee.intervalStartMonth" autocomplete="off" @md-selected="reCalculate" :disabled="sending">
                <md-option v-for="(month, index) in availableStartMonths" v-bind:value="month" :key="month">
                  {{intervalAvailableStartMonths[index]}}
                </md-option>
              </md-select>
            </md-field>
          </div>

          <div class="cell large-3 medium-6 small-12" style="padding-top: 12px;padding-bottom: 12px;">
            <md-checkbox class="md-primary" v-model="fee.usingPriceList" @change="toggleUsePriceList">Preisliste verwenden<br></md-checkbox>
          </div>

          <div v-if="feeTemplate" class="cell large-3 medium-6 small-12"></div>

          <div v-if="fee.usingPriceList" class="cell large-6 medium-12 small-12">
            <md-field v-if="priceLists && priceLists.length > 0" :class="getValidationClass('selectedPriceListRefNr')">
              <label>Preislisten</label>
              <md-select v-model="selectedPriceListRefNr" @md-selected="onSelectPriceList" :disabled="sending">
                <md-option v-for="priceList of priceLists" v-bind:value="priceList.refNr" :key="priceList.refNr">
                  {{priceList.name}}
                  <md-tooltip md-direction="right" style="font-size: medium; height: auto;">
                    {{priceList.name}}<br>
                    {{labels.priceListType[priceList.type]}}<br>
                    <span v-if="priceList.type === 'INTERVAL_HOURS'">
                      {{ priceList.hourIntervalPricings.length }} Stundeninterval<span v-if="priceList.hourIntervalPricings.length !== 1">le</span>
                    </span>
                    <span v-else-if="priceList.type === 'FACTOR_HOURS'">
                      {{ priceList.factorPerHour | currency}}
                    </span>
                    <span v-else-if="priceList.type === 'FACTOR_DAYS'">
                      {{ priceList.factorPerDay | currency}}
                    </span>
                  </md-tooltip>
                </md-option>
              </md-select>
              <span class="md-error" v-if="!$v.fee.selectedPriceListRefNr">Preisliste ist notwendig</span>
              <md-tooltip v-if="fee && fee.pricelist" md-direction="right" style="font-size: medium; height: auto;">
                {{fee.pricelist.name}}<br>
                {{labels.priceListType[fee.pricelist.type]}}<br>
                <span v-if="fee.pricelist.type === 'INTERVAL_HOURS'">
                      {{ fee.pricelist.hourIntervalPricings.length }} Stundeninterval<span v-if="fee.pricelist.hourIntervalPricings.length !== 1">le</span>
                    </span>
                <span v-else-if="fee.pricelist.type === 'FACTOR_HOURS'">
                      {{ fee.pricelist.factorPerHour | currency}}
                    </span>
                <span v-else-if="fee.pricelist.type === 'FACTOR_DAYS'">
                      {{ fee.pricelist.factorPerDay | currency}}
                    </span>
              </md-tooltip>
            </md-field>
            <div v-else style="padding-top: 26px;">
              <router-link to="/Finanzen/Preislisten"><span style="font-size: 1.2rem;">Keine Preislisten vorhanden</span>
                <md-tooltip md-direction="bottom" style="font-size: medium;">Preislisten bearbeiten</md-tooltip>
              </router-link>
              <br><br>
            </div>
          </div>
          <div v-else class="cell large-6 medium-12 small-12"></div>

          <div class="cell large-3 medium-6 small-12">
            <md-field :class="getValidationClass('baseAmount')">
              <label for="baseAmount">Basisbetrag (€ <b>{{labels.paymentInterval[fee.interval]}}</b>)</label>
              <md-input type="number" name="baseAmount" id="baseAmount" autocomplete="off"
                        v-model="fee.baseAmount" :disabled="sending || fee.usingPriceList" @md-changed="reCalculate"/>
              <span class="md-error" v-if="!($v.fee.baseAmount.required && $v.fee.baseAmount.minValue) && !fee.usingPriceList">Positiver Wert ist notwendig</span>
            </md-field>
          </div>

          <div class="cell large-3 medium-6 small-12">
            <md-field :class="getValidationClass('discountPercentage')">
              <label for="discountPercentage">Ermäßigung (%)</label>
              <md-input type="number" min="0" name="discountPercentage" id="discountPercentage" autocomplete="off"
                        v-model="fee.discountPercentage" :disabled="sending" @md-changed="reCalculate"/>
              <span class="md-error" v-if="!$v.fee.discountPercentage.between">Ermäßigung (%) muss zwischen 0% und 100% sein</span>
            </md-field>
          </div>

          <div class="cell large-3 medium-6 small-12">
            <md-field :class="getValidationClass('discountAbsolute')">
              <label for="discountAbsolute">Nachlass (€)</label>
              <md-input type="number" min="0" name="discountAbsolute" id="discountAbsolute" autocomplete="off"
                        v-model="fee.discountAbsolute" :disabled="sending" @md-changed="reCalculate"/>
              <span class="md-error" v-if="!$v.fee.discountAbsolute.minValue">Nachlass (€) muss positiv sein</span>
            </md-field>
          </div>

          <div class="cell large-3 medium-6 small-12">
            <md-field>
              <label for="amount">Zu bezahlen (€ <b>{{labels.paymentInterval[fee.interval]}}</b>)</label>
              <md-input type="number" name="amount" id="amount" autocomplete="off"
                        v-model="fee.amount" disabled/>
            </md-field>
          </div>

          <div class="cell large-3 medium-6 small-12">
            <md-checkbox class="md-primary" v-model="fee.taxRelevant" >steuer-relevant<br></md-checkbox>
          </div>

          <div class="cell">
            <md-progress-bar v-if="sending && progress > 0" md-mode="determinate" :md-value="progress"/>
          </div>
        </div>
      </md-dialog-content>

      <button class="close-button" type="button" @click="closeDialog">
        <span aria-hidden="true">&times;</span>
      </button>
    </md-dialog>
  </div>
</template>

<script>

  import jQuery from 'jquery';
  import {validationMixin} from 'vuelidate';
  import Vue from 'vue';
  import SessionTime from '../animations/SessionTime';
  import ChildService from '../../services/ChildService';
  import HttpErrorHandler from '../../services/HttpErrorHandler';
  import FacilityService from '../../services/FacilityService';

  import {
    required,
    minValue,
    between,
  } from 'vuelidate/lib/validators';

  export default {
    name: 'EditFeeDialog',
    mixins: [validationMixin],

    components: {
      SessionTime,
    },

    props: {
      child: {
        required: false
      },
      selectedFee: {
        required: true
      },
      selectedFeeTemplate: {
        required: false
      },
      selectedFacilityReferenceNumber: {
        required: false
      },
      showDialog: {
        required: true
      },
      mode: {
        required: true
      },
      children: {
        required: false
      },
    },

    validations: {
      selectedPriceListRefNr: {
        required,
      },

      fee: {
        name: {
          required,
        },
        feeType: {
          required,
        },
        validFrom: {
          required,
        },
        validUntil: {
          required,
        },
        baseAmount: {
          required,
          minValue: minValue(0.01),
        },
        discountAbsolute: {
          minValue: minValue(0),
        },
        discountPercentage: {
          between: between(0, 100),
        },
      },

      feeTemplate: {
        name: {
          required,
        },
      },
    },

    data: () => ({
      success: true,
      sending: false,

      disabledFirstDay: date => {
        const day = date.getDate();
        return day !== 1;
      },

      disabledLastDay: date => {
        const day = date.getDate();
        const dateString = date.getFullYear() + '-' + Number(date.getMonth() + 1) + '-' +  Number(date.getDay() + 1);
        const lastDay = Number(Vue.moment(dateString).endOf('month').format('DD'));
        return day !== lastDay
      },

      fee: null,
      feeTemplate: null,

      validFromDate: null,
      validUntilDate: null,

      availableStartMonths: [0],
      intervalAvailableStartMonths: ['Keine Angabe'],

      priceLists: [],
      selectedPriceListRefNr: '',
      feeTemplates: [],
      selectedFeeTemplateRefNr: '',

      progress: 0,
      responseCount: 0,
      errorCount: 0,
      errorMessage: '',
    }),

    watch: {
      showDialog() {
        this.updateData();
        this.formatValidFromDate();
        this.formatValidUntilDate();
        this.updateAvailableStartMonths();
        this.initDatePickerSyncHandler(this.showDialog);
      },

      selectedFeeTemplate() {
        this.updateData();
      },

      selectedFacilityReferenceNumber() {
        if (this.selectedFacilityReferenceNumber) {
          this.getFacilityPriceLists(this.selectedFacilityReferenceNumber);
        }
      },

      child() {
        this.getFacilityPriceLists(this.child.facilityReference);
        this.getFacilityFeeTemplates(this.child.facilityReference);
      }
    },

    created() {
      this.reCalculate();
      this.formatValidFromDate();
      this.formatValidUntilDate();
      if (this.child) {
        this.getFacilityPriceLists(this.child.facilityReference);
        this.getFacilityFeeTemplates(this.child.facilityReference);
      }
    },

    updated() {
      if (this.showDialog) {
        this.reCalcAmount();
        this.formatValidFromDate();
        this.formatValidUntilDate();
      }
    },

    methods: {

      initDatePickerSyncHandler(init) {
        if (init) {
          setTimeout(() => {
            jQuery('#valid-from-date-dp').children('input').bind('blur', () => {
              this.validFromDate = jQuery('#valid-from-date-dp').children('input').val();
              this.writeBackDates();
              this.onCloseValidFromDatePicker();
            });
            jQuery('#valid-until-date-dp').children('input').bind('blur', () => {
              this.validUntilDate = jQuery('#valid-until-date-dp').children('input').val();
              this.writeBackDates();
              this.onCloseValidUntilDatePicker();
            });
          }, 500);
        } else {
          jQuery('#valid-from-date-dp').children('input').unbind('blur');
          jQuery('#valid-until-date-dp').children('input').unbind('blur');
        }
      },

      toggleUsePriceList() {
        if (this.fee && !this.fee.usingPriceList) {
          this.selectedPriceListRefNr = '';
          this.fee.pricelist = null;
        }
      },

      updateData() {
        this.fee = this.selectedFee;
        this.feeTemplate = this.selectedFeeTemplate;
        this.selectedPriceListRefNr = '';
        this.selectedFeeTemplateRefNr = '';
        if (this.fee && this.fee.pricelist && this.fee.pricelist.refNr){
          this.selectedPriceListRefNr = this.fee.pricelist.refNr;
        }
      },

      formatValidFromDate() {
        if (this.validFromDate === null) {
          if (this.fee && this.fee.validFrom) {
            this.validFromDate = Vue.moment(this.fee.validFrom).format('DD.MM.YYYY');
          }
        }
      },

      formatValidUntilDate() {
        if (this.validUntilDate === null) {
          if (this.fee && this.fee.validUntil) {
            this.validUntilDate = Vue.moment(this.fee.validUntil).format('DD.MM.YYYY');
          }
        }
      },

      onCloseValidFromDatePicker() {
        setTimeout(() => this.validFromDate = jQuery('#valid-from-date-dp').children('input').val(), 100);
        this.updateAvailableStartMonths();
        this.reCalcFee();
      },

      onCloseValidUntilDatePicker() {
        setTimeout(() => this.validUntilDate = jQuery('#valid-until-date-dp').children('input').val(), 100);
        this.updateAvailableStartMonths();
        this.reCalcFee();
      },

      updateAvailableStartMonths() {
        setTimeout(() => {
          this.availableStartMonths = [0];
          this.intervalAvailableStartMonths = ['Keine Angabe'];

          let validFromDate = Vue.moment(this.validFromDate, "DD.MM.YYYY").format("YYYY-MM-DD");
          let validUntilDate = Vue.moment(this.validUntilDate, "DD.MM.YYYY").format("YYYY-MM-DD");

          let numMonths = Vue.moment(validUntilDate).diff(Vue.moment(validFromDate), 'months', false) + 1;
          let startMonthIndex = Number(Vue.moment(validFromDate).format('MM')) - 1;

          for (let i = 0; i < numMonths; i++) {
            let index = Number(1 + (startMonthIndex + i) % 12);
            let item = Vue.moment().month(startMonthIndex + i).format('MMMM') + ' ' + Vue.moment(this.validFromDate).add(i, 'M').format('YYYY');
            if ((i < 1 && this.fee.interval === 'MONTHLY') ||
                (i < 3 && this.fee.interval === 'QUARTERLY') ||
                (i < 6 && this.fee.interval === 'HALFYEARLY') ||
                (i < 12 && this.fee.interval === 'YEARLY')) {
              this.availableStartMonths.push(index);
              this.intervalAvailableStartMonths.push(item);
            } else {
              this.fee.intervalStartMonth = 0;
              break;
            }
          }
        }, 250);
      },

      onOpenValidUntilDatePicker() {
        if ((this.fee.validFrom && !this.fee.validUntil) ||
          (this.validFromDate && !this.validUntilDate)) {
          this.fee.validUntil = this.fee.validFrom;
          this.validUntilDate = this.validFromDate;
        }
      },

      onSelectPaymentInterval() {
        this.updateAvailableStartMonths();
        this.reCalculate();
      },

      onSelectFeeTemplate() {
        if (this.selectedFeeTemplateRefNr.toString()) {
          this.feeTemplates.forEach(feeTemplate => {
            if (feeTemplate.refNr === this.selectedFeeTemplateRefNr) {
              this.fee = JSON.parse(JSON.stringify(feeTemplate.fee));
              this.fee.name = feeTemplate.name;
              this.selectedPriceListRefNr = '';

              if (this.fee.pricelist && this.fee.pricelist.refNr) {
                this.selectedPriceListRefNr = this.fee.pricelist.refNr;
              }

              this.$store.commit('successMsg', 'Gebührenvorlage <b>' + feeTemplate.name + '</b> wurde übernommen.');
            }
          })
          setTimeout(() => this.selectedFeeTemplateRefNr = '', 500);
        }
      },

      onSelectPriceList() {
        for (let pl of this.priceLists) {
          if (pl.refNr === this.selectedPriceListRefNr) {
            this.fee.pricelist = pl;
          }
        }
        this.reCalcFee();
      },

      reCalcFee() {
        if (this.child && this.fee && this.fee.usingPriceList && this.selectedPriceListRefNr) {
          this.writeBackDates();
          this.sending = true;
          this.success = false;
          ChildService.reCalcFee(this.child.id, this.fee)
            .then(response => {
              this.fee = JSON.parse(JSON.stringify(response.data));
              this.success = true;
              this.sending = false;
              this.reCalcAmount();
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Neuberechnen der Gebühr für Kind ' + this.child.masterdata.fullName);
              this.sending = false;
            })
        }
      },

      reCalcAmount() {
        if (this.fee) {
          // const weight = {YEARLY: 12, HALFYEARLY: 6, QUARTERLY: 3, ONE_TIME: 1, MONTHLY: 1}
          let newBaseAmount = this.fee.baseAmount; /// weight[this.fee.interval];
          let newAmount = newBaseAmount - (newBaseAmount * this.fee.discountPercentage / 100) - this.fee.discountAbsolute;
          this.fee.amount = newAmount.toFixed(2);
        }
      },

      reCalculate() {
        this.reCalcFee();
        this.reCalcAmount();
      },

      addFeeTemplate() {
        this.sending = true;
        this.success = false;
        FacilityService.addFeeTemplate(this.selectedFacilityReferenceNumber, this.feeTemplate)
          .then(response => {
            this.success = true;
            this.sending = false;
            this.$emit("closeEditFeeDialog");
            this.$emit("updateFeeTemplateSuccess", this.feeTemplate, response.data);
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Hinzufügen der Gebührenvorlage <b>' + this.feeTemplate.name + '</b> für Einrichtung mit Ref-Nummer ' + this.selectedFacilityReferenceNumber);
            this.sending = false;
          })
      },

      updateFeeTemplate() {
        this.success = false;
        this.sending = true;
        FacilityService.updateFeeTemplate(this.selectedFacilityReferenceNumber, this.feeTemplate)
          .then(response => {
            this.success = true;
            this.sending = false;
            this.$emit("closeEditFeeDialog");
            this.$emit("updateFeeTemplateSuccess", this.feeTemplate, response.data);
          })
          .catch(e => {
            this.sending = false;
            HttpErrorHandler.handleError(e, this, 'Fehler beim Speichern der Gebührenvorlage <b>' + this.selectedFeeTemplate.name + '</b>');
          })
      },

      addFee(child, numRequests) {
        this.sending = true;
        this.success = false;
        ChildService.addFee(child.id, this.fee)
          .then(response => {
            if (numRequests === 1) {
              const updatedChild = JSON.parse(JSON.stringify(response.data));
              this.success = true;
              this.sending = false;
              this.$emit("closeEditFeeDialog");
              this.$emit("updateFeeSuccess", updatedChild);
            } else {
              this.responseCount++;
              this.progress = Number(this.responseCount / numRequests) * 100;
              if (this.responseCount >= numRequests) {
                this.sending = false;
                let msg = 'Für <b>' + (this.responseCount - this.errorCount) + '</b>&nbsp;Kinder wurde eine neue Gebühr angelegt.';
                if (this.errorCount > 0 ) {
                  msg += '<br>Bei <b>' + this.errorCount + '</b> war die Anlage fehlerhaft.';
                  msg += '<br><br>Folgende Kinder sind betroffen:<br><b>' + this.errorMessage.substr(0, this.errorMessage.length - 2) + '</b>';
                }
                this.$store.commit('successMsg', msg);
                this.closeDialog();
                this.$emit("updateFeeSuccess");
              }
            }
          })
          .catch(e => {
            this.errorCount++;
            this.errorMessage += child.masterdata.fullName + ', ';
            this.responseCount++;
            this.progress = Number(this.responseCount / numRequests) * 100;
            HttpErrorHandler.handleError(e, this, 'Fehler beim Anlegen der Gebühr für Kind ' + child.masterdata.fullName);
            if (numRequests === 1 || this.responseCount >= numRequests) {
              this.sending = false;
            }
          })
      },

      updateFee() {
        this.sending = true;
        this.success = false;
        ChildService.updateFee(this.child.id, this.fee)
          .then(response => {
            const child = JSON.parse(JSON.stringify(response.data));
            this.success = true;
            this.sending = false;
            this.$emit("closeEditFeeDialog");
            this.$emit("updateFeeSuccess", child);
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Aktualisieren der Gebühr für Kind ' + this.child.masterdata.fullName);
            this.sending = false;
          })
      },

      getValidationClass(fieldName) {
        let field = this.$v.fee[fieldName];
        if (!field) {
          field = this.$v.selectedPriceListRefNr;
        }

        if (this.feeTemplate && fieldName === 'name') {
          field = this.$v.feeTemplate[fieldName];
        }

        if (fieldName === 'baseAmount' && this.fee.usingPriceList) {
          return '';
        }

        if (field) {
          return {
            'md-invalid': field.$invalid && field.$dirty
          }
        }
      },

      writeBackDates() {
        if (this.validFromDate) {
          this.fee.validFrom = Vue.moment(this.validFromDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
          if (this.fee.validFrom === 'Invalid date') {
            this.fee.validFrom = null;
            this.validFromDate = '';
          }
        } else {
          this.fee.validFrom = null;
        }

        if (this.validUntilDate) {
          this.fee.validUntil = Vue.moment(this.validUntilDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
          if (this.fee.validUntil === 'Invalid date') {
            this.fee.validUntil = null;
            this.validUntilDate = '';
          }
        } else {
          this.fee.validUntil = null;
        }
      },

      validateFee() {
        this.writeBackDates();

        if (this.selectedFeeTemplate) {
          this.fee.name = 'dummy';
          this.fee.validFrom = Vue.moment().format('YYYY-MM-DD');
          this.fee.validUntil = Vue.moment().format('YYYY-MM-DD');
        } else {
          this.feeTemplate = {name: 'dummy'};
        }

        this.$v.$touch();
        let invalid = this.$v.$invalid;

        if (this.fee.usingPriceList && this.fee.baseAmount <= 0) {
          this.fee.baseAmount = 0.01;
          this.$v.$touch();
          invalid = this.$v.$invalid;
          this.fee.baseAmount = 0;
        }

        if (!this.fee.usingPriceList && this.selectedPriceListRefNr === '') {
          this.selectedPriceListRefNr = 'VALID';
          this.$v.$touch();
          invalid = this.$v.$invalid;
          this.selectedPriceListRefNr = '';
        }

        if (this.selectedFeeTemplate) {
          this.fee.name = '';
          this.fee.validFrom = null;
          this.fee.validUntil = null;

        } else {
          this.feeTemplate = null;
        }

        if (this.mode === 'update' && !invalid) {
          if (this.child) {
            this.updateFee();
          }
          if (this.selectedFeeTemplate) {
            this.updateFeeTemplate();
          }
        }
        if (this.mode === 'add' && !invalid) {
          this.responseCount = 0;
          this.errorCount = 0;
          this.errorMessage = '';
          this.progress = 0;

          if (this.children) {
            for (let child of this.children) {
              this.addFee(child, this.children.length);
            }
          } else if (this.child) {
            this.addFee(this.child, 1);
          } else if (this.selectedFeeTemplate) {
            this.addFeeTemplate();
          }
        }
      },

      closeDialog() {
        this.$emit("closeEditFeeDialog");
        this.$v.$reset();
        this.validFromDate = null;
        this.validUntilDate = null;
      },

      getFacilityPriceLists(referenceNumber) {
        if (!(this.organization && this.organization.facilities && this.organization.facilities.length > 0)) {
          return;
        }
        if (!referenceNumber || referenceNumber === 'null') {
          return;
        }
        if (this.isAdmin || this.isTreasurer) {
          this.sending = true;
          FacilityService.getPriceLists(referenceNumber)
            .then(response => {
              this.priceLists = response.data;
              this.sending = false;
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Preislisten für Einrichtung mit Nummer ' + referenceNumber);
              this.sending = false;
            })
        }
      },

      getFacilityFeeTemplates(referenceNumber) {
        if (!(this.organization && this.organization.facilities && this.organization.facilities.length > 0)) {
          return;
        }
        if (!referenceNumber || referenceNumber === 'null') {
          return;
        }
        if (this.isAdmin || this.isTreasurer) {
          this.sending = true;
          FacilityService.getFeeTemplates(referenceNumber)
            .then(response => {
              this.feeTemplates = response.data;
              this.sending = false;
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Gebührenvorlagen für Einrichtung mit Nummer ' + referenceNumber);
              this.sending = false;
            })
        }
      },
    },

    computed: {
      isAuthenticated() {
        return this.$store.getters.authenticated
      },

      isAdmin() {
        return this.$store.getters.admin
      },

      isTreasurer() {
        return this.$store.getters.treasurer;
      },

      isSage() {
        return this.$store.getters.sage
      },

      organization() {
        return this.$store.getters.organization
      },

      labels() {
        return this.$store.getters.labels
      },
    },
  };
</script>

<style scoped>
  .fee-billed-info {
    color: grey;
  }
</style>
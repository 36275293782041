<template>
  <div v-if="child" class="tab-content">
    <div v-if="organization.financeModuleActive">
      <md-table id="contracts-view-table-id" v-model="searched" md-sort="name" md-sort-order="asc" md-card md-fixed-header style="height: 740px;"
                @md-selected="onSelect" :md-selected-value.sync="selectedRows">
        <md-table-toolbar>
          <div class="md-toolbar-section-start grid-x">
            <div class="cell large-4 hide-for-medium-only hide-for-small-only">
              <p class="md-title ai-number">{{searched.length}} <span v-if="searched.length > 1">Verträge</span><span v-else>Vertrag</span></p>
            </div>
            <div class="cell large-2 medium-0 small-0">
              <div v-if="sending">
                <vue-simple-spinner></vue-simple-spinner>
              </div>
            </div>
            <div class="cell large-6 medium-12 small-12">
              <md-field md-clearable class="md-toolbar-section-end">
                <md-input placeholder="Suche nach Vertragspartner ..." v-model="search" @input="searchOnTable"/>
              </md-field>
            </div>
            <div class="cell" style="padding-bottom: 15px;">
              <div class="grid-x grid-padding-x">
                <div class="cell medium-6 large-6 hide-for-small-only">
                  <md-checkbox class="md-primary" @change="searchOnTable" v-model="showActiveContractsOnly">Nur aktive Verträge
                  </md-checkbox>
                </div>
                <div v-if="!child.archived && canWrite && (isFacility || isTreasurer || isAdmin || isFacilityAdmin || isManagement)" class="cell medium-6 large-6">
                  <md-button @click="onAddContract" class="md-icon-button md-raised md-primary">
                    <md-icon>note_add</md-icon>
                    <md-tooltip>Neuen Vertrag hinzufügen</md-tooltip>
                  </md-button>
                  <md-button @click="onEditContract" :disabled="!selected" class="md-icon-button md-raised md-third">
                    <md-icon>edit</md-icon>
                    <md-tooltip>Vertrag bearbeiten</md-tooltip>
                  </md-button>
                  <md-button @click="onDeleteContract" :disabled="!selected || !isAdmin" class="md-icon-button md-raised md-accent">
                    <md-icon>delete</md-icon>
                    <md-tooltip>Vertrag löschen</md-tooltip>
                  </md-button>
                </div>
                <div v-else class="cell medium-6 large-6">
                  <md-button @click="onViewContract" :disabled="!selected" class="md-icon-button md-raised md-third">
                    <md-icon>visibility</md-icon>
                    <md-tooltip>Vertrag einsehen</md-tooltip>
                  </md-button>
                </div>
              </div>
            </div>
          </div>
        </md-table-toolbar>

        <md-table-empty-state v-if="search"
                              md-label="Keine passenden Verträge gefunden"
                              :md-description="`Keine Verträge mit Vertragspartner '${search}' gefunden. Versuchen Sie es mit einem anderen Vertragspartner.`">
        </md-table-empty-state>

        <md-table-row :id="contractRowId(item)" slot="md-table-row" slot-scope="{ item }" :class="getClass(item.refNr)" md-selectable="single">
          <md-table-cell :class="activeClass(item.expired)" md-label="Vertrags-Nr" md-sort-by="contractReferenceNumber">{{ item.contractReferenceNumber }}<br><br>
            <md-button class="md-icon-button md-raised md-third"
                       @click="onDownloadContractTemplateDocument(item)"
                       :disabled="!item.templateDocumentAvailable || sending || !(isAdmin || isFacilityAdmin || isTreasurer || isManagement) || !canWrite">
              <md-icon>archive</md-icon>
              <md-tooltip>Download erzeugtes Vertragsdokument basierend auf der aktiven Druckvorlage <b>{{trimString(item.templateFileName)}}</b></md-tooltip>
            </md-button>
            <md-button class="md-icon-button md-raised md-forth"
                       @click="onDownloadContractDocument(item)"
                       :disabled="!item.documentAvailable || sending || !(isAdmin || isFacilityAdmin || isTreasurer || isManagement) || !canWrite">
              <md-icon>archive</md-icon>
              <md-tooltip>Download Vertragsdokument <b>{{trimString(item.documentFileName)}}</b></md-tooltip>
            </md-button>
          </md-table-cell>
          <md-table-cell style="padding-left: 0; padding-right: 0" :class="activeClass(item.expired)" md-label="gültig von" md-sort-by="validFrom">{{ item.validFrom | moment("DD.MM.YYYY") }}
          </md-table-cell>
          <md-table-cell style="padding-left: 0; padding-right: 0" :class="activeClass(item.expired)" md-label="gültig bis" md-sort-by="validUntil">{{ item.validUntil | moment("DD.MM.YYYY") }}
          </md-table-cell>
          <md-table-cell style="padding-left: 0; padding-right: 0" :class="activeClass(item.expired)" md-label="Vertragspartner" md-sort-by="signingPerson.lastname">
            <PersonView :facilityRef="child.facilityReference" :person="item.signingPerson" :raw="true"/>
            <PersonView v-if="item.secondSigningPerson && item.signingPerson2" :facilityRef="child.facilityReference" :person="item.signingPerson2" :raw="true"/>
          </md-table-cell>
        </md-table-row>
      </md-table>

      <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDeleteContractDialog">
        <div v-if="selected" class="grid-x grid-padding-x grid-padding-y">
          <md-dialog-title>
            <i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Vertrag für <span class="group-title">{{child.masterdata.fullName}}</span>
            löschen
          </md-dialog-title>
          <div class="cell">
            <md-content>Soll der Vertrag mit der Nr <span class="group-title">{{selected.contractReferenceNumber}}</span> für das Kind <span class="group-title">{{child.masterdata.fullName}}</span> wirklich
              gelöscht werden?
            </md-content>
            <br>
            <md-progress-bar md-mode="indeterminate" v-if="sending"/>
          </div>
          <div class="cell text-center">
            <div @click="deleteContract" class="button alert"><i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Ja,
              Vertrag jetzt löschen
            </div>
            <button class="button success" style="margin-left: 1rem;" @click="showDeleteContractDialog = false" type="button"
                    :disabled="sending">
              <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
            </button>
          </div>
          <button class="close-button" type="button" @click="showDeleteContractDialog = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </md-dialog>

      <EditContractDialog :showDialog="showEditContractDialog" :child="child" :contract="selectedContract" :mode="dialogMode"
                     @updateContractSuccess="updateContractSuccess"
                     @closeEditContractDialog="showEditContractDialog = false"></EditContractDialog>
    </div>
    <div v-else>
      <NoModuleCard moduleType="FINANCE" moduleFunction="Verträgen"></NoModuleCard>
    </div>
  </div>
</template>

<script>
  import jQuery from 'jquery';
  import Spinner from 'vue-simple-spinner';
  import EditContractDialog from '../dialogs/EditContractDialog';

  import ChildService from '../../services/ChildService';
  import HttpErrorHandler from '../../services/HttpErrorHandler';

  import Contract from '../../entities/Contract';
  import Address from '../../entities/Address';
  import Person from '../../entities/Person';
  import BankAccount from '../../entities/BankAccount';
  import PersonView from '../../components/views/PersonView';
  import NoModuleCard from '../../components/cards/NoModuleCard';
  import BinaryService from "@/services/BinaryService";

  const toLower = text => {
    return text.toString().toLowerCase();
  };

  const searchByBaseAmount = (items, term) => {
    if (term) {
      return items.filter(item => toLower(item.signingPerson.lastname).includes(toLower(term)));
    }

    return items;
  };

  const searchByActiveState = (items, activeOnly) => {
    if (activeOnly) {
      return items.filter(item => !item.expired);
    }

    return items;
  };

  export default {
    name: 'ContractsView',

    components: {
      'vue-simple-spinner': Spinner,
      Contract,
      Address,
      Person,
      PersonView,
      EditContractDialog,
      NoModuleCard,
    },

    props: {
      child: {
        required: true
      },
    },

    mounted() {
      this.searchOnTable();
    },

    watch: {
      child() {
        this.selected = null;
        this.selectedContract = null;
        this.searchOnTable();
      },
    },

    data() {
      return {
        sending: false,
        searched: [],
        search: null,
        selected: null,
        lastSelected: null,
        selectedRows: [],
        selectedContract: null,
        showEditContractDialog: false,
        dialogMode: 'update',
        success: false,
        showDeleteContractDialog: false,
        showActiveContractsOnly: true,

        downloadUrl: null,
        downloadLink: null,
        downloadBlob: null,
      }
    },

    methods: {
      contractRowId(item) {
        return 'contract-id-' + item.refNr;
      },

      booleanText(value) {
        return value ? 'ja' : 'nein';
      },

      getClass: ({id}) => ({
        'md-primary': id === id
      }),

      activeClass(active) {
        return active? 'in-active' : ''
      },

      trimString(name) {
        return name ? name.replaceAll('_', ' ') : '';
      },

      searchOnTable() {
        this.searched = searchByBaseAmount(this.child.contracts, this.search);
        this.searched = searchByActiveState(this.searched, this.showActiveContractsOnly);
        this.resizeDialogElements();
      },

      completeContractData(contract) {
        if (contract !== null) {
          if (contract.signingPerson === null) {
            contract.signingPerson = JSON.parse(JSON.stringify(Person.person));
          }
          if (contract.signingPerson.address === null) {
            contract.signingPerson.address = JSON.parse(JSON.stringify(Address.address));
          }
          if (contract.account === null) {
            contract.account = JSON.parse(JSON.stringify(BankAccount.bankAccount));
          }

          return contract;
        }
      },

      onSelect(item) {
        if (item && item.refNr >= 0) {
          this.dialogMode = 'update';
          this.selected = JSON.parse(JSON.stringify(this.completeContractData(item)));
          this.selectedContract = JSON.parse(JSON.stringify(this.selected));
          this.lastSelected = JSON.parse(JSON.stringify(this.selected));
        } else {
          this.selected = null;
          this.selectedContract = null;
        }
      },

      resizeDialogElements() {
        // Table header
        setTimeout(() => jQuery('.md-table-head-label').attr('style', 'padding: 0px 0px 0px 24px;'), 10);

        // Table rows
        setTimeout(() => jQuery('.md-table-cell-container').attr('style', 'padding: 6px 0px 6px 10px;'), 30);

        // Table height
        setTimeout(() => jQuery('#contracts-view-table-id').children('.md-content.md-table-content').attr('style','height: 100%'),50);
      },

      onAddContract() {
        if (this.selected) {
          jQuery('#' + this.contractRowId(this.selected)).trigger('click');
        }
        setTimeout(() => {
          this.dialogMode = 'add';
          this.selectedContract = JSON.parse(JSON.stringify(this.completeContractData(Contract.contract)));
          this.showEditContractDialog = true;
        }, 200);
      },

      onDeleteContract() {
        if (this.selectedContract) {
          this.showDeleteContractDialog = true;
        }
      },

      deleteContract() {
        if (this.selected) {
          this.success = false;
          this.sending = true;
          ChildService.deleteContract(this.child.id, this.selected.refNr)
            .then(response => {
              const child = JSON.parse(JSON.stringify(response.data));
              this.sending = false;
              this.$store.commit('successMsg', 'Der Vertrag für das Kind &nbsp;<b>' + this.child.masterdata.fullName + '</b>&nbsp;wurde erfolgreich gelöscht.');
              this.success = true;
              this.search = null;
              this.selected = null;
              this.selectedEmployee = null;
              this.showDeleteContractDialog = false;
              this.$emit("updateChildren", child);
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Löschen des Vertrag mit der Nr ' + this.selected.contractReferenceNumber + ' für Kind ' + this.child.masterdata.fullName);
              this.sending = false;
            })
        }
      },

      onEditContract() {
        if (this.selected) {
          this.dialogMode = 'update';
          setTimeout(() => this.showEditContractDialog = true, 200);
        }
      },

      onViewContract() {
        if (this.selected) {
          this.dialogMode = 'view';
          setTimeout(() => this.showEditContractDialog = true, 200);
        }
      },

      updateContractSuccess(updatedChild) {
        if (this.dialogMode === 'add') {
          this.$store.commit('successMsg', 'Der neue Vertrag für Kind&nbsp;<b>' + this.child.masterdata.fullName + '</b>&nbsp;wurde erfolgreich hinzugefügt.');
          this.success = true;
          this.search = null;
          this.onSelect(null);
          this.searchOnTable();
        }

        if (this.dialogMode === 'update') {
          this.$store.commit('successMsg', 'Die Vertragsänderung für Kind&nbsp;<b>' + this.child.masterdata.fullName + '</b>&nbsp;wurde erfolgreich gespeichert!');
          this.success = true;

          setTimeout(() => {
            if (this.lastSelected) {
              jQuery('#' + this.contractRowId(this.lastSelected)).trigger('click');
            }
          }, 500);
        }

        this.$emit("updateChildren", updatedChild);
        this.success = true;
      },

      resetStates() {
        this.success = false;
      },

      onDownloadContractDocument(contract) {
        if (contract) {
          this.downloadContractDocument(contract);
        }
      },

      downloadContractDocument(contract) {
        if (contract) {
          BinaryService.downloadParentContractDocument(contract.documentId)
              .then((response) => {

                setTimeout(() => {
                  this.cleanDownloads();
                  this.downloadBlob = new Blob([response.data], {type: 'application/octet-stream'});
                  this.downloadUrl = window.URL.createObjectURL(this.downloadBlob);
                  this.downloadLink = document.createElement('a');
                  const id = contract.documentFileName;
                  this.downloadLink.href = this.downloadUrl;
                  this.downloadLink.setAttribute('download', id);
                  this.downloadLink.setAttribute('id', id);
                  document.body.appendChild(this.downloadLink);
                  this.downloadLink.click();
                }, 250);
                this.$store.commit('successMsg', 'Das Dokument für Vertrag <b>' + contract.contractReferenceNumber + '</b> wurde erfolgreich heruntergeladen.');
              })
              .catch(e => {
                HttpErrorHandler.handleError(e, this, 'Fehler beim Herunterladen des Vertragsdokument für <b>' + contract.contractReferenceNumber + '</b>');
              })
        }
      },

      onDownloadContractTemplateDocument(contract) {
        if (contract) {
          this.downloadContractTemplateDocument(contract);
        }
      },

      downloadContractTemplateDocument(contract) {
        if (contract) {
          BinaryService.downloadParentContractDocument(contract.templateId)
              .then((response) => {

                setTimeout(() => {
                  this.cleanDownloads();
                  this.downloadBlob = new Blob([response.data], {type: 'application/octet-stream'});
                  this.downloadUrl = window.URL.createObjectURL(this.downloadBlob);
                  this.downloadLink = document.createElement('a');
                  const id = contract.contractFileName;
                  this.downloadLink.href = this.downloadUrl;
                  this.downloadLink.setAttribute('download', id);
                  this.downloadLink.setAttribute('id', id);
                  document.body.appendChild(this.downloadLink);
                  this.downloadLink.click();
                }, 250);
                this.$store.commit('successMsg', 'Das erzeugte Vertragsdokument für Vertrag <b>' + contract.contractReferenceNumber + '</b> wurde erfolgreich heruntergeladen.');
              })
              .catch(e => {
                HttpErrorHandler.handleError(e, this, 'Fehler beim Herunterladen des erzeugten Vertragsdokument für <b>' + contract.contractReferenceNumber + '</b>');
              })
        }
      },

      cleanDownloads() {
        if (this.downloadUrl) {
          this.downloadBlob = null;
          document.body.removeChild(this.downloadLink);
          this.downloadLink = null;
        }
      },
    },

    computed: {
      isAdmin() {
        return this.$store.getters.admin;
      },

      isFacilityAdmin() {
        return this.$store.getters.facilityAdmin;
      },

      isManagement() {
        return this.$store.getters.management;
      },

      isSage() {
        return this.$store.getters.sage;
      },

      isTreasurer() {
        return this.$store.getters.treasurer
      },

      isFacility() {
        return this.$store.getters.facility
      },

      canWrite() {
        return this.$store.getters.canWrite
      },

      labels() {
        return this.$store.getters.labels
      },

      organization() {
        return this.$store.getters.organization
      },
    }
  }
</script>

<style lang="scss" scoped>
  .tab-content {
    padding: 0 8px 0 8px;
  }

  p {
    margin: 0;
  }

  table {
    border-collapse: unset;
  }

  tbody th, tbody td {
    padding-top: 0;
    padding-bottom: 0;
  }

  .md-checkbox {
    margin: 0;
  }

  tbody tr:nth-child(even) {
    border-bottom: 0;
    background-color: transparent;
  }

  .md-table.md-theme-default .md-table-row.md-selected-single.md-primary {
    background: darkseagreen;
  }

  .md-tooltip {
    font-size: medium;
    height: auto;
    white-space: normal;
  }

  .in-active {
    font-style: italic;
    color: gray;
  }
</style>

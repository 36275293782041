<template>
  <div>
    <br>
    <md-table v-if="filteredDocumentation" v-model="filteredDocumentation" md-sort="name" md-sort-order="asc" md-card md-fixed-header
              style="height: 575px;"
              @md-selected="onSelect" :md-selected-value.sync="selectedRows">
      <md-table-toolbar>
        <div class="md-toolbar-section-start grid-x">
          <div class="cell large-5 medium-5 small-5">
            <p class="md-title ai-number" style="color: crimson;">{{filteredDocumentation.length}} {{label}}</p>
          </div>
          <div class="cell large-1 medium-1 small-1">
            <div v-if="loading">
              <vue-simple-spinner></vue-simple-spinner>
            </div>
          </div>

          <div class="cell small-6 medium-6 large-6 text-center" style="padding-top: 5px;">
            <div v-if="isAdmin || isFacility || isDocumentation" class="grid-x grid-padding-x">
              <div class="cell medium-12 large-12">
                <md-button v-if="canWrite && !child.archived" @click="onAddDocumentation"
                           :disabled="loading"
                           class="md-icon-button md-raised md-primary">
                  <md-icon>note_add</md-icon>
                  <md-tooltip>{{labelsSingular[type]}} hinzufügen</md-tooltip>
                </md-button>
                <md-button v-if="!child.archived && canWrite" @click="onEditDocumentation"
                           :disabled="!selected || loading"
                           class="md-icon-button md-raised md-third">
                  <md-icon>edit</md-icon>
                  <md-tooltip>{{labelsSingular[type]}} bearbeiten</md-tooltip>
                </md-button>
                <md-button v-if="child.archived || !canWrite" @click="onViewDocumentation"
                           :disabled="!selected || loading"
                           class="md-icon-button md-raised md-third">
                  <md-icon>visibility</md-icon>
                  <md-tooltip>{{labelsSingular[type]}} einsehen</md-tooltip>
                </md-button>
                <md-button v-if="canWrite && !child.archived" @click="onDeleteDocumentation"
                           :disabled="!selected || loading"
                           class="md-icon-button md-raised md-accent">
                  <md-icon>delete</md-icon>
                  <md-tooltip>{{labelsSingular[type]}} löschen</md-tooltip>
                </md-button>
              </div>
            </div>
          </div>

        </div>
      </md-table-toolbar>

      <md-table-empty-state/>

      <md-table-row v-if="filteredDocumentation" :id="item.id" slot="md-table-row" slot-scope="{ item }" :class="getClass(item.id)"
                    md-selectable="single">
        <md-table-cell style="padding-right: 0" md-label="Bearbeiter" md-sort-by="document.editors">
          <span v-if="item.editors.length > 0">
            <span v-for="editor of item.editors" :key="editor">{{editor}}<br></span>
          </span>
          <span v-else>keine</span>
        </md-table-cell>
        <md-table-cell v-if="item.document.participants && item.type === 'PARENTS_TALK'" style="padding-right: 0" md-label="Teilnehmer" md-sort-by="document.participants">
          <span v-if="item.document.participants.length > 0">
            <span v-for="participant of item.document.participants" :key="participant">{{participant}}<br></span>
          </span>
          <span v-else>keine</span>
        </md-table-cell>
        <md-table-cell style="padding-left: 0; padding-right: 0" md-label="Start" md-sort-by="startEditing">
          {{ item.startEditing | moment("DD.MM.YYYY") }}
        </md-table-cell>
        <md-table-cell style="padding-left: 0; padding-right: 0" md-label="Ende" md-sort-by="endEditing">
          {{ item.endEditing | moment("DD.MM.YYYY") }}
        </md-table-cell>
      </md-table-row>
    </md-table>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDeleteDocumentationDialog">
      <div v-if="selected" class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title><md-icon>euro_symbol</md-icon>&nbsp;&nbsp;&nbsp;Dokumentation {{label.substring(0, label.indexOf(' '))}} für <span
            class="group-title">{{child.masterdata.fullName}}</span>
          löschen
        </md-dialog-title>
        <div class="cell">
          <md-content>Soll die ausgewählte Dokumentation <b>{{label.substring(0, label.indexOf(' '))}}</b> für das Kind <span class="group-title">{{child.masterdata.fullName}}</span> wirklich
            gelöscht werden?
          </md-content>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="loading"/>
        </div>
        <div class="cell text-center">
          <md-checkbox v-model="absolutelySure" :disabled="loading">Ich bin mir absolut sicher!</md-checkbox>
        </div>
        <div class="cell text-center">
          <button @click="deleteDocumentation" class="button alert" :disabled="loading || !absolutelySure">
            <i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Ja, Dokumentation jetzt löschen
          </button>
          <button class="button success" style="margin-left: 1rem;" @click="showDeleteDocumentationDialog = false" type="button"
                  :disabled="loading">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="showDeleteDocumentationDialog = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

  </div>
</template>

<script>

  import Spinner from 'vue-simple-spinner';
  import EditDocumentationDialog from '../../components/dialogs/EditDocumentationDialog'
  import DocumentationService from '../../services/DocumentationService';
  import HttpErrorHandler from '../../services/HttpErrorHandler';

  const filterByType = (items, term) => {
    if (term) {
      return items.filter(item => item.type.includes(term));
    }

    return items;
  };

  export default {
    name: 'DocumentationView',

    components: {
      EditDocumentationDialog,
      'vue-simple-spinner': Spinner
    },

    props: {
      child: {
        required: true
      },
      documentation: {
        required: true
      },
      type: {
        required: true
      },
      sending: {
        required: true
      },
    },

    watch: {
      documentation() {
        this.filterDocumentation();
      },

      type() {
        this.filterDocumentation();
      },
    },

    data() {
      return {
        filteredDocumentation: [],
        selectedRows: [],
        selected: null,
        selectedDocumentation: null,
        label: '',
        labelsSingular: {
          NOTE: 'Notiz',
          'PARENTS_TALK': 'Elterngespräch',
          'CHILD_OBSERVATION': 'Kindbeobachtung',
          'THERAPIST_TALK': 'Therapeutengespräch',
          'QUESTIONNAIRE_PERIK': 'PERIK Fragebogen',
          'QUESTIONNAIRE_SELDAK': 'SELDAK Fragebogen',
          'QUESTIONNAIRE_SISMIK': 'SISMIK Fragebogen',
        },
        labelsPlural: {
          NOTE: 'Notizen',
          'PARENTS_TALK': 'Elterngespräche',
          'CHILD_OBSERVATION': 'Kindbeobachtungen',
          'THERAPIST_TALK': 'Therapeutengespräche',
          'QUESTIONNAIRE_PERIK': 'PERIK Fragebögen',
          'QUESTIONNAIRE_SELDAK': 'SELDAK Fragebögen',
          'QUESTIONNAIRE_SISMIK': 'SISMIK Fragebögen',
        },

        dialogMode: 'update',
        showDeleteDocumentationDialog: false,
        localSending: false,
        absolutelySure: false,
      }
    },

    methods: {

      resetStates() {
      },

      getClass: ({id}) => ({
        'md-primary': id === id
      }),

      filterDocumentation() {
        this.selected = null;
        this.selectedDocumentation = null;
        this.filteredDocumentation = [];
        this.filteredDocumentation = filterByType(this.documentation, this.type);
        if (this.filteredDocumentation.length === 1) {
          this.label = this.labelsSingular[this.type];
        } else {
          this.label = this.labelsPlural[this.type];
        }
      },

      onSelect(item) {
        if (item && item.id) {
          this.dialogMode = 'update';
          this.selected = JSON.parse(JSON.stringify(item));
          this.selectedDocumentation = JSON.parse(JSON.stringify(this.selected));
          localStorage.setItem(this.user.md5 + '@selectedDocumentation', JSON.stringify(this.selectedDocumentation));
          localStorage.setItem(this.user.md5 + '@fullDocumentation', JSON.stringify(this.documentation));
        } else {
          this.selected = null;
          this.selectedDocumentation = null;
          localStorage.removeItem(this.user.md5 + '@selectedDocumentation');
          localStorage.removeItem(this.user.md5 + '@fullDocumentation');
        }
      },

      onAddDocumentation() {
        this.getPreFilledDocumentation();
      },

      getPreFilledDocumentation() {
        if (this.child) {
          this.localSending = true;
          DocumentationService.getPreFilledDocumentation(this.child.id, this.type)
            .then(response => {
              this.localSending = false;
              this.dialogMode = 'add';

              localStorage.setItem(this.user.md5 + '@selectedDocumentation', JSON.stringify(response.data));
              if (this.documentation) {
                localStorage.setItem(this.user.md5 + '@fullDocumentation', JSON.stringify(this.documentation));
              }
              localStorage.setItem(this.user.md5 + '@returnToDocumentationSelection', 'true');
              localStorage.setItem(this.user.md5 + '@dialogMode', this.dialogMode);
              this.$router.push('/Dokumentation');
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der vorausgefüllten Dokumentation für Kind '
                + this.child.masterdata.firstname + ' ' + this.child.masterdata.lastname);
              this.localSending = false;
            })
        }
      },

      onEditDocumentation() {
        if (this.selectedDocumentation) {
          this.dialogMode = 'update';
          localStorage.setItem(this.user.md5 + '@returnToDocumentationSelection', 'true');
          localStorage.setItem(this.user.md5 + '@dialogMode', this.dialogMode);
          this.$router.push('/Dokumentation');
        }
      },

      onViewDocumentation() {
        if (this.selectedDocumentation) {
          this.dialogMode = 'view';
          localStorage.setItem(this.user.md5 + '@returnToDocumentationSelection', 'true');
          this.$router.push('/Dokumentation');
        }
      },

      onDeleteDocumentation() {
        if (this.selectedDocumentation) {
          this.absolutelySure = false;
          this.showDeleteDocumentationDialog = true;
        }
      },

      deleteDocumentation() {
        if (this.selected) {
          this.localSending = true;
          DocumentationService.deleteDocumenation(this.selectedDocumentation.id)
            .then(() => {
              this.localSending = false;
              this.$store.commit('successMsg', 'Die Dokumentation für das Kind &nbsp;<b>' + this.child.masterdata.fullName + '</b>&nbsp;wurde erfolgreich gelöscht.');
              this.selected = null;
              this.selectedDocumentation = null;
              this.showDeleteDocumentationDialog = false;
              this.$emit("updateDocumentation");
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Löschen der Dokumentation für Kind ' + this.child.masterdata.fullName);
              this.localSending = false;
            })
        }
      },
    },

    computed: {

      loading() {
        return (this.sending || this.localSending);
      },

      isAdmin() {
        return this.$store.getters.admin;
      },

      isSage() {
        return this.$store.getters.sage;
      },

      isTreasurer() {
        return this.$store.getters.treasurer
      },

      isFacility() {
        return this.$store.getters.facility
      },

      isDocumentation() {
        return this.$store.getters.documentation
      },

      canWrite() {
        return this.$store.getters.canWrite
      },

      user() {
        return this.$store.getters.user;
      },

      organization() {
        return this.$store.getters.organization
      },
    }
  }
</script>

<style lang="scss" scoped>
  .md-tooltip {
    font-size: medium;
    white-space: normal;
  }
</style>

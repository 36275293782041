<template>
  <div v-if="child" class="tab-content">
    <div v-if="organization.financeModuleActive">
      <md-table id="fees-view-table-id" v-model="searched" md-sort="name" md-sort-order="asc" md-card md-fixed-header style="height: 740px;"
                @md-selected="onSelect" :md-selected-value.sync="selectedRows">
        <md-table-toolbar>
          <div class="md-toolbar-section-start grid-x">
            <div class="cell large-4 hide-for-medium-only hide-for-small-only">
              <p class="md-title ai-number">{{searched.length}} Gebühr<span v-if="searched.length !== 1">en</span></p>
            </div>
            <div class="cell large-2 medium-0 small-0">
              <div v-if="sending">
                <vue-simple-spinner></vue-simple-spinner>
              </div>
            </div>
            <div class="cell large-6 medium-12 small-12">
              <md-field md-clearable class="md-toolbar-section-end">
                <md-input placeholder="Suche nach Betrag ..." v-model="search" @input="searchOnTable"/>
              </md-field>
            </div>
            <div class="cell" style="padding-bottom: 15px;">
              <div class="grid-x grid-padding-x">
                <div class="cell medium-6 large-6 hide-for-small-only">
                  <md-checkbox class="md-primary" @change="searchOnTable" v-model="showActiveFeesOnly">Nur aktive Gebühren
                  </md-checkbox>
                </div>
                <div v-if="!child.archived && canWrite && (isFacility || isTreasurer || isAdmin || isFacilityAdmin || isManagement)" class="cell medium-6 large-6">
                  <md-button @click="onAddFee" class="md-icon-button md-raised md-primary">
                    <md-icon>add</md-icon>
                    <md-tooltip>Neue Gebühr hinzufügen</md-tooltip>
                  </md-button>
                  <md-button @click="onEditFee" :disabled="!selected" class="md-icon-button md-raised md-third">
                    <md-icon>edit</md-icon>
                    <md-tooltip>Gebühr bearbeiten</md-tooltip>
                  </md-button>
                  <md-button @click="onCopyFee" :disabled="!selected" class="md-icon-button md-raised md-forth">
                    <md-icon>content_copy</md-icon>
                    <md-tooltip>Gebühr kopieren</md-tooltip>
                  </md-button>
                  <md-button @click="onDeleteFee" :disabled="!selected" class="md-icon-button md-raised md-accent">
                    <md-icon>delete</md-icon>
                    <md-tooltip>Gebühr löschen</md-tooltip>
                  </md-button>
                </div>
                <div v-else class="cell medium-6 large-6">
                  <md-button @click="onViewFee" :disabled="!selected" class="md-icon-button md-raised md-third">
                    <md-icon>visibility</md-icon>
                    <md-tooltip>Gebühr einsehen</md-tooltip>
                  </md-button>
                </div>
              </div>
            </div>
          </div>
        </md-table-toolbar>

        <md-table-empty-state v-if="search"
                              md-label="Keine passenden Gebühren gefunden"
                              :md-description="`Keine Gebühren mit dem Betrag '${search}' gefunden. Versuchen Sie es mit einem anderen Betrag.`">
        </md-table-empty-state>

        <md-table-row :id="item.refNr" slot="md-table-row" slot-scope="{ item }" :class="getClass(item.refNr)" md-selectable="single">
          <md-table-cell style="padding-right: 0" md-label="Bezeichnung" md-sort-by="feeType">
            <md-icon v-if="item.lastBilledDate" style="color: #42A85F">check</md-icon>
            <md-icon v-else style="color: grey">remove</md-icon>

            <span v-if="item.name" :class="activeClass(item.active)">{{ item.name }}</span>
            <span v-else :class="activeClass(item.active)">({{ labels.feeType[item.feeType] }})</span>

            <md-tooltip v-if="item.lastBilledDate && item.active" md-direction="bottom">
              Gebührenart: <b>{{ labels.feeType[item.feeType] }}</b><br>
              Zuletzt abgerechnet am {{item.lastBilledDate | moment("DD.MM.YYYY") }}
            </md-tooltip>
            <md-tooltip v-else-if="item.active" md-direction="bottom">
              Gebührenart: <b>{{ labels.feeType[item.feeType] }}</b><br>
              Noch nicht abgerechnet
            </md-tooltip>
          </md-table-cell>
          <md-table-cell style="padding-left: 0; padding-right: 0" md-label="gültig von" md-sort-by="validFrom">
            <span :class="activeClass(item.active)">{{ item.validFrom | moment("DD.MM.YYYY") }}</span>
          </md-table-cell>
          <md-table-cell style="padding-left: 0; padding-right: 0" md-label="gültig bis" md-sort-by="validUntil">
            <span :class="activeClass(item.active)">{{ item.validUntil | moment("DD.MM.YYYY") }}</span>
          </md-table-cell>
          <md-table-cell style="padding-left: 0; padding-right: 0" md-label="Gesamtbetrag" md-sort-by="amount">
            <span v-if="item.signedAmount < 0" style="color: red"><b :class="activeClass(item.active)">{{ item.signedAmount | currency }}</b></span>
            <span v-else style="color: seagreen"><b :class="activeClass(item.active)">{{ item.signedAmount | currency }}</b></span>&nbsp;&nbsp;
            <i v-if="item.usingPriceList" class="fi-price-tag" style="color: salmon; font-size: x-large;"></i>
            <md-tooltip>
              Basisbetrag (monatlich): <b>{{item.baseAmount | currency}}</b><br>
              Ermäßigung: <b>{{item.discountPercentage}} %</b><br>
              + Nachlass: <b>{{item.discountAbsolute | currency}}</b><br>
              <span v-if="item.taxRelevant"><b style="color: greenyellow;">steuer-relevant</b></span>
              <span v-if="item.usingPriceList"><br>Gebühr wird mit Preisliste <b style="color: salmon;">{{item.pricelist.name}}</b> berechnet.</span>
            </md-tooltip>
          </md-table-cell>
          <md-table-cell style="padding-left: 0; padding-right: 0" md-label="Interval" md-sort-by="interval">
            <span :class="activeClass(item.active)">{{ labels.paymentInterval[item.interval] }}</span>
          </md-table-cell>
          <md-tooltip v-if="!item.active" md-direction="top">Gebühr nicht aktiv</md-tooltip>
        </md-table-row>
      </md-table>

      <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDeleteFeeDialog">
        <div v-if="selected" class="grid-x grid-padding-x grid-padding-y">
          <md-dialog-title><i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Gebühr für <span
              class="group-title">{{child.masterdata.fullName}}</span>
            löschen
          </md-dialog-title>
          <div class="cell">
            <md-content>Soll die Gebühr für das Kind <span class="group-title">{{child.masterdata.fullName}}</span> wirklich
              gelöscht werden?
            </md-content>
            <br>
            <md-progress-bar md-mode="indeterminate" v-if="sending"/>
          </div>
          <div class="cell text-center">
            <div @click="deleteFee" class="button alert"><i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Ja,
              Gebühr jetzt löschen
            </div>
            <button class="button success" style="margin-left: 1rem;" @click="showDeleteFeeDialog = false" type="button"
                    :disabled="sending">
              <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
            </button>
          </div>
          <button class="close-button" type="button" @click="showDeleteFeeDialog = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </md-dialog>

      <md-dialog :md-click-outside-to-close="false" :md-active.sync="showCopyFeeDialog">
        <div v-if="selected" class="grid-x grid-padding-x grid-padding-y">
          <md-dialog-title><i class="fi-page-copy"></i>&nbsp;&nbsp;&nbsp;Gebühr für <span
              class="group-title">{{child.masterdata.fullName}}</span>
            kopieren
          </md-dialog-title>
          <div class="cell">
            <md-content>Soll die Gebühr <span class="group-title">{{labels.feeType[selected.feeType]}}</span> für das Kind <span class="group-title">{{child.masterdata.fullName}}</span> kopiert werden?
            </md-content>
            <br>
            <md-progress-bar md-mode="indeterminate" v-if="sending"/>
          </div>
          <div class="cell text-center">
            <div @click="copyFee" class="button success"><i class="fi-page-copy"></i>&nbsp;&nbsp;&nbsp;Ja,
              Gebühr jetzt kopieren
            </div>
            <button class="button alert" style="margin-left: 1rem;" @click="showCopyFeeDialog = false" type="button"
                    :disabled="sending">
              <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
            </button>
          </div>
          <button class="close-button" type="button" @click="showCopyFeeDialog = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </md-dialog>

      <EditFeeDialog :showDialog="showEditFeeDialog" :child="child" :selectedFee="selectedFee" :mode="dialogMode"
                     @updateFeeSuccess="updateFeeSuccess"
                     @closeEditFeeDialog="showEditFeeDialog = false"></EditFeeDialog>
    </div>
    <div v-else>
      <NoModuleCard moduleType="FINANCE" moduleFunction="Gebühren"></NoModuleCard>
    </div>
 </div>
</template>

<script>
  import jQuery from 'jquery';
  import Spinner from 'vue-simple-spinner';
  import EditFeeDialog from '../dialogs/EditFeeDialog';

  import ChildService from '../../services/ChildService';
  import HttpErrorHandler from '../../services/HttpErrorHandler';

  import Fee from '../../entities/Fee';
  import NoModuleCard from '../../components/cards/NoModuleCard';

  const toLower = text => {
    return text.toString().toLowerCase();
  };

  const searchByBaseAmount = (items, term) => {
    if (term) {
      return items.filter(item => toLower(item.amount).includes(toLower(term)));
    }

    return items;
  };

  const searchByActiveState = (items, activeOnly) => {
    if (activeOnly) {
      return items.filter(item => item.active);
    }

    return items;
  };

  export default {
    name: 'FeesView',

    components: {
      'vue-simple-spinner': Spinner,
      EditFeeDialog,
      NoModuleCard,
    },

    props: {
      child: {
        required: true
      },
    },

    mounted() {
      this.searchOnTable();
    },

    watch: {
      child() {
        this.searchOnTable();
      },
    },

    data() {
      return {
        sending: false,
        searched: [],
        search: null,
        selected: null,
        selectedRows: [],
        selectedFee: null,
        showEditFeeDialog: false,
        dialogMode: 'update',
        success: false,
        showDeleteFeeDialog: false,
        showCopyFeeDialog: false,
        showActiveFeesOnly: true,
      }
    },

    methods: {
      booleanText(value) {
        return value ? 'ja' : 'nein';
      },

      getClass: ({id}) => ({
        'md-primary': id === id
      }),

      activeClass(active) {
        return active? '' : 'in-active'
      },

      searchOnTable() {
        this.searched = searchByBaseAmount(this.child.fees, this.search);
        this.searched = searchByActiveState(this.searched, this.showActiveFeesOnly);
        this.resizeDialogElements();
      },

      onSelect(item) {
        if (item && item.feeType) {
          this.dialogMode = 'update';
          this.selected = JSON.parse(JSON.stringify(item));
          this.selectedFee = JSON.parse(JSON.stringify(this.selected));
        } else {
          this.selected = null;
          this.selectedFee = null;
        }
      },

      resizeDialogElements() {
        // Table header
        setTimeout(() => jQuery('.md-table-head-label').attr('style', 'padding: 0px 0px 0px 24px;'), 10);

        // Table rows
        setTimeout(() => jQuery('.md-table-cell-container').attr('style', 'padding: 6px 0px 6px 10px;'), 30);

        // Table height
        setTimeout(() => jQuery('#fees-view-table-id').children('.md-content.md-table-content').attr('style','height: 100%'),50);
      },

      onAddFee() {
        this.dialogMode = 'add';
        this.selectedFee = JSON.parse(JSON.stringify(Fee.fee));
        setTimeout(() => this.showEditFeeDialog = true, 200);
      },

      onDeleteFee() {
        if (this.selectedFee) {
          this.showDeleteFeeDialog = true;
        }
      },

      deleteFee() {
        if (this.selected) {
          this.success = false;
          this.sending = true;
          ChildService.deleteFee(this.child.id, this.selected.refNr)
            .then(response => {
              const child = JSON.parse(JSON.stringify(response.data));
              this.sending = false;
              this.$store.commit('successMsg', 'Die Gebühr für das Kind &nbsp;<b>' + this.child.masterdata.fullName + '</b>&nbsp;wurde erfolgreich gelöscht.');
              this.success = true;
              this.search = null;
              this.selected = null;
              this.showDeleteFeeDialog = false;
              this.$emit("updateChildren", child);
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Löschen der Gebühr für Kind ' + this.child.masterdata.fullName);
              this.sending = false;
            })
        }
      },

      onCopyFee() {
        if (this.selectedFee) {
          this.showCopyFeeDialog = true;
        }
      },

      copyFee() {
        if (this.selected) {
          this.sending = true;
          this.success = false;
          ChildService.copyFee(this.child.id, this.selected)
            .then(response => {
              const child = JSON.parse(JSON.stringify(response.data));
              this.sending = false;
              this.$store.commit('successMsg', 'Die Gebühr für das Kind &nbsp;<b>' + this.child.masterdata.fullName + '</b>&nbsp;wurde erfolgreich kopiert.');
              this.success = true;
              this.search = null;
              this.selected = null;
              this.showCopyFeeDialog = false;
              this.$emit("updateChildren", child);
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Kopieren der Gebühr für Kind ' + this.child.masterdata.fullName);
              this.sending = false;
            })
        }
      },

      onEditFee() {
        if (this.selected) {
          this.dialogMode = 'update';
          setTimeout(() => this.showEditFeeDialog = true, 200);
        }
      },

      onViewFee() {
        if (this.selected) {
          this.dialogMode = 'view';
          setTimeout(() => this.showEditFeeDialog = true, 200);
        }
      },

      updateFeeSuccess(updatedChild) {
        if (this.dialogMode === 'add') {
          this.$store.commit('successMsg', 'Die neue Gebühr für Kind&nbsp;<b>' + this.child.masterdata.fullName + '</b>&nbsp;wurde erfolgreich hinzugefügt.');
          this.success = true;
          this.search = null;
          this.searchOnTable();
        }

        if (this.dialogMode === 'update') {
          this.$store.commit('successMsg', 'Die Gebührenänderung für Kind&nbsp;<b>' + this.child.masterdata.fullName + '</b>&nbsp;wurde erfolgreich gespeichert!');
          this.success = true;
        }

        this.child.fees = JSON.parse(JSON.stringify(updatedChild.fees));

        this.$emit("updateChildren", updatedChild);
        this.success = true;
        this.onSelect(null);
      },

      resetStates() {
        this.success = false;
      },
    },

    computed: {
      isAdmin() {
        return this.$store.getters.admin;
      },

      isFacility() {
        return this.$store.getters.facility;
      },

      isFacilityAdmin() {
        return this.$store.getters.facilityAdmin;
      },

      isTreasurer() {
        return this.$store.getters.treasurer;
      },

      isManagement() {
        return this.$store.getters.management;
      },

      isSage() {
        return this.$store.getters.sage;
      },

      canWrite() {
        return this.$store.getters.canWrite;
      },

      labels() {
        return this.$store.getters.labels;
      },

      organization() {
        return this.$store.getters.organization;
      },
    }
  }
</script>

<style lang="scss" scoped>
  .tab-content {
    padding: 0 8px 0 8px;
  }

  p {
    margin: 0;
  }

  table {
    border-collapse: unset;
  }

  tbody th, tbody td {
    padding-top: 0;
    padding-bottom: 0;
  }

  .md-checkbox {
    margin: 0;
  }

  tbody tr:nth-child(even) {
    border-bottom: 0;
    background-color: transparent;
  }

  .md-table.md-theme-default .md-table-row.md-selected-single.md-primary {
    background: darkseagreen;
  }

  .md-tooltip {
    font-size: medium;
    height: auto;
    white-space: normal;
  }

  .in-active {
    font-style: italic;
    color: gray;
  }
</style>

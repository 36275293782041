export default {

  name: 'BookingTime',

  bookingTime: {
    backupActive: false,
    creationErrorMessage: null,
    creationPossible: true,
    current: false,
    description: '',
    divisiontype: '',
    handicapped: false,
    holidayDays: 0,
    hoursPerDay: 0,
    hoursPerWeek: 0,
    hoursUnweighted: 0,
    kibigTimeSlot: '',
    kindergartenBeitragszuschuss: false,
    kindergartenBeitragszuschussAutoCalculationEnabled: true,
    kindergartenBeitragszuschussEnde: null,
    kindergartenBeitragszuschussStart: null,
    lunchbreakHours: 0,
    migrantBackground: false,
    numberOfAssignedDays: 5,
    preparatoryCourseActive: false,
    preschooler: false,
    promotionCommunityKey: 0,
    promotionCommunityName: '',
    refNr: -1,
    schoolHolidayNameSet: false,
    schoolHolidaysName: '',
    showBeitragszuschuss: false,
    showVorschulKind: false,
    supportFactor: 0,
    supportFactorAutoCalculationEnabled: true,
    timeFactor: 0,
    timeFactorAutoCalculationEnabled: true,
    times: {
      'DO': {'from': '00:00:00', 'until': '00:00:00', 'assigned': false, 'minutes': 0},
      'DI': {'from': '00:00:00', 'until': '00:00:00', 'assigned': false, 'minutes': 0},
      'MO': {'from': '00:00:00', 'until': '00:00:00', 'assigned': false, 'minutes': 0},
      'MI': {'from': '00:00:00', 'until': '00:00:00', 'assigned': false, 'minutes': 0},
      'FR': {'from': '00:00:00', 'until': '00:00:00', 'assigned': false, 'minutes': 0}
    },
    type: '',
    useWeekend: false,
    validFrom: null,
    validUntil: null,
  }
}